import React from "react";
import classNames from "classnames";

import "./Switch.scss";

export default class Switch extends React.Component {
    render = () => {
        const { setValue, title, checked, className, disabled, ...props } = this.props;

        return (
            <div className="custom-switch-container">
                <h6 className="switch-title">{title}</h6>
                <label className={classNames("switch", className)} {...props}>
                    <input type="checkbox" checked={checked} onChange={(e) => setValue(e.target.checked)} disabled={disabled && disabled} />
                    <span className="slider round"></span>
                </label>
            </div>
        );
    };
}
