import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Tooltip } from "@material-ui/core";

import "components/common/Accordion/Accordion.scss";
import iconChevronNext from "images/icon-next.svg";

export default class CustomizeAccordion extends React.Component {

    state = {
        isOpenTitle: false,
    }

    mouseEnter = (e) => {
        if (e.target.scrollWidth > e.target.clientWidth) {
            this.setState({ isOpenTitle: true });
        }
    }
    render = () => {
        const { title, titleStyle, children, badge, additionalInfo, ...props } = this.props;
        return (
            <div>
                <Accordion elevation={0} className="accordion-root" {...props}>
                    <AccordionSummary id={`${title}-header`} aria-controls={`${title}-content`} className="accordion-summary-containerr">
                        <div className="accordion-header-container">
                            <div className={titleStyle ? `accordion-title-container ${titleStyle}` : "accordion-title-container"}>
                                <img src={iconChevronNext} alt="" className="show-more-icon" />
                                {titleStyle === 'enclosure-logs-title' ? (
                                    <div className="accordion-title">{title}</div>
                                ) : (
                                <Tooltip title={title} placement="top" disableHoverListener={!this.state.isOpenTitle}>
                                    <h4 onMouseEnter={this.mouseEnter} className="accordion-title">{title}</h4>
                                </Tooltip>
                                )}
                            </div>
                            {badge && <div className="badge size-tiny">{badge}</div>}
                            {additionalInfo && <div className="additional-info">{additionalInfo}</div>}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-detail-containerr">{children}</AccordionDetails>
                </Accordion>
            </div>
        );
    };
}
