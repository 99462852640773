import React from 'react';
import _ from "lodash";
import { Grid, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';

import 'components/uv-admin/enclosure-logs/EnclosureLogs.scss';
import iconBack from "images/icon-back-prim.svg";
import iconEnclosures from "images/graphic-enclosures.svg";
import iconWarning from "images/icon-warning-small-purple.svg";
import iconLostConnection from "images/icon-not-communicating.svg";

import { report as reportAPI } from 'services/api'
import { CustomizeAccordion, CustomizeTable, DotLoading } from 'components/common';
import { UVCAdmin } from 'helpers/constant';

export default class EnclosureLogs extends React.Component {
    initialState = {
        headers: UVCAdmin.enclosureLogs.headers,
        facilities: null,
        isLoading: false,
        selectedFacility: null,
        enclosureLogs: null,
        isLogsLoading: false,
    }

    state = {...this.initialState}
    typingTimer = null;

    componentDidMount = () => {
        this.getFacilities();
        this.pstTimeConverter('2021-05-24T19:01:41.071Z')
    }

    componentWillUnmount = () => {
        clearTimeout(this.typingTimer)
    }

    getFacilities = () => {
        this.setState({ isLoading: true });
        return reportAPI
            .getFalicitiesByUser()
            .then((facilities) => {
                this.setState({facilities, isLoading: false},
                    () => this.setSelectedFacility(facilities[0]))
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    }

    setSelectedFacility = (facility) => {
        this.setState({
            selectedFacility: facility, 
            isLogsLoading: true, 
        }, () => {
            let requests = []
            facility.enclosures.forEach(enclosure => requests.push(reportAPI.getEnclosureLogs(enclosure.id)))
            return Promise.all(requests)
                .then(data => {
                    let logs = _.flatten(data);
                    let groupedLogs = _.groupBy(logs, 'enclosure_id')
                    return groupedLogs;
                })
                .then((logs) => this.setState({enclosureLogs: logs, isLogsLoading: false}))
                .catch(err => this.setState({ isLogsLoading: false }))
        })
    }

    connectionStatusRendering = (status, ok) => {
        switch(true) {
            case status === 'lost_connection':
                return <img src={iconLostConnection} width="22" alt="" />
            case status === 'connected' && !ok:
                return <img src={iconWarning} width="22" alt="" />
            default:
                return <div style={{width: '22px'}}></div>
        }
    }

    pstTimeConverter = (utcTime) => {
        let date = new Date(utcTime);
        let pstDate = date.toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
        pstDate = pstDate.replace(",", " @") + ' PST';
        return pstDate
    }
    
    mouseEnter = (e, id) => {
        const facilities = this.state.facilities.map((value, indx) => {
            if (value.id === id && e.target.scrollWidth > e.target.clientWidth) {
                value.isOpenTitle = true;
            } else {
                value.isOpenTitle = false;
            }
            return value;
        });
        this.setState({ facilities });
    }

    enCloseureMouseEnter = (e, id, type) => {
        const enclosures = this.state.selectedFacility.enclosures.map((value, indx) => {
            if (value.id === id && e.target.scrollWidth > e.target.clientWidth) {
                if (type === 'title') value.isOpenTitle = true;
                if (type === 'udi') value.isOpenUdi = true;
                if (type === 'location') value.isOpenLocation = true;
            } else {
                value.isOpenTitle = false;
                value.isOpenUdi = false;
            }
            return value;
        });
        this.setState({ selectedFacility: { ...this.state.selectedFacility, enclosures } });
    }

    render = () => {
        const { history } = this.props;
        const { 
            isLoading, 
            facilities, 
            selectedFacility, 
            isLogsLoading,
            enclosureLogs,
            headers,
        } = this.state; 
        
        return (
            <React.Fragment>
                <div className="container-subnav enclosure-logs-container">
                    <button className="btn tertiary size-tiny back-button" onClick={() => history.replace('/facilities')}>
                        <img src={iconBack} alt="" width="20" />
                    </button>
                    <Typography variant="h4" className="uvc-admin-grand-title">Enclosure Logs</Typography>
                    <div className="free-space"></div>
                    <div className="free-space"></div>
                </div>
                <div className="container-dashboard">
                    {!isLoading && facilities && selectedFacility && enclosureLogs && (
                        <div className="inner">
                            <div className="logs-grid-container">
                                <div className="logs-grid-item">
                                    <Typography variant="h4" className="location">Facility</Typography>
                                    <Grid container justifyContent="flex-start" alignItems="center" className="facilities-name-list">
                                        {facilities.map(item => (
                                            <Grid 
                                                item 
                                                className={item.id === selectedFacility.id 
                                                            ? "facilities-name-item active" 
                                                            : "facilities-name-item"} 
                                                key={item.id}
                                                onClick={() => this.setSelectedFacility(item)}
                                                >
                                                <Tooltip title={item.name} placement="top" disableHoverListener={!item.isOpenTitle}>
                                                    <Typography variant="h5" onMouseEnter={(e) => this.mouseEnter(e, item.id)}>{item.name}</Typography>
                                                </Tooltip>
                                                <div className="badge size-small">{item['enclosures'].length}</div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                                <div className="logs-grid-item">
                                    <Tooltip title={selectedFacility.name} placement="top">
                                        <Typography variant="h4" className="facility-title">{selectedFacility.name}</Typography>
                                    </Tooltip>
                                    {selectedFacility.enclosures.length !== 0 && (
                                        <div className="enclosure-logs">
                                            {!isLogsLoading && (
                                                <React.Fragment>
                                                    {selectedFacility.enclosures.map(item => (
                                                        <CustomizeAccordion
                                                            key={item.udi}
                                                            title={
                                                                <React.Fragment>
                                                                    <span>{this.connectionStatusRendering(item['connection_status'], item['ok?'])}</span>
                                                                    <Tooltip title={item.nickname} placement="top" disableHoverListener={!item.isOpenTitle}>
                                                                        <span onMouseEnter={(e) => this.enCloseureMouseEnter(e, item.id, 'title')}>{item.nickname}</span>
                                                                    </Tooltip>
                                                                    <Tooltip title={item.udi} placement="top" disableHoverListener={!item.isOpenUdi}>
                                                                        <span className="badge size-small" onMouseEnter={(e) => this.enCloseureMouseEnter(e, item.id, 'udi')}>
                                                                            {item.udi}
                                                                    </span>
                                                                    </Tooltip>
                                                                </React.Fragment>
                                                            }
                                                            titleStyle="enclosure-logs-title"
                                                            additionalInfo={
                                                                <Tooltip title={item.location} placement="top" disableHoverListener={!item.isOpenLocation}>
                                                                    <Typography variant="h5" onMouseEnter={(e) => this.enCloseureMouseEnter(e, item.id, 'location')}>{item.location}</Typography>
                                                                </Tooltip>
                                                            }
                                                            children={
                                                                <React.Fragment>
                                                                    {enclosureLogs[item.id] && enclosureLogs[item.id].length !== 0 
                                                                    ? (
                                                                        <CustomizeTable 
                                                                            headers={headers.filter(el => el.isShow)}
                                                                            children={
                                                                                <React.Fragment>
                                                                                    {enclosureLogs[item.id].map(row => (
                                                                                        <TableRow
                                                                                            hover
                                                                                            key={row.id}
                                                                                            classes={{
                                                                                                root: "normal-cell",
                                                                                            }}
                                                                                        >
                                                                                            {headers.map((header) => header.isShow && (
                                                                                                <TableCell style={{width: header.width ? header.width : 'auto'}} className="cell-item" key={header.key} id={header.key}>
                                                                                                    {/* {header.key === 'performed_at' ? this.pstTimeConverter(row[header.key]) : row[header.key]} */}
                                                                                                    {row[header.key]}
                                                                                                </TableCell>
                                                                                            ))}
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    ): (
                                                                        <Typography variant="h5" className="empty-logs">Enclosure not yet connected</Typography>
                                                                    )}
                                                                </React.Fragment>
                                                            }                                             
                                                        />
                                                    ))} 
                                                </React.Fragment>
                                            )}
                                            {isLogsLoading && <DotLoading title={'Retrieving logs...'}/>}
                                        </div>
                                    )}
                                    {selectedFacility.enclosures.length === 0 && (
                                        <div className="no-enclosure-logs">
                                            <div className="message-container">
                                                <img src={iconEnclosures} alt="No-logs" width="130" />
                                                <Typography variant="h4">No enclosures at this facility</Typography>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {isLoading && <DotLoading title={"Retrieving logs..."} />}
                </div>
            </React.Fragment>
        )
    }
}
