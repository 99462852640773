import React from 'react'
import { CustomizeSelect } from 'components/common';

import "components/uv-admin/details/Detail.scss";

export default class SoftwareSelection extends React.Component {

  render = () => {
      const { setVersion, selectedVersion, versions } = this.props;

      return (
          <div className="software-selection-container">
              {versions && (
                  <CustomizeSelect id="software" title="Expected Software Version" required handleChange={(val) => setVersion(val)} value={selectedVersion} options={versions} />
              )}
          </div>
      );
  };
}
