import React from "react";
import _ from "lodash";
import { Grid, Typography } from '@material-ui/core';

import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";
import "components/uv-admin/details/Detail.scss";

import { TextField, Switch, CustomizeSelect, Spinner } from "components/common";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";
import { ADD_SINGLE_OPERATOR } from "components/uv-admin/details/operators/ModifyOperatorsModal";
import { ADD_OPERATOR, EDIT_OPERATOR } from "components/uv-admin/details/operators/Operators";

import { UVCAdmin } from "helpers/constant"

import { report as reportAPI } from "services/api";

export default class SingleAddModal extends React.Component {
    initialState = {
        operatorModal: UVCAdmin.facilityDetails.operators.operatorModal.addModal,
        operatorPayload: null,
        isOperatorProcessing: false,
        stopFlag: false
    }

    state = {...this.initialState}

    componentDidMount = () => {
        if(this.props.action === ADD_OPERATOR && this.props.tab === ADD_SINGLE_OPERATOR) {
            let operatorModal = this.clearModalError();
            let initialModal = {}
            operatorModal.forEach(field => {
                initialModal[field.key] = this.renderInitialValue(field.key)
            })
            this.setState({
                operatorPayload: initialModal
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.operator
            && !this.state.stopFlag
            && this.props.action === EDIT_OPERATOR) {
            let operatorModal = this.clearModalError();
            let initialModal = {}
            operatorModal.forEach(field => {
                initialModal[field.key] = this.props.operator[field.key]
            })
            this.setState({
                operatorPayload: initialModal,
                stopFlag: true
            })
        }
    }

    renderInitialValue = (key) => {
        switch (key) {
            case 'deactivate_duplicate_badges': return true;
            case 'department_id': 
                return this.props.department 
                        && this.props.department.length !== 0 
                        ? this.props.departments[0].key 
                        : ''; 
            default: return '';
        }
    }

    formValidation = (stateData, stateModal) => {
        const data = this.state[stateData];
        const modal = this.props.action === ADD_OPERATOR ? 
                        this.state[stateModal].filter(el => el.key !== 'id') : 
                        this.state[stateModal]
        modal.forEach((el, index) => {
            switch(true) {
                case !data:
                case !data.hasOwnProperty(el.key):
                case data && data[el.key] === "":
                    el.isError = true;
                    el.errorMessage = "Required Field";
                    break
                default:
                    break;
            }
            this.setState({
                [stateModal]: [
                ...this.state[stateModal].slice(0, index),
                Object.assign({}, this.state[stateModal][index], el),
                ...this.state[stateModal].slice(index + 1),
            ]});
        })
        return !Boolean(_.find(modal, ['isError', true]))
    }

    onChange = (key, value) => {
        this.setState(
            (prevState) => ({
                operatorPayload: {
                    ...prevState.operatorPayload,
                    [key]: value,
                },
            })
        );
    };

    handleError = (err) => {
        if (err && err.response && err.response.status && err.response.status === 422) {
            let errors = err.response.data.errors;            
            let errorModal = this.state.operatorModal.map(field => {
                let obj = _.indexOf(_.keys(errors), field.key) !== -1 
                        ? {...field, isError: true, errorMessage:  _.result(errors, field.key)} 
                        : {...field, isError: false, errorMessage: ''}
                return obj
            })
            this.setState({operatorModal: errorModal})
        }
        else {
            this.props.setStackToaster(
                (
                    <ToasterMessageTemplate 
                        icon={iconError}
                        title={'Error when adding/updating Operator!'}
                    />
                )
            )
        }
        this.setState({
            isOperatorProcessing: false,
        })
    }

    addNewOperator = (e) => {
        e.preventDefault();
        if (!this.formValidation('operatorPayload', 'operatorModal')) {
            return
        } else {
            this.setState({isOperatorProcessing: true})
            return reportAPI.addOperator(this.props.facilityId, this.state.operatorPayload)
            .then(res => {
                if(res) {
                    this.setState({
                        isOperatorProcessing: false,
                    }, () => {
                        this.props.setStackToaster(
                            (
                                <ToasterMessageTemplate 
                                    icon={iconSuccess}
                                    title={'New operator added to facility.'}
                                />
                            )
                        )
                        this.handleClose(false, this.props.submit)
                    })
                }
            })
            .catch(err => this.handleError(err))
        }
    }

    editOperator = (e) => {
        e.preventDefault();
        this.setState({isOperatorProcessing: true})
        const data = {
            'department_id': this.state.operatorPayload['department_id']
        }
        return reportAPI.editOpertor(this.props.facilityId, this.state.operatorPayload.id, data)
        .then(res => {
            if(res) {
                this.setState({
                    isOperatorProcessing: false,
                }, () => {
                    this.props.setStackToaster(
                        (
                            <ToasterMessageTemplate 
                                icon={iconSuccess}
                                title={'Operator Department Updated'}
                            />
                        )
                    )
                    this.handleClose(false, this.props.submit)
                })
            }
        })
        .catch(err => this.handleError(err))
    }

    clearModalError = () => {
        let initializeModal = this.state.operatorModal.map(field => {
            let obj = {...field, isError: false, errorMessage: ''};
            return obj
        })
        this.setState({ operatorModal: initializeModal});
        return initializeModal;
    }

    handleClose = (val, cb) => {
        this.setState(this.initialState, () => {
            let initialEquipmentPayload = {}
            let initializeModal = this.clearModalError();
            initializeModal.forEach(field => {
                initialEquipmentPayload[field.key] = this.renderInitialValue(field.key)
            })
            this.setState({
                equipmentPayload: initialEquipmentPayload,
            }, () => cb(val))
        })
    }
    
    render = () => {
        const { closeForm, goBack, departments, action } = this.props;
        const { operatorModal, operatorPayload, isOperatorProcessing } = this.state;

        return (
            <React.Fragment>
                <div className="require-title-container">
                    <h6 className="require-title required">Required</h6>
                </div>
                <Grid container justify="center" alignItems="flex-start" alignContent="flex-start" spacing={2}>
                    {operatorPayload && operatorModal.filter(field => field.isShow).map(field => field.key !== 'deactivate_duplicate_badges' && field.key !== 'department_id' ? (
                        <Grid item xs={field['grid-xs']} key={field.key}>
                            <TextField 
                                    title={field.title} 
                                    id={"edit-operators"} 
                                    key={field.key}
                                    disabled={action === EDIT_OPERATOR} 
                                    requiredStyle={field.isRequired && "required"} 
                                    errorName={field.isError && field.errorMessage} 
                                    value={operatorPayload[field.key]}
                                    onChange={(val) => this.onChange(field.key, val)}
                                    onFocus={(e) => this.setState((prevState) => ({
                                        operatorModal: prevState.operatorModal.map((obj) => (
                                            obj.key === field.key && obj.isError
                                            ? Object.assign(obj, {isError: false, errorMessage: ''})
                                            : obj
                                        ))
                                    }))} 
                                autoFocus={field.key === 'first_name' ? 'autoFocus' : ''}
                                    />
                        </Grid>
                    ) : (
                        (field.key === 'deactivate_duplicate_badges' ? (
                            <Grid item xs={field['grid-xs']} key={field.key}>
                                {action === ADD_OPERATOR && (
                                    <div className="help-container">
                                        <Switch
                                            title={field.title}
                                            checked={operatorPayload[field.key]}
                                            setValue={(val) => {
                                                this.onChange(field.key, val)
                                                this.clearModalError();
                                            }}
                                        />
                                        <Typography variant="h5">
                                            <span>Please note: </span>
                                            {UVCAdmin.facilityDetails.operators.duplicateIdHelpText}
                                        </Typography>
                                    </div>
                                )}
                            </Grid>
                        ) : (
                            <Grid item xs={field['grid-xs']} key={field.key}>
                                <div className="select-container">
                                    <CustomizeSelect
                                        error={field.isError}
                                        errorName={field.errorMessage} 
                                        id="select-department"
                                        title={field.title}
                                        className="department-selection"
                                        required
                                        options={departments}
                                        value={operatorPayload[field.key]}
                                        handleChange={(val) => this.onChange(field.key, val)}
                                        onOpen={(e) => this.setState((prevState) => ({
                                            operatorModal: prevState.operatorModal.map((obj) => (
                                                obj.key === field.key && obj.isError
                                                ? Object.assign(obj, {isError: false, errorMessage: ''})
                                                : obj
                                            ))
                                        }))}
                                    />
                                </div>
                            </Grid>
                        ))
                    ))}
                </Grid>
                {action === ADD_OPERATOR && operatorPayload && !isOperatorProcessing && (
                    <div className="action-container multiple-actions">
                        <button className="btn tertiary size-normal" onClick={() => this.handleClose(null, goBack)}>
                            Back
                        </button>
                        <button className="btn tertiary size-normal" onClick={() => this.handleClose(false, closeForm)}>
                            Cancel
                        </button>
                        <button className="btn primary size-normal" onClick={(e) => this.addNewOperator(e)}>
                            Add Operator
                        </button>
                    </div>
                )}
                {action === EDIT_OPERATOR && operatorPayload && !isOperatorProcessing && (
                    <div className="action-container" style={{ marginTop: "40px" }}>
                        <button className="btn tertiary size-normal" onClick={() => this.handleClose(false, closeForm)}>
                            Cancel
                        </button>
                        <button className="btn primary size-normal" onClick={(e) => this.editOperator(e)}>
                            Save
                        </button>
                    </div>
                )}
                {isOperatorProcessing && (
                    <div className="action-container multiple-actions">
                        <div className="free-space"></div>
                        <Spinner open={isOperatorProcessing} fullscreen={false} />
                        <div className="free-space"></div>
                    </div>
                )}
            </React.Fragment>
        )
    };
}
