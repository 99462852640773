import { UVAdmin, UVReport, FacilityDetails, EnclosureLogs } from 'components';

const routes = [
  {
    path: '/logout',
    name: 'Sign Out',
    icon: '',
    component: null,
  },
  {
    path: '/report',
    name: 'My Report',
    icon: '',
    component: UVReport,
  },
  {
    path: '/facilities/:id',
    name: 'Facility',
    icon: '',
    component: FacilityDetails,
  },
  {
    path: '/enclosure_logs',
    name: 'Enclosure Logs',
    icon: '',
    component: EnclosureLogs,
  },
  {
    path: '/facilities',
    name: 'My Facilities',
    icon: '',
    component: UVAdmin,
    exact: true,
  },
  { redirect: true, path: '/', pathTo: '/report', name: 'My Report' },
];

export default routes;
