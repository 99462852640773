import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import warningIcon from "images/icon-warning-large-red.svg";
import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";

import { CustomizeSelect, Modal, TextField, Switch } from "components/common";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage"
import { EDIT_USER, ADD_USER, DELETE_USER } from "components/uv-admin/details/users/Users";
import { UVCAdmin, ERROR_MESSAGE, TOAST_MESSAGE } from "helpers/constant";
import { validateEmail } from "helpers/functions";
import { report as reportAPI } from "services/api";

export default class ModifyUser extends React.Component {
    state = {
        selectedUser: null,
        isAddAnotherUser: false,
        stopFlag: false,
        userRoles: [
            {
                key: "user",
                title: "User",
            },
            {
                key: "facility_admin",
                title: "Facility Admin",
            },
        ],
        isReloadNeeded: false
    };

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.isUserSessionExpire !== this.props.isUserSessionExpire && this.props.isUserSessionExpire === true) {
            this.handleClose(false);
        }
        if (prevProps.user !== this.props.user && (this.props.action === EDIT_USER || this.props.action === DELETE_USER)) {
            this.setState({ selectedUser: this.props.user, stopFlag: true });
        } else if (this.props.action === ADD_USER && !this.state.stopFlag) {
            this.setState({
                selectedUser: {
                    email: "",
                    role_name: this.state.userRoles[0].key,
                },
                stopFlag: true,
            });
        }
    };

    onChange = (key, value) => {
        this.setState((prevState) => ({
            selectedUser: {
                ...prevState.selectedUser,
                [key]: value,
            },
        }));
    };

    submitUser = (facilityId) => {
        if (!this.isFormValidated()) {
            return;
        } else {
            this.setState({ isProcessing: true });

            reportAPI
                .addUser(facilityId, this.state.selectedUser)
                .then((res) => {
                    if (res) {
                        this.setState({
                            isProcessing: false,
                            selectedUser: null,
                        }, () => 
                            // this.props.setToaster(toasterMessageType.SUCCESS, TOAST_MESSAGE.ADD_USER_SUCCESS)
                            this.props.setStackToaster(
                                (
                                    <ToasterMessageTemplate 
                                    icon={iconSuccess}
                                    title={TOAST_MESSAGE.ADD_USER_SUCCESS}
                                />
                                )
                            )
                        );
                    }
                    if (!this.state.isAddAnotherUser) {
                        this.setState({
                            isAddAnotherUser: false,
                            isReloadNeeded: false
                        }, () => {
                            this.props.onClose(true);
                            this.props.onSubmit(true);
                        });
                    } else {
                        this.setState({
                            isReloadNeeded: true,
                            selectedUser: {
                                email: "",
                                role_name: this.state.userRoles[0].key,
                            }
                        })
                    }
                })
                .catch((err) => this.handleError(err));
        }
    };

    updateUser = (facilityId) => {
        this.setState({ isProcessing: true });
        const { selectedUser } = this.state;
        return reportAPI.updateUser(facilityId, selectedUser.id, selectedUser.role_name)
            .then(res => {
                if (res) {
                    this.setState({
                        isProcessing: false,
                        selectedUser: null,
                    }, () => {
                        // this.props.setToaster(toasterMessageType.SUCCESS, 'User Role updated successfully!')
                        this.props.setStackToaster(
                            (
                                <ToasterMessageTemplate 
                                    icon={iconSuccess}
                                    title={'User Role updated successfully!'}
                                />
                            )
                        )
                        this.props.onSubmit(true);
                    });
                }
            })
            .catch(err => this.handleError(err))
    }

    deleteUser = (facilityId) => {
        this.setState({ isProcessing: true });
        const { selectedUser } = this.state;
        return reportAPI.updateUser(facilityId, selectedUser.id, '')
            .then(res => {
                if (res) {
                    this.setState({
                        isProcessing: false,
                        selectedUser: null,
                    }, () => {
                        // this.props.setToaster(toasterMessageType.SUCCESS, 'User has been removed!')
                        this.props.setStackToaster(
                            (
                                <ToasterMessageTemplate 
                                    icon={iconSuccess}
                                    title={'User has been removed!'}
                                />
                            )
                        )
                        this.props.onSubmit(true);
                    });
                }
            })
            .catch(err => this.handleError(err))
    }

    handleError = (err) => {
        if (err && err.response && err.response.status && err.response.status === 422) {
            let errors = err.response.data.errors;
            if (_.indexOf(_.keys(errors), UVCAdmin.facilityDetails.users.userModal.addUserModal[0].key) !== -1) {
                this.setState({ errEmailInput: _.result(errors, UVCAdmin.facilityDetails.users.userModal.addUserModal[0].key )}); 
            }
        }
        else {
            // this.props.setToaster(toasterMessageType.ERROR, TOAST_MESSAGE.ADD_USER_ERROR);
            this.props.setStackToaster(
                (
                    <ToasterMessageTemplate 
                        icon={iconError}
                        title={TOAST_MESSAGE.ADD_USER_ERROR}
                    />
                )
            )
        }
        this.setState({
            isProcessing: false,
        })
    }

    isFormValidated = () => {
        const selectedUser = this.state.selectedUser;

        if (!selectedUser || !selectedUser.email || selectedUser.email === "") {
            this.setState({ errEmailInput: ERROR_MESSAGE.REQUIRED_FIELD });
            return false;
        } else {
            if (!validateEmail(selectedUser.email)) {
                this.setState({ errEmailInput: ERROR_MESSAGE.INVALID_EMAIL });
                return false;
            }
        }
        return true;
    };

    handleClose = (isOpen, isReloadNeeded) => {
        this.setState({ 
            errEmailInput: null, 
            errUserRoleInput: null,
            selectedUser: null,
            stopFlag: false, 
        }, () => this.props.onClose(isOpen, isReloadNeeded));
    };

    render = () => {
        const { open, action, facilityId } = this.props;
        const { userRoles, isAddAnotherUser, selectedUser, errEmailInput, isProcessing, isReloadNeeded } = this.state;
        const modifyUserModalConstants = UVCAdmin.facilityDetails.users.userModal.addUserModal;
        return (
            <Modal
                open={open}
                title={action === ADD_USER ? "Add a User" : action === EDIT_USER ? "Edit User" : "Remove User"}
                content={
                    <div>
                        <form className="edit-user-modal">
                            {(action === ADD_USER || action === EDIT_USER) && (
                                <React.Fragment>
                                    <div className="require-title-container">
                                        <h6 className="require-title required">Required</h6>
                                    </div>
                                    <Grid container spacing={2} justify="flex-start" alignItems="stretch" className="textfield-container">
                                        {modifyUserModalConstants.map((field) =>
                                            field.key !== "role_name" ? (
                                                <Grid item xs={field["grid-xs"]} key={field.key}>
                                                    <TextField
                                                        title={field.title}
                                                        id={"edit-user"}
                                                        requiredStyle={"required"}
                                                        errorName={errEmailInput}
                                                        value={selectedUser && selectedUser[field.key] ? selectedUser[field.key] : ""}
                                                        disabled={action === EDIT_USER}
                                                        onChange={(e) => this.onChange(field.key, e)}
                                                        onFocus={() => this.setState({ errEmailInput: null })}
                                                        autoFocus={action === ADD_USER && field.key === 'email' ? 'autoFocus' : ''}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item xs={field["grid-xs"]} key={field.key}>
                                                    <CustomizeSelect
                                                        className="config-selection user-role"
                                                        title={field.title}
                                                        required
                                                        value={selectedUser && selectedUser[field.key] ? selectedUser[field.key] : userRoles[0].key}
                                                        options={userRoles}
                                                        handleChange={(val) => this.onChange(field.key, val)}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    {action === ADD_USER && (
                                        <Switch
                                            title={"Add another user"}
                                            checked={isAddAnotherUser}
                                            setValue={(val) =>
                                                this.setState({
                                                    isAddAnotherUser: val,
                                                })
                                            }
                                        />
                                    )}
                                </React.Fragment>
                            )}
                            {action === DELETE_USER && selectedUser && 
                                <div className="remove-container">
                                    <img src={warningIcon} className="remove-icon" alt="" width="44" />
                                    <Typography variant="h6" className="remove-message">{UVCAdmin.facilityDetails.users.deleteMessage}</Typography>
                                    <Typography variant="h6" className="remove-title">{selectedUser.name || selectedUser.email}</Typography>
                                </div>
                            }
                        </form>
                    </div>
                }
                action={
                    <div className="edit-user-modal">
                        <div className="action-container">
                            <button className="btn tertiary size-normal" onClick={() => this.handleClose(false, isReloadNeeded)}>
                                Cancel
                            </button>
                            {action === ADD_USER && (
                                <button className="btn primary size-normal" onClick={() => this.submitUser(facilityId)}>
                                    {'Add User'}
                                </button>
                            )}
                            {action === DELETE_USER && (
                                <button className="btn primary size-normal delete-button" onClick={() => this.deleteUser(facilityId)}>
                                    {'Remove'}
                                </button>
                            )}
                            {action === EDIT_USER && (
                                <button className="btn primary size-normal" onClick={() => this.updateUser(facilityId)}>
                                    {'Save'}
                                </button>
                            )}
                        </div>
                    </div>
                }
                onClose={(isOpen) => this.handleClose(isOpen, isReloadNeeded)}
                loading={isProcessing}
            />
        );
    };
}
