import React from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import "./style.scss";

import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";
import iconClose from "images/icon-close-white.svg";

import { TextField, ToasterMessage, Spinner } from "components/common";
import { UserProfile, ERROR_MESSAGE, toasterMessageType, TOAST_MESSAGE } from "helpers/constant";
import { user as userAPI } from "services/api";

// Phone Number Formatting
import { AsYouType } from 'libphonenumber-js';

const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className='titleDialog' {...other} >
            <div className="title">{children}</div>
            <button className="btn primary size-tiny transparent" onClick={onClose}>
                <img src={iconClose} alt="" width="18" />
            </button>
        </MuiDialogTitle>
    );
};
export default class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            formValue: {},
            stateProfile: [],
            toasterMessage: null,
            toasterType: null,
        };
    }

    componentDidMount() {
        this.setState((prevState) => ({
            formValue: {
                ...this.props.profile,
                phone_number: new AsYouType('US').input(this.props.profile.phone_number)
            },
            stateProfile: UserProfile.modal,
        }))
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.isUserSessionExpire !== this.props.isUserSessionExpire && this.props.isUserSessionExpire === true) {
            this.handleClose(null);
        }
    }

    onChange = (key, value) => {
        let val = value;
        if (key === 'phone_number') {
            val = value ? new AsYouType('US').input(value) : value;
        }
        this.setState((prevState) => ({
            formValue: {
                ...prevState.formValue,
                [key]: val,
            },
        }));
    };

    formValidation = () => {
        const { stateProfile, formValue } = this.state;
        const modal = stateProfile.reduce((acc, curr) => {
            if (curr.isRequired && formValue[curr.key] === "") {
                curr.isError = true;
                curr.errorMessage = ERROR_MESSAGE.REQUIRED_FIELD;
            }
            acc.push(curr);
            return acc;
        }, []);
        this.setState({ stateProfile: modal });
        return Boolean(_.find(modal, ['isError', true]))
    }

    updateProfile = () => {
        if (this.formValidation()) {
            return;
        } else {
            this.setState({ isProcessing: true });
            const { formValue } = this.state;
            const data = {
                id: formValue.id,
                first_name: formValue.first_name,
                last_name: formValue.last_name,
                employee_id: formValue.employee_id,
                phone_number: formValue.phone_number,
                department: formValue.department,
                title: formValue.title,
                user_id: formValue.user_id
            }
            return userAPI.updateProfile(data.id, data)
                .then(res => {
                    if (res) {
                        this.setState({
                            isProcessing: false,
                        }, () => {
                            this.setState({
                                toasterMessage: TOAST_MESSAGE.UPDATE_PROFILE,
                                toasterType: toasterMessageType.SUCCESS,
                            })
                            this.handleClose(res);
                        });
                    }
                })
                .catch(err => this.handleError(err))
        }
    }

    handleError = (err) => {
        const { stateProfile } = this.state;
        if (err && err.response && err.response.status && err.response.status === 422) {
            let errors = err.response.data.errors;
            const modal = stateProfile.reduce((acc, curr) => {
                if (errors.hasOwnProperty(curr.key)) {
                    curr.isError = true;
                    curr.errorMessage = errors[curr.key][0];
                }
                acc.push(curr);
                return acc;
            }, []);
            this.setState({ stateProfile: modal });
        } else if (err && err.response && err.response.status) {
            const error = err.response.data.error;
            this.setState({
                toasterMessage: error,
                toasterType: toasterMessageType.ERROR,
            })
            this.handleClose(null);
        } else {
            this.setState({
                toasterMessage: TOAST_MESSAGE.ADD_USER_ERROR,
                toasterType: toasterMessageType.ERROR,
            })
            this.handleClose(null);
        }
        this.setState({ isProcessing: false })
    }

    handleClose = (profile) => {
        const modal = this.state.stateProfile.reduce((acc, curr) => {
            curr.isError = false;
            curr.errorMessage = '';
            acc.push(curr);
            return acc;
        }, []);
        this.props.onClose(profile);
        const formValue = profile ? {
            ...profile,
            phone_number: new AsYouType('US').input(profile.phone_number)
        } :
            {
                ...this.props.profile,
                phone_number: new AsYouType('US').input(this.props.profile.phone_number)
            };
        this.setState({ formValue, stateProfile: modal });
    };

    handleRemoveInputError = (key) => {
        const modal = this.state.stateProfile.reduce((acc, curr) => {
            if (curr.key === key) {
                curr.isError = false;
                curr.errorMessage = '';
            }
            acc.push(curr);
            return acc;
        }, []);
        this.setState({ stateProfile: modal });
    }

    render() {
        const { open } = this.props;
        const { formValue, stateProfile, toasterMessage, toasterType, isProcessing } = this.state;
        return (
            <React.Fragment>
                <ToasterMessage
                    open={toasterMessage !== null}
                    title={toasterMessage}
                    icon={toasterType === toasterMessageType.ERROR ? iconError : iconSuccess}
                    handleClose={(cb) => this.setState({ toasterMessage: cb })} />
                <Dialog
                    open={open}
                    onClose={() => this.handleClose(null)}
                    aria-labelledby="dialog-title"
                >
                    <DialogTitle id="dialog-title" onClose={() => this.handleClose(null)}>
                        {UserProfile.title}
                    </DialogTitle>
                    <DialogContent >
                        <form className="edit-profile-modal">
                            <React.Fragment>
                                <div className="require-title-container">
                                    <h6 className="require-title required">Required</h6>
                                </div>
                                <Grid container spacing={2} justify="flex-start" alignItems="stretch" className="textfield-container">
                                    {stateProfile.map((field) => (
                                        <Grid item xs={field["grid-xs"]} key={field.key}>
                                            <TextField
                                                title={field.title}
                                                id={field.key}
                                                requiredStyle={field.isRequired ? "required" : ''}
                                                errorName={field.isError && field.errorMessage}
                                                value={formValue && formValue[field.key] ? formValue[field.key] : ""}
                                                onChange={(e) => this.onChange(field.key, e)}
                                                onFocus={() => this.handleRemoveInputError(field.key)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </React.Fragment>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {isProcessing ? (
                            <Spinner open={isProcessing} fullscreen={false} />
                        ) : (
                            <>
                                <button className="btn cancel size-normal" onClick={() => this.handleClose(null)}>
                                    Cancel
                                 </button>
                                <button className="btn primary size-normal" onClick={this.updateProfile}>
                                    Save
                                </button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };
}
