import React from "react";

import "./Sub.scss";
import iconEnclosures from "images/icon-nav-enclosures.svg";
import iconUsers from "images/icon-nav-users.svg";
import iconDepartments from "images/icon-nav-departments.svg";
import iconOperators from "images/icon-nav-operators.svg";

import { UVCFooter } from "helpers/constant";
import { DotLoading } from "components/common";
export default class Sub extends React.Component {
    state = {
        activeNavItem: this.props.activeTab,
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.activeTab !== this.props.activeTab) {
            this.setState({ activeNavItem: this.props.activeTab });
        }
    };

    iconRender = (iconName) => {
        switch (iconName) {
            case "iconEnclosures":
                return iconEnclosures;
            case "iconUsers":
                return iconUsers;
            case "iconDepartments":
                return iconDepartments;
            case "iconOperators":
                return iconOperators;
            default:
                return;
        }
    };

    changeActiveNabItem = (index) => {
        this.setState({ activeNavItem: index });
        this.props.setActiveTab(index);
    };

    render = () => {
        const { children, title, loading, loadingTitle } = this.props;
        const { activeNavItem } = this.state;

        return (
            <React.Fragment>
                <div className="container-subnav">{title && title}</div>
                {loading ? (
                    <DotLoading className="special-loading" title={loadingTitle} />
                ) : (
                    <React.Fragment>
                        <div className="sub-grid-container">
                            <div className="sidebar">
                                {UVCFooter.links.map((item, index) => (
                                    <div key={item.key} className="sidebar-item" onClick={() => this.changeActiveNabItem(index)}>
                                        <div className={index === activeNavItem ? "active" : "inner"}>
                                            <img src={this.iconRender(item.iconName)} alt="" />
                                            <h6>{item.key === "uv-footer-enclosure" ? item.title.split(" ")[1] : item.title}</h6>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="content">{children}</div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };
}
