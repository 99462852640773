import React from "react";
import _ from "lodash";
import { TableCell, TableRow } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";

import "components/uv-admin/details/Detail.scss";
import iconEmptyUsers from "images/graphic-users.svg";
import iconEdit from "images/icon-edit.svg";
import iconDelete from "images/icon-delete.svg";

import { defaultRowsPerPage, UVCAdmin, TOAST_MESSAGE, uvcAdminRole, facilityAdminRole } from "helpers/constant";
import { formatRole } from "helpers/functions";
import { report as reportAPI } from "services/api";

import { CustomizeTable } from "components/common";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";
import { ModifyUserModal } from "components/uv-admin/details";

export const EDIT_USER = "edit";
export const ADD_USER = "add";
export const DELETE_USER = "delete";

export default class Users extends React.Component {

    state = {
        headers: UVCAdmin.facilityDetails.users.userModal.headers,
        rowsPerPage: defaultRowsPerPage,
        currentPage: 1,
        userAction: ADD_USER,
        isEditUserModal: false,
        selectedUser: null,
    };

    openModifyUserModal = () =>  {
        this.setState({isEditUserModal: true, userAction: ADD_USER});
    }

    resendInvitation = (userId) => {
        reportAPI.resendInvitation(this.props.facilityId, userId).then(res => {
            // this.props.setToaster(toasterMessageType.SUCCESS, TOAST_MESSAGE.RESEND_INVITATION_SUCCESS)
            this.props.setStackToaster(
                (
                    <ToasterMessageTemplate 
                        icon={iconSuccess}
                        title={TOAST_MESSAGE.RESEND_INVITATION_SUCCESS}
                    />
                )
            )
        }, (err) => {
            // this.props.setToaster(toasterMessageType.ERROR, TOAST_MESSAGE.RESEND_INVITATION_ERROR);
            this.props.setStackToaster(
                (
                    <ToasterMessageTemplate 
                        icon={iconError}
                        title={TOAST_MESSAGE.RESEND_INVITATION_ERROR}
                    />
                )
            )
        });
    }

    render = () => {
        const { users, fetchFacilityDetail, setToaster, roles, facilityId, setStackToaster, isUserSessionExpire } = this.props  
        const { headers, rowsPerPage, currentPage, userAction, isEditUserModal, selectedUser } = this.state;

        return (
            <React.Fragment>
                <div className="details-container" id="users">
                    {users && users.length > 0 ? (
                        <React.Fragment>
                            <div className="details-header">
                                <h5 className="title">{UVCAdmin.facilityDetails.users.grantTitle}</h5>
                                <div className="flex-spacer"></div>
                                {(Boolean(_.find(roles, {'name': uvcAdminRole, facility_id: null }))
                                || Boolean(_.find(roles, {'name': facilityAdminRole, facility_id: facilityId}))) && (
                                    <button 
                                        className="btn primary size-small" 
                                        onClick={() => this.openModifyUserModal() }>
                                            {UVCAdmin.facilityDetails.users.addAnUsers}
                                    </button>
                                )}
                            </div>
                            <div className="users-content">
                                <CustomizeTable
                                    headers={headers}
                                    children={
                                        <React.Fragment>
                                            {users.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((row) => (
                                                <TableRow
                                                    hover
                                                    key={row.id}
                                                    classes={{
                                                        root: row["invitation_pending"] === "Yes" ? "disable-cell" : "normal-cell",
                                                    }}
                                                >
                                                    {headers.map(
                                                        (header) =>
                                                            header.isShow && (
                                                                <TableCell
                                                                    key={header.key}
                                                                    id={header.key}
                                                                    classes={{
                                                                        root: header.key === "role_name" ? "transparent-background caplitalize" : "transparent-background",
                                                                    }}
                                                                    className="cell-item"
                                                                    align={header.key === "invitation_pending" || header.key === "invitation_link" ? "right" : "left"}
                                                                    style={{width: header.width ? header.width : 'auto'}}
                                                                >
                                                                    {header.key !== "invitation_link" && header.key !== "invitation_pending" && header.key !== "role_name" && row[header.key]}
                                                                    {header.key === "invitation_link" 
                                                                    && row["invitation_pending"] === "Yes"
                                                                    && (Boolean(_.find(roles, {'name': uvcAdminRole, facility_id: null})) || Boolean(_.find(roles, {'name': facilityAdminRole, facility_id: facilityId})))
                                                                    && <button onClick={() => this.resendInvitation(row.id)}className="btn tertiary size-small">Resend Invitation</button>}
                                                                    {header.key === "actions" && (
                                                                        <div className="action-bar">
                                                                            {(Boolean(_.find(roles, {'name': uvcAdminRole, facility_id: null}))
                                                                            || Boolean(_.find(roles, {'name': facilityAdminRole, facility_id: facilityId}))) && (
                                                                                <React.Fragment>
                                                                                    <button 
                                                                                        className="btn primary size-small action-button"
                                                                                        onClick={() => this.setState({isEditUserModal: true, userAction: EDIT_USER, selectedUser: row})}>
                                                                                        <img src={iconEdit} width="15" alt="Edit User" />
                                                                                    </button>
                                                                                    <button 
                                                                                        className=" btn primary size-small action-button"
                                                                                        onClick={() => this.setState({isEditUserModal: true, userAction: DELETE_USER, selectedUser: row})}>
                                                                                        <img src={iconDelete} width="15" alt="Delete User" />
                                                                                    </button>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {header.key === "invitation_pending" && row["invitation_pending"] === "Yes" && <div className="badge size-small">Pending</div>}
                                                                    {header.key === "role_name" && formatRole(row[header.key])}
                                                                </TableCell>
                                                            )
                                                    )}
                                                </TableRow>
                                            ))}
                                        </React.Fragment>
                                    }
                                    pagination={
                                        <Pagination
                                            count={Math.ceil(users.length / rowsPerPage)}
                                            classes={{
                                                root: "pagination",
                                                ul: "pagination-item",
                                            }}
                                            showFirstButton
                                            showLastButton
                                            size="small"
                                            page={currentPage}
                                            onChange={(e, page) => this.setState({ currentPage: page })}
                                            shape="rounded"
                                        />
                                    }
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="no-details">
                            <div className="img-container">
                                <img src={iconEmptyUsers} alt="No-Users" width="130" />
                            </div>
                            <div className="content-container">
                                <div>
                                    <h1>{UVCAdmin.facilityDetails.users.grantTitle}</h1>
                                    <h5>{UVCAdmin.facilityDetails.users.noUsersDesc}</h5>
                                    {(Boolean(_.find(roles, {'name': uvcAdminRole, facility_id: null}))
                                    || Boolean(_.find(roles, {'name': facilityAdminRole, facility_id: facilityId}))) && (
                                        <button className="btn primary size-small" onClick={() => this.openModifyUserModal() }>
                                            {UVCAdmin.facilityDetails.users.addUser}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <ModifyUserModal 
                    open={isEditUserModal}
                    action={userAction}
                    user={selectedUser}
                    facilityId={this.props.facilityId}
                    onClose={(showFlag, reloadFlag) => {
                        this.setState({
                        isEditUserModal: showFlag,
                        selectedUser: null}, () => {
                            if (reloadFlag) fetchFacilityDetail();
                        });
                    
                    }}
                    onSubmit={(cb) => {
                        this.setState({
                            isEditUserModal: cb,
                            selectedUser: null
                        }); 
                        fetchFacilityDetail()
                        }
                    }
                    setToaster={(type, msg) => setToaster(type, msg)}
                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                    isUserSessionExpire={isUserSessionExpire}
                />
            </React.Fragment>
        );
    };
}
