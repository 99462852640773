import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import { ToasterMessageTemplate } from 'components/common/Toaster/ToasterMessage';
import { useSnackbar } from 'notistack';
import iconError from 'images/status_red.svg';
import { Spinner, TextField } from 'components/common';
import {
  UVCAcceptInvitation,
  ERROR_MESSAGE,
  markInput,
  TOAST_MESSAGE,
} from 'helpers/constant';
import { auth as authAPI, browserStorage } from 'services/api';
import './style.scss';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={markInput}
      placeholderChar={'_'}
      showMask
    />
  );
}

const initForm = {
  firstName: { value: '', error: false, isRequire: true, messageError: '' },
  lastName: { value: '', error: false, isRequire: true, messageError: '' },
  title: { value: '', error: false, isRequire: true, messageError: '' },
  employeeId: { value: '', error: false, isRequire: false, messageError: '' },
  department: { value: '', error: false, isRequire: true, messageError: '' },
  phone: {
    value: '(___)___-____',
    error: false,
    isRequire: true,
    messageError: '',
  },
  password: { value: '', error: false, isRequire: true, messageError: '' },
  confirmPassword: {
    value: '',
    error: false,
    isRequire: true,
    messageError: '',
  },
};

const AcceptInvitation = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [forms, setForms] = useState(initForm);
  const [isProcessing, setIsProcessing] = useState(false);
  const formatPhoneNumber = (value) => {
    return value
      .split('')
      .filter((item) => !isNaN(item) && item !== ' ')
      .join('');
  };

  const trimText = (text) => {
    return text?.trimStart()?.trimEnd();
  };

  const onCreateAccount = async () => {
    let oldForms = forms;
    let invalid = false;

    Object.keys(forms).forEach((item) => {
      oldForms = {
        ...oldForms,
        [item]: {
          ...forms[item],
          error: false,
          messageError: '',
        },
      };
      setForms(oldForms);

      const getPhone = formatPhoneNumber(forms.phone.value);
      if (item === 'phone' && getPhone.length === 0) {
        oldForms = {
          ...oldForms,
          phone: {
            ...forms.phone,
            error: true,
            messageError: ERROR_MESSAGE.REQUIRED_FIELD,
          },
        };
        setForms(oldForms);
        invalid = true;
      }

      if (item === 'password' && forms.password.value.length < 8) {
        oldForms = {
          ...oldForms,
          [item]: {
            ...forms[item],
            error: true,
            messageError: ERROR_MESSAGE.IS_TOO_SHORT,
          },
        };
        setForms(oldForms);
        invalid = true;
      }
      if (
        item === 'confirmPassword' &&
        forms.password.value !== forms.confirmPassword.value
      ) {
        oldForms = {
          ...oldForms,
          [item]: {
            ...forms[item],
            error: true,
            messageError: ERROR_MESSAGE.DOES_NOT_MATCH_PASSWORD,
          },
        };
        setForms(oldForms);
        invalid = true;
      }
      if (
        item !== 'phone' &&
        item !== 'employeeId' &&
        forms[item].value.length === 0
      ) {
        oldForms = {
          ...oldForms,
          [item]: {
            ...forms[item],
            error: true,
            messageError: ERROR_MESSAGE.REQUIRED_FIELD,
          },
        };
        setForms(oldForms);
        invalid = true;
      }
    });
    if (!invalid) {
      setIsProcessing(true);
      const payload = {
        user: {
          invitation_token: location.search.split('=')?.[1]
            ? location.search.split('=')?.[1]
            : '',
          profile_attributes: {
            last_name: trimText(forms.lastName.value),
            first_name: trimText(forms.firstName.value),
            title: trimText(forms.title.value),
            employee_id: trimText(forms.employeeId.value),
            department: trimText(forms.department.value),
            phone_number: formatPhoneNumber(forms.phone.value),
          },
          password: trimText(forms.password.value),
          password_confirmation: trimText(forms.confirmPassword.value),
        },
      };
      return authAPI
        .invitation(payload)
        .then((resp) => {
          setIsProcessing(false);
          if (browserStorage.getData('savedFacility')) {
            let savedFacility = browserStorage.getData('savedFacility');
            if (
              !browserStorage.getData('savedFacility')[resp.profile.user_id]
            ) {
              savedFacility[`${resp.profile.user_id}`] = {
                username: resp.profile.user_id,
                info: resp,
                featureReport: null,
                facilityReport: null,
              };
            }

            browserStorage.saveData('savedFacility', savedFacility);
          }
          location.href = window.location.origin;
          window.location.reload();
        })
        .catch((err) => {
          setIsProcessing(false);
          if (err?.response?.status === 422) {
            let errors = err?.response?.data?.errors;
            const errorsList = ["can't be blank", 'is invalid'];
            if (errorsList.includes(String(errors?.invitation_token))) {
              enqueueSnackbar(
                <ToasterMessageTemplate
                  icon={iconError}
                  title={TOAST_MESSAGE.INVALID_INVITATION_TOKEN}
                />
              );
            }
            if (
              String(errors?.['profile.phone_number']) ===
              ERROR_MESSAGE.IS_INVALID_NUMBER
            ) {
              oldForms = {
                ...oldForms,
                phone: {
                  ...forms.phone,
                  error: true,
                  messageError: ERROR_MESSAGE.IS_INVALID_NUMBER,
                },
              };
              setForms(oldForms);
            }
            if (String(errors?.['password']) === ERROR_MESSAGE.IS_TOO_SHORT) {
              oldForms = {
                ...oldForms,
                phone: {
                  ...forms.phone,
                  error: true,
                  messageError: ERROR_MESSAGE.IS_TOO_SHORT,
                },
              };
              setForms(oldForms);
            }
          }
        });
    }
  };

  const onChange = (value, name) => {
    const newForms = {
      ...forms,
      [name]: {
        value: value,
      },
    };
    setForms(newForms);
  };

  return (
    <div className='container-accept-invitation container-login'>
      <header>
        <div className='logo'></div>
      </header>

      <main>
        <form className='accept-invitation-form'>
          <h3>{UVCAcceptInvitation.title}</h3>
          <div className='desc'>
            <span>{UVCAcceptInvitation.description}</span>
          </div>
          <div className='required'>
            <span>{UVCAcceptInvitation.required}</span> <span />
          </div>
          <div className='field-row'>
            <TextField
              title={UVCAcceptInvitation.forms.firstName}
              type='text'
              className='input-primary size-full'
              placeholder={UVCAcceptInvitation.forms.firstName}
              autoFocus
              value={forms.firstName.value}
              name='firstName'
              onChange={(val, name) => onChange(val, name)}
              require='require'
              requiredStyle='required'
              errorName={forms.firstName.error && forms.firstName.messageError}
            />
            <TextField
              title={UVCAcceptInvitation.forms.lastName}
              type='text'
              className='input-primary size-full'
              placeholder={UVCAcceptInvitation.forms.lastName}
              autoFocus
              value={forms.lastName.value}
              name='lastName'
              onChange={(val, name) => onChange(val, name)}
              require='require'
              requiredStyle='required'
              errorName={forms.lastName.error && forms.lastName.messageError}
            />
          </div>
          <div className='field-column'>
            <TextField
              title={UVCAcceptInvitation.forms.title}
              type='text'
              className='input-primary size-full'
              placeholder={UVCAcceptInvitation.forms.title}
              autoFocus
              value={forms.title.value}
              name='title'
              onChange={(val, name) => onChange(val, name)}
              require='require'
              requiredStyle='required'
              errorName={forms.title.error && forms.title.messageError}
            />
            <TextField
              title={UVCAcceptInvitation.forms.employeeId}
              type='text'
              className='input-primary size-full'
              placeholder={UVCAcceptInvitation.forms.employeeId}
              autoFocus
              value={forms.employeeId.value}
              name='employeeId'
              onChange={(val, name) => onChange(val, name)}
            />
            <TextField
              title={UVCAcceptInvitation.forms.department}
              type='text'
              className='input-primary size-full'
              placeholder={UVCAcceptInvitation.forms.department}
              autoFocus
              value={forms.department.value}
              name='department'
              onChange={(val, name) => onChange(val, name)}
              require='require'
              requiredStyle='required'
              errorName={
                forms.department.error && forms.department.messageError
              }
            />
            <div className='field-phone'>
              <div>
                <span>{UVCAcceptInvitation.forms.phone}</span> <span />
              </div>
              <Input
                value={forms.phone.value}
                onChange={(e) => onChange(e.target.value, e.target.name)}
                name='phone'
                id='phone'
                inputComponent={TextMaskCustom}
              />
              <h6 className='error-title'>
                {forms.phone.error && forms.phone.messageError}
              </h6>
            </div>
          </div>
          <div className='field-row'>
            <TextField
              title={UVCAcceptInvitation.forms.password}
              type='password'
              className='input-primary size-full'
              placeholder={UVCAcceptInvitation.forms.password}
              autoFocus
              value={forms.password.value}
              name='password'
              onChange={(val, name) => onChange(val, name)}
              require='require'
              requiredStyle='required'
              errorName={forms.password.error && forms.password.messageError}
            />
            <TextField
              title={UVCAcceptInvitation.forms.confirmPassword}
              type='password'
              className='input-primary size-full'
              placeholder={UVCAcceptInvitation.forms.confirmPassword}
              autoFocus
              value={forms.confirmPassword.value}
              name='confirmPassword'
              onChange={(val, name) => onChange(val, name)}
              require='require'
              requiredStyle='required'
              errorName={
                forms.confirmPassword.error &&
                forms.confirmPassword.messageError
              }
            />
          </div>
          <div className='btn-container'>
            {isProcessing ? (
              <div>
                <Spinner open={isProcessing} fullscreen={false} />
              </div>
            ) : (
              <button type='button' onClick={() => onCreateAccount()}>
                {UVCAcceptInvitation.createAccountButton}
              </button>
            )}
          </div>
        </form>
      </main>
    </div>
  );
};

export default AcceptInvitation;
