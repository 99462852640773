import React from "react";
import { Grid, Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";

import { SoftwareSelection } from "components/uv-admin/details";
import { Modal } from "components/common";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";

import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";

import { UVCAdmin } from "helpers/constant";
import { report as reportAPI } from "services/api";

export default class EditSoftwareModal extends React.Component {
    state = {
        enclosure: null,
        softwareOptions: [...UVCAdmin.facilityDetails.enclosures.softwareSelectionOptions],
        isUVESoftwareProcessing: false,
        isOpenTitle: false,
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.enclosure !== this.props.enclosure && this.props.enclosure !== null) {
            this.setState({ enclosure: this.props.enclosure });
        }
        if(prevProps.isUserSessionExpire !== this.props.isUserSessionExpire && this.props.isUserSessionExpire === true) {
            this.handleClose(false);
        }
    };

    handleChange = (key, val) => {
        this.setState((prevState) => ({
            enclosure: {
                ...prevState.enclosure,
                [key]: val,
            },
        }));
    };

    transformVersion = (versionName) => {
        let possibleVersion = this.props.versions.filter(ver => ver.title === versionName)[0];
        return possibleVersion ? possibleVersion['key'] : "";
    }

    setVersion = (val) => {
        let versionName = this.props.versions.filter(ver => ver.key === val)[0]['title'];
        this.handleChange('next_version', versionName);
        this.handleChange('enclosure_software_package_id', val);
    }

    handleClose = (cb) => {
        this.props.onClose(cb);
        this.setState({ enclosure: null });
    };

    updateSoftware = () => {
        let d = new Date();
        this.setState({isUVESoftwareProcessing: true});
        let enclosure = {...this.state.enclosure, 'next_version_updated_at': d.toISOString()}
        return reportAPI.updateUVE(enclosure.id, enclosure)
            .then((res) => {
                if (res) {
                    this.setState({
                        isUVESoftwareProcessing: false,
                        enclosure: null,
                    })
                }
            })
            .then(() => this.props.onSubmit(false))
            .then(() => 
                // this.props.setToaster(toasterMessageType.SUCCESS, 'Enclosure updated successfully!')
                this.props.setStackToaster(
                    (
                        <ToasterMessageTemplate 
                            icon={iconSuccess}
                            title={'Enclosure updated successfully!'}
                        />
                    )
                )
            )
            .catch(err => {
                this.setState({
                    isUVESoftwareProcessing: false,
                })
                // this.props.setToaster(toasterMessageType.ERROR, 'Error when updating enclosure!')
                this.props.setStackToaster(
                    (
                        <ToasterMessageTemplate 
                            icon={iconError}
                            title={'Error when updating enclosure!'}
                        />
                    )
                )
            })
    }

    mouseEnter = (e) => {
        if (e.target.scrollWidth > e.target.clientWidth) {
            this.setState({ isOpenTitle: true });
        }
    }

    render = () => {
        const { open, versions } = this.props;
        const { enclosure, softwareOptions, isUVESoftwareProcessing, isOpenTitle } = this.state;

        return (
            <Modal
                open={open}
                title={"Edit Software Version"}
                content={
                    <form className="edit-version-modal">
                        <div className="require-title-container">
                            <h6 className="require-title required">Required</h6>
                        </div>
                        {enclosure && (
                            <React.Fragment>
                                <h5 className="current-version">
                                    <Tooltip title={enclosure.nickname} placement="top" disableHoverListener={!isOpenTitle}>
                                        <span className="enclosure-name" onMouseEnter={this.mouseEnter}>{enclosure.nickname}</span>
                                    </Tooltip>
                                    <span>{' Currently Running: '}</span>
                                    <span className="badge size-medium">{`Version ${enclosure.software_version || 'Unknown'}`}</span>
                                </h5>
                                <Grid 
                                    container spacing={2} 
                                    className="software-selection-grid-container">
                                    <Grid item xs={6}>
                                        <SoftwareSelection versions={versions} selectedVersion={enclosure && enclosure['next_version'] ? this.transformVersion(enclosure["next_version"]) : versions[0].key} setVersion={(val) => this.setVersion(val)} />
                                    </Grid>
                                    <Grid>
                                        <div className="empty-space" style={{ width: "100%" }}></div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems="stretch" className="software-selection-grid-container">
                                    {softwareOptions.map((opt) => (
                                        <Grid item xs={6} key={opt.key}>
                                            <FormControlLabel
                                                classes={{root: "checkbox-root"}} 
                                                control={
                                                <Checkbox checked={enclosure[opt.key] ? enclosure[opt.key] : false} 
                                                    disableRipple 
                                                    disableFocusRipple 
                                                    color="primary" 
                                                    classes={{ colorPrimary: "custom-checkbox" }} 
                                                    onChange={(e) => this.handleChange(opt.key, e.target.checked)} 
                                                    name={opt.desc} 
                                                />} 
                                                label={opt.desc} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </React.Fragment>
                        )}
                    </form>
                }
                onClose={(cb) => this.handleClose(cb)}
                action={
                    <div className="edit-version-modal">
                        <div className="action-container">
                            <button className="btn tertiary size-normal" onClick={() => this.handleClose(false)}>
                                Cancel
                            </button>
                            <button className="btn primary size-normal" onClick={() => this.updateSoftware()}>
                                {"Save"}
                            </button>
                        </div>
                    </div>
                }
                loading={isUVESoftwareProcessing}
            />
        );
    };
}
