import { browserStorage } from "services/api";
import CONFIG from "services/config";
import _ from 'lodash';

export const getTimeout = (expiry) => {
  const offsetTime = CONFIG.get_offset_timeout() *60*1000 // Set offset time from config file
  let currentDate = new Date().getTime();
  let expiryDate = new Date(expiry*1000).getTime();
  // Calcualate the expire time-range with offset
  return expiryDate - currentDate <= offsetTime 
          ? expiryDate - currentDate 
          : expiryDate - currentDate - offsetTime;
}

export const objectMapping = (primaryObj, secondaryObj, keyArr) => {
  let resObj = Object.keys(primaryObj)
    .filter(key => keyArr.includes(key))
    .reduce((obj, key) => {
        obj[key] = secondaryObj !== null ? 
                    primaryObj[key] || secondaryObj[key] : 
                    primaryObj[key];
        return obj
    }, {})
  return resObj
};

export const sq = (num) => {
  return num*num
}

export const pastDateCalculator = (pastTimeStamp) => {
  let times = {
    "min": 60,
    "hour": sq(60),
    "day": (sq(60) * 24),
    "week": (sq(60) * 24 * 7),
    "month": (sq(60) * 24 * 7 * 4),
    "year": (sq(60) * 24 * 7 * 4 * 12)
  };

  if(!pastTimeStamp) return "Enclosure not yet connected";
  let d = new Date(pastTimeStamp),
      diff = ((Date.now() - d.getTime()) / 1000);
      if (diff < times.min) {
        return "now";
      } else if (diff < times.hour) {
        return Math.floor(diff / times.min) + " min ago";
      } else if (diff < times.day) {
        return Math.floor(diff / times.hour) + " hours ago";
      } else if (diff < times.week) {
        return Math.floor(diff / times.day) + " days ago";
      } else if (diff < times.month) {
        return Math.floor(diff / times.week) + " weeks ago";
      } else if (diff < times.year) {
        return Math.floor(diff / times.month) + " months ago";
      } //else over a year
      return "Over a year ago";
}

export const formatRole = (role) => {
  let res = '';
  if (role.includes("_")) {
    res = role.replace("_", " ")
  }
  else {
    res = role;
  }
  return res;
}

export const isLogin = () => {
  const ciisToken = browserStorage.getData('header');
  let existFlag = false;
  _.keys(ciisToken).forEach(key => {
    if (ciisToken[key] && ciisToken[key] !== null && ciisToken[key] !== undefined) {
      existFlag = true;
    }
    return existFlag;
  })
  if (existFlag) {
      return true;
  }
  return false;
};

export const cleanObject = (obj) => {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  }

  return obj;
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const intervalWithLimit = (callback, delay, limit) => {
  let limitCount = limit ? limit : 99;
  let intervalId = window.setInterval(() => {
    if(limitCount === 0) {
      window.clearInterval(intervalId);
      return;
    }
    else {
      return callback()
    }
  }, delay) 
}

export const checkNumberOfDays = (number, min, max) => {
  const reg = new RegExp('^[1-9]\\d*$');
  let isNumber = false;
  if (number.match(reg) 
      && Number(number) >= min 
      && Number(number) <= max 
      && Number.isInteger(Number(number))) {
    isNumber = true;
  }
  return isNumber
}
