// Node modules
import React from "react";
import classNames from "classnames";
// Styles
import "./PulldownMenu.scss";

export default class PulldownMenu extends React.Component {
    // Render
    render = () => {
        const { open, children, className } = this.props;

        return <div className={open ? classNames("container", className) : "hidden"}>{children}</div>;
    };
}
