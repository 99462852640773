import React, { useEffect } from 'react';

// Router
import { Redirect } from 'react-router';
import { ToasterMessageTemplate } from 'components/common/Toaster/ToasterMessage';
import { useSnackbar } from 'notistack';
import iconError from 'images/status_red.svg';

import { TOAST_MESSAGE } from 'helpers/constant';

const InvalidPasswordResetToken = () => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar(
      <ToasterMessageTemplate
        icon={iconError}
        title={TOAST_MESSAGE.TOKEN_EXPIRED}
      />
    );
  }, [])

  return <Redirect to="/login" />;

}

export default InvalidPasswordResetToken;
