import React from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from "@material-ui/core";

import "./Table.scss";

export default class CustomizeTable extends React.Component {
    render = () => {
        const { headers, children, pagination } = this.props;

        return (
            <React.Fragment>
                <TableContainer className={pagination ? "table-root with-pagination" : "table-root"}>
                    <Table stickyHeader className="table-container">
                        <TableHead>
                            <TableRow>
                                {headers.map(
                                    (header) =>
                                        header.isShow && (
                                            <TableCell
                                                key={header.key}
                                                align="left"
                                                classes={{
                                                    root: "transparent-background",
                                                    stickyHeader: "table-header",
                                                }}
                                                style={{
                                                    minWidth: header.minWidth && header.minWidth,
                                                    maxWidth: header.maxWidth && header.maxWidth,
                                                    width: header.width ? header.width : 'auto'
                                                }}
                                                variant="head"
                                            >
                                                {header.title}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>{children}</TableBody>
                    </Table>
                </TableContainer>
                {pagination && pagination}
            </React.Fragment>
        );
    };
}
