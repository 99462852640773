import React from "react";
import _ from "lodash";
import "components/uv-admin/facilities/Facilities.scss";
import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";

import { UVCAdmin } from "helpers/constant";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";
import { cleanObject } from "helpers/functions"; 
import { ADD, MODIFY } from "components/uv-admin/facilities/Facilities";
import { Modal, TextField } from "components/common";
import { report as reportAPI } from "services/api";

export default class ModifyFacilityModal extends React.Component {
    initialState = {
        facilityModals: UVCAdmin.addFacilityModal,
        facilityInfo: null,
        isLoading: false,
        stopFlag: false,
    }
    state = {...this.initialState};

    componentDidMount = () => {
        this.state.facilityModals.forEach(field => {
            this.setState(prevState => ({
                facilityInfo: {
                    ...prevState.facilityInfo,
                    [field.key]: ''
                }
            }))
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(((prevProps.facility !== this.props.facility) || (this.props.id && !this.state.stopFlag))
            && this.props.facility 
            && this.props.action === MODIFY) {
                let initializeModal = this.state.facilityModals.map(field => {
                    let obj = {...field, isError: false, errorMessage: ''};
                    return obj
                })
                this.setState({facilityInfo: this.props.facility, stopFlag: true, facilityModals: initializeModal})
            
        }
        if(prevProps.open !== this.props.open && this.props.action === ADD) {
            let initializeModal = this.state.facilityModals.map(field => {
                let obj = {...field, isError: false, errorMessage: ''};
                return obj
            })
            this.setState({facilityModals: initializeModal})
        }
        if(prevProps.isUserSessionExpire !== this.props.isUserSessionExpire && this.props.isUserSessionExpire === true) {
            this.handleClose();
        }
    }

    formValidation = (excludedKey, stateData, stateModal) => {
        let data = this.state[stateData];
        let modal = this.state[stateModal]; 
        modal.forEach(el => {
            if (el.key !== excludedKey && el.isShow !== false) {
                switch(true) {
                    case !data:
                    case data && data[el.key] === "":
                        el.isError = true;
                        el.errorMessage = "Required Field";
                        this.setState({[stateModal]: modal});
                        break
                    default:
                        this.setState({[stateModal]: modal}); 
                        break;
                }
            }
        })
        return !Boolean(_.find(modal, ['isError', true]))
    }

    handleChange = (key, val) => {
        this.setState(
            (prevState) => ({
                facilityInfo: {
                    ...prevState.facilityInfo,
                    [key]: val,
                },
            })
        );
    };

    handleClose = (cb) => {
        let initializeModal = this.state.facilityModals.map(field => {
            let obj = {...field, isError: false, errorMessage: ''};
            return obj
        })
        initializeModal.forEach(field => {
            this.setState(prevState => ({
                facilityModals: initializeModal,
                facilityInfo: {
                    ...prevState.facilityInfo,
                    [field.key]: ''
                }
            }), () => this.props.onClose(cb))
        })
        if (this.state.stopFlag) {
            this.setState({stopFlag: false})
        } 
    }

    modifyFacility = () => {
        if(!this.formValidation('address_2', 'facilityInfo', 'facilityModals')) {
            return;
        }
        else {
            const id = this.state.facilityInfo.id;
            this.setState({isLoading: true})
            return reportAPI.updateFacility(id, this.state.facilityInfo)
                .then(res => {
                    if(res) {
                        this.setState({
                            isLoading: false,
                            facilityInfo: null,
                        });
                        this.props.onSubmit(false);
                        // this.props.setToaster(toasterMessageType.SUCCESS, 'Facility Updated!')
                        this.props.setStackToaster(
                            (
                                <ToasterMessageTemplate 
                                    icon={iconSuccess}
                                    title={'Facility Updated!'}
                                />
                            )
                        )
                    }
                })
                .catch(err => {
                    this.handleError(err)
                })
            }
    }

    addFacility = () => {
        if(!this.formValidation('address_2', 'facilityInfo', 'facilityModals')) {
            return;
        }
        else {
            this.setState({isLoading: true})
            let fiteredNewFacility = cleanObject(this.state.facilityInfo);
            
            return reportAPI.addFacility(fiteredNewFacility)
                .then(res => {
                    if(res) {
                        this.setState({
                            isLoading: false,
                            facilityInfo: null,
                        });
                        this.props.onSubmit(false);
                    }
                })
                .catch(err => {
                    this.handleError(err)
                })
        }
    }

    handleError = (err) => {
        if (err && err.response && err.response.status && err.response.status === 422) {
            let errors = err.response.data.errors;
            let errorModal = this.state.facilityModals.map(field => {
                let obj = _.indexOf(_.keys(errors), field.key) !== -1 
                        ? {...field, isError: true, errorMessage:  _.result(errors, field.key)} 
                        : {...field, isError: false, errorMessage: ''}
                return obj
            })
            this.setState({facilityModals: errorModal})
        }
        else {
            // this.props.setToaster(toasterMessageType.ERROR, 'Error when creating/updating Facility!')
            this.props.setStackToaster(
                (
                    <ToasterMessageTemplate 
                        icon={iconError}
                        title={'Error when creating/updating Facility!'}
                    />
                )
            )
        }
        this.setState({
            isLoading: false,
        })
    }

    render = () => {
        const { open, action } = this.props;
        const { facilityModals, facilityInfo, isLoading } = this.state;

        return (
            <Modal
                open={open}
                title={action === ADD ? UVCAdmin.addFacility : UVCAdmin.editFacility}
                content={
                    <form className="add-facility-modal">
                        {(action === ADD || action === MODIFY) && (
                            <React.Fragment>
                                <div className="require-container">
                                    <h6 className="title required">Required</h6>
                                </div>
                                <div className="text-field-container">
                                    {facilityModals.map(
                                        (field, index) =>
                                            field.key !== "id" && (
                                                <TextField
                                                    title={field.title}
                                                    id={UVCAdmin.grandHeader}
                                                    key={field.key}
                                                    requiredStyle={field.isRequired && "required"}
                                                    containerStyle={`grid-area-${index + 1}`}
                                                    errorName={field.isError && field.errorMessage}
                                                    value={
                                                        facilityInfo && facilityInfo[field.key] 
                                                        ? facilityInfo[field.key]
                                                        : ""}
                                                    onChange={(val) => this.handleChange(field.key, val)}
                                                    onFocus={(e) => this.setState((prevState) => ({
                                                        facilityModals: prevState.facilityModals.map((obj) => (
                                                            obj.key === field.key && obj.isError
                                                            ? Object.assign(obj, {isError: false, errorMessage: ''})
                                                            : obj
                                                        ))
                                                    }))}
                                                    autoFocus={action === ADD && field.key === 'name' ? 'autoFocus' : ''}
                                                />
                                            )
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </form>
                }
                action={
                    <div className="add-facility-modal">
                        <div className="action-container">
                            <button className="btn tertiary size-normal" onClick={() => this.handleClose(false)}>
                                Cancel
                            </button>
                            {action === MODIFY && (
                                <button className="btn primary size-normal" onClick={() => this.modifyFacility()}>
                                    {"Save"}
                                </button>
                            )}
                            {action === ADD && (
                                <button className="btn primary size-normal" onClick={() => this.addFacility()}>
                                    {"Add Facility"}
                                </button>
                            )}
                        </div>
                    </div>
                }
                onClose={(cb) => this.handleClose(cb)}
                loading={isLoading}
            />
        );
    };
}
