import React from "react";
import { Grid } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import singleEquipment from "images/graphic-equipment-single.svg";
import bulkEquipments from "images/graphic-equipment-multiple.svg";

import { ADD_EQUIPMENT, BULK_ADD_EQUIPMENT } from "components/uv-admin/details/equipments/Equipments";

export default class AddOptions extends React.Component {
    render = () => {
        const { openDialog } = this.props;

        return (
            <div className="choose-option">
                <Grid container justify="center" alignItems="center" alignContent="center" spacing={3}>
                    <Grid item xs={6}>
                        <div className="single-operator" onClick={() => openDialog(true, ADD_EQUIPMENT)}>
                            <div>
                                <img src={singleEquipment} alt="" width="65" />
                                <h6><strong>Single Equipment</strong></h6>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="bulk-operators" onClick={() => openDialog(true, BULK_ADD_EQUIPMENT)}>
                            <div>
                                <img src={bulkEquipments} alt="" width="117" />
                                <h6><strong>{"Bulk Upload"}</strong><br/><strong>{'Equipment'}</strong><br/>{'(CSV File)'}</h6>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    };
}
