/******************************************************************************\
 * File: App.jsx
 *
 * Author: CIIS
 *
 * Description: 
 *
 * Notes:
\******************************************************************************/
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import './App.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
// import Dashboard from './pages/auth/Dashboard';
// import Login from "pages/public/Login";
import PrivateRoute from 'components/common/Auth/PrivateRoute';
import PublicRoute from 'components/common/Auth/PublicRoute';
import Login from 'components/login';
import { AcceptInvitation, ResetPassword, InvalidPasswordResetToken } from 'components';

import { browserStorage } from 'services/api';
import MainLayout from 'layout/Main';
import { isLogin } from 'helpers/functions';

import { limitInterval } from 'helpers/constant';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
// import logo from './logo.svg';
//------------------------------------------------------------------------------

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1910,
    },
  },
});

class App extends React.Component {
  state = {
    ciisInfo: null,
    intervalLimit: 0,
  };

  componentDidMount = () => {
    if (!browserStorage.getData('savedFacility')) {
      browserStorage.saveData('savedFacility', {});
    }
    if (!browserStorage.getData('pbiKeys')) {
      browserStorage.saveData('pbiKeys', {});
    }
  };

  render() {
    const { ciisInfo, intervalLimit } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={4}
          hideIconVariant
          disableWindowBlurListener
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            root: 'toaster-container',
          }}
        >
          <Router>
            <Switch>
              <PublicRoute
                path='/accept_invitation'
                component={() => <AcceptInvitation />}
              />

              <PublicRoute
                path='/reset_password'
                component={() => <ResetPassword />}
              />

              <PublicRoute
                path='/invalid_password_reset_token'
                component={() => <InvalidPasswordResetToken />}
              />

              <PublicRoute
                path='/login'
                component={() => (
                  <Login
                    setUsername={(ciisUser) =>
                      this.setState({
                        ciisInfo: ciisUser,
                        intervalLimit: limitInterval,
                      })
                    }
                  />
                )}
              />

              <PrivateRoute
                path='/'
                authed={isLogin()}
                component={(props) => (
                  <MainLayout
                    history={props.history}
                    ciisInfo={ciisInfo && ciisInfo}
                    intervalLimit={intervalLimit && intervalLimit}
                  />
                )}
              />
            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

export default App;
