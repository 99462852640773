import React from 'react';
import _ from 'lodash';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';

import 'components/uv-admin/details/Detail.scss';
import iconCycle from 'images/icon-cycle-frequency.svg';
import iconHelp from 'images/icon-help.svg';
import iconAdd from 'images/icon-add.svg';
import iconSuccessBlue from 'images/icon-success.svg';
import iconSuccess from 'images/status_green.svg';
import iconError from 'images/status_red.svg';
import warningIcon from 'images/icon-warning-large-red.svg';

import { Modal, TextField, Switch } from 'components/common';
import {
  EQUIPMENT_ADD_OPTIONS,
  ADD_EQUIPMENT,
  EDIT_EQUIPMENT_TYPE,
  BULK_ADD_EQUIPMENT,
  DELETE_EQUIPMENT_TYPE,
  DELETE_EQUIPMENT_TYPE_WARNING,
  DELETE_EQUIPMENT
} from 'components/uv-admin/details/equipments/Equipments';
import { EquipmentAddOptions } from "components/uv-admin/details";

import { ToasterMessageTemplate } from 'components/common/Toaster/ToasterMessage';
import { UVCAdmin, toasterMessageType } from 'helpers/constant';

import { report as reportAPI } from 'services/api';
import CONFIG from 'services/config';
export default class EditEquipment extends React.Component {
  initialState = {
    stopFlag: false,
    newEquipmentType: '',
    isAddNewType: false,
    newTypeError: '',
    selectedTypeFrequency: '',
    isEditSelectedTypeFrequency: false,
    equipmentModal:
      UVCAdmin.facilityDetails.equipments.equipmentsModal.addEquipmentModal,
    isOverwriteDupId: true,
    uploadedFile: null,
    equipmentPayload: null,
    isEquipmentProcessing: false,
    isEquipmentTypeDeclared: false,
    department: this.props.department,
    hideAddNewType: false,
    isTempEquipmentExist: false,
    selectedEquipment: null,
    isEditEquipmentTypeName: false,
    newEquipmentTypeName: '',
    indicatorRef: null,
    cycleRef: null,
  };

  uploadFieldRef = React.createRef();
  cycleRef = React.createRef();
  indicatorRef = React.createRef();

  state = { ...this.initialState };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.isUserSessionExpire !== this.props.isUserSessionExpire &&
      this.props.isUserSessionExpire === true
    ) {
      this.handleClose(null, false, this.props.onClose);
    }
    switch (true) {
      case (prevProps.department !== this.props.department ||
        prevProps.type !== this.props.type ||
        !this.state.stopFlag) &&
        this.props.type &&
        this.props.department &&
        (this.props.action === EDIT_EQUIPMENT_TYPE ||
          this.props.action === DELETE_EQUIPMENT_TYPE):
        this.setState(
          {
            equipmentPayload: this.props.type,
            department: this.props.department,
            stopFlag: true,
            isEditEquipmentTypeName:
              this.props.department['equipment_types'].filter(
                (field) => field.id === this.props.type['equipment_type_id']
              )[0].default === false
                ? true
                : false,
          },
          () => {
            if (this.state.isEditEquipmentTypeName) {
              this.setState({
                newEquipmentTypeName: this.props.type['equipment_type_name'],
              });
            }
            this.clearModalError();
          }
        );
        break;
      case (prevProps.department !== this.props.department ||
        !this.state.stopFlag) &&
        this.props.department &&
        (this.props.action === ADD_EQUIPMENT ||
          this.props.action === BULK_ADD_EQUIPMENT):
        this.state.equipmentModal.forEach((field) => {
          this.setState((prevState) => ({
            equipmentPayload: {
              ...prevState.equipmentPayload,
              [field.key]: this.renderInitialValue(field.key),
            },
          }));
        });
        let equipmentList = this.props.department['equipment_types'].filter(
          (el) => el.id !== 'new'
        );
        this.setState(
          {
            department: {
              ...this.props.department,
              equipment_types: equipmentList,
            },
            stopFlag: true,
          },
          () => this.clearModalError()
        );
        break;
      case (prevProps.department !== this.props.department ||
        prevProps.equipment !== this.props.equipment ||
        !this.state.stopFlag) &&
        this.props.department &&
        this.props.equipment &&
        this.props.action === DELETE_EQUIPMENT:
        this.setState({
          selectedEquipment: this.props.equipment,
          department: this.props.department,
          stopFlag: true,
        });
        break;
      default:
        return;
    }
  };

  renderInitialValue = (key) => {
    switch (key) {
      case 'deactivate_duplicate_tags':
        return true;
      default:
        return '';
    }
  };

  onChange = (key, value) => {
    this.setState((prevState) => ({
      equipmentPayload: {
        ...prevState.equipmentPayload,
        [key]: value,
      },
    }));
  };

  setSelectedType = (id) => {
    if (id !== 'new') {
      let protocol = this.state.department.protocols.filter(
        (prot) => prot.equipment_type_id === id
      );
      this.onChange('equipment_type_id', id);
      this.onChange(
        'frequency',
        protocol.length > 0 ? protocol[0].frequency : ''
      );
      this.onChange('tag_id', '');
      this.onChange('equipment_id', '');
      let equipmentList = this.state.department['equipment_types'].filter(
        (el) => el.id !== 'new'
      );

      this.setState({
        isEditSelectedTypeFrequency: false,
        isEquipmentTypeDeclared: protocol.length > 0 ? true : false,
        hideAddNewType: false,
        isAddNewType: false,
        newEquipmentType: '',
        newTypeError: '',
        department: {
          ...this.state.department,
          equipment_types: equipmentList,
        },
        isTempEquipmentExist: false,
      });
      this.clearModalError();
    }
  };

  handleUpload = (e) => this.setState({ uploadedFile: e.target.files[0] });

  onInputClick = (event) => event.target.value = '';

  handleError = (err) => {
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 422
    ) {
      let errors = err.response.data.errors;
      if (errors.tag_id) {
        this.indicatorRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      let errorModal = this.state.equipmentModal.map((field) => {
        if (field === 'tag_id') {
          this.indicatorRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        if (field === 'frequency') {
          this.cycleRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        let obj =
          _.indexOf(_.keys(errors), field.key) !== -1
            ? {
                ...field,
                isError: true,
                errorMessage: _.result(errors, field.key),
              }
            : { ...field, isError: false, errorMessage: '' };
        return obj;
      });
      switch (true) {
        case this.state.isTempEquipmentExist &&
          errors.hasOwnProperty('equipment_type.name'):
          if (errors['equipment_type.name'][0] !== null) {
            this.setState(
              {
                isAddNewType: true,
                hideAddNewType: false,
                department: {
                  ...this.state.department,
                  equipment_types: this.state.department[
                    'equipment_types'
                  ].filter((el) => el.id !== 'new'),
                },
                newTypeError: errors['equipment_type.name'],
                equipmentModal: errorModal,
                equipmentPayload: {
                  ...this.state.equipmentPayload,
                  equipment_type_id: '',
                },
              }
            );
          } else {
            this.setState({
              equipmentModal: errorModal,
            });
          }

          break;
        case this.state.isEditEquipmentTypeName:
          this.setState({
            newTypeError: errors.base,
            isEquipmentProcessing: false,
            equipmentModal: errorModal,
          });
          break;
        default:
          this.setState({ equipmentModal: errorModal });
          break;
      }
    } else {
      // this.props.setToaster(toasterMessageType.ERROR, 'Error when updating Department!')
      this.props.setStackToaster(
        <ToasterMessageTemplate
          icon={iconError}
          title={'Error when updating Department!'}
        />
      );
    }
    this.setState({
      isEquipmentProcessing: false,
    });
  };

  handleClose = (id, val, callback) => {
    this.setState(this.initialState, () => {
      let initializeModal = this.clearModalError();
      let initialEquipmentPayload = {};
      initializeModal.forEach((field) => {
        initialEquipmentPayload[field.key] = this.renderInitialValue(field.key);
      });
      this.setState(
        {
          equipmentPayload: initialEquipmentPayload,
          stopFlag: false,
        },
        () => (id ? callback(id, val) : callback(val))
      );
    });
  };

  formValidation = (stateData, stateModal) => {
    let newNameFlag = true;
    let data =
      this.props.action === BULK_ADD_EQUIPMENT
        ? _.omit(this.state[stateData], ['tag_id', 'equipment_id'])
        : this.state[stateData];
    let modal = this.state[stateModal];
    modal.forEach((el, index) => {
      switch (true) {
        case !data:
        case data && el.isRequired === true && data[el.key] === '':
          el.isError = true;

          if (el.key === 'tag_id') {
            this.indicatorRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
          if (el.key === 'frequency') {
            this.cycleRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }

          el.errorMessage = 'Required Field';
          break;
        default:
          break;
      }
      this.setState({
        [stateModal]: [
          ...this.state[stateModal].slice(0, index),
          Object.assign({}, this.state[stateModal][index], el),
          ...this.state[stateModal].slice(index + 1),
        ],
      });
    });
    if (
      this.state.isEditEquipmentTypeName &&
      this.state.newEquipmentTypeName === ''
    ) {
      this.setState(
        {
          newTypeError: 'Required Field',
          isEquipmentProcessing: false,
        },
        () => {
          newNameFlag = false;
          return newNameFlag && !Boolean(_.find(modal, ['isError', true]));
        }
      );
    } else {
      return !Boolean(_.find(modal, ['isError', true]));
    }
  };

  addTemporaryEquipmentType = () => {
    if (this.state.newEquipmentType === '') {
      this.setState({ newTypeError: 'Required Field' });
      return;
    } else if (
      Boolean(
        _.find(this.state.department['equipment_types'], [
          'name',
          this.state.newEquipmentType,
        ])
      )
    ) {
      this.setState({ newTypeError: 'This name has already been taken' });
      return;
    } else {
      let department = this.state.department;
      let tempEquipmentType = {
        id: 'new',
        name: this.state.newEquipmentType,
        default: false,
      };
      department['equipment_types'].push(tempEquipmentType);
      this.setState({
        department: department,
        isAddNewType: false,
        equipmentPayload: {
          ...this.state.equipmentPayload,
          equipment_type_id: 'new',
          frequency: '',
          tag_id: '',
          equipment_id: '',
        },
        hideAddNewType: true,
        isTempEquipmentExist: true,
        isEditSelectedTypeFrequency: true,
      });
    }
  };

  addNewEquipment = (department) => {
    if (!this.formValidation('equipmentPayload', 'equipmentModal')) {
      for (let item of this.state.equipmentModal) {
        if (item.key === 'frequency' && item.isError) {
          this.cycleRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        if (item.key === 'tag_id' && item.isError) {
          this.indicatorRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
      return;
    } else {
      let { equipmentPayload, isEquipmentTypeDeclared, isTempEquipmentExist } =
        this.state;
      this.setState({ isEquipmentProcessing: true });
      let protocol = new FormData();
      switch (true) {
        case !isTempEquipmentExist:
          if (isEquipmentTypeDeclared) {
            let protocolId =
              department.protocols.length !== 0
                ? department.protocols.filter(
                    (obj) =>
                      obj['equipment_type_id'] ===
                      equipmentPayload['equipment_type_id']
                  )[0].id
                : '';
            // filter(key => key === 'tag_id' || key === 'equipment_type_id')
            _.keys(equipmentPayload).forEach((key) => {
              if (equipmentPayload[key] !== '') {
                protocol.append(`protocol[${key}]`, equipmentPayload[key]);
              }
            });
            return reportAPI
              .addNewEquipmentByDepartmentIdWithExistProtocolId(
                this.props.department.id,
                protocolId,
                protocol
              )
              .then((res) => {
                if (res) {
                  this.setState(
                    {
                      isEquipmentProcessing: false,
                    },
                    () => {
                      // this.props.setToaster(toasterMessageType.SUCCESS, '1 item added to department.');
                      this.props.setStackToaster(
                        <ToasterMessageTemplate
                          icon={iconSuccess}
                          title={'1 item added to department.'}
                        />
                      );
                      this.handleClose(
                        this.props.department,
                        false,
                        this.props.onSubmit
                      );
                    }
                  );
                }
              })
              .catch((err) => this.handleError(err));
          } else {
            _.keys(equipmentPayload).forEach((key) => {
              if (equipmentPayload[key] !== '') {
                protocol.append(`protocol[${key}]`, equipmentPayload[key]);
              }
            });
            return reportAPI
              .addNewEquipmentByDepartmentId(this.props.department.id, protocol)
              .then((res) => {
                if (res) {
                  this.setState(
                    {
                      isEquipmentProcessing: false,
                    },
                    () => {
                      this.props.setStackToaster(
                        <ToasterMessageTemplate
                          icon={iconSuccess}
                          title={'1 item added to department.'}
                        />
                      );
                      this.handleClose(
                        this.props.department,
                        false,
                        this.props.onSubmit
                      );
                    }
                  );
                }
              })
              .catch((err) => this.handleError(err));
          }
        case isTempEquipmentExist:
          _.keys(equipmentPayload)
            .filter(
              (key) =>
                key !== 'equipment_type_id' && equipmentPayload[key] !== ''
            )
            .forEach((key) => {
              protocol.append(`protocol[${key}]`, equipmentPayload[key]);
            });
          protocol.append(
            'protocol[equipment_type_attributes][name]',
            this.state.newEquipmentType
          );
          protocol.append(
            'protocol[equipment_type_attributes][department_id]',
            this.props.department.id
          );
          return reportAPI
            .addNewEquipmentByDepartmentId(this.props.department.id, protocol)
            .then((res) => {
              if (res) {
                this.setState(
                  {
                    isEquipmentProcessing: false,
                  },
                  () => {
                    // this.props.setToaster(toasterMessageType.SUCCESS, '1 item added to department.');
                    this.props.setStackToaster(
                      <ToasterMessageTemplate
                        icon={iconSuccess}
                        title={'1 item added to department.'}
                      />
                    );
                    this.handleClose(
                      this.props.department,
                      false,
                      this.props.onSubmit
                    );
                  }
                );
              }
            })
            .catch((err) => this.handleError(err));
        default:
          return;
      }
    }
  };

  bulkUploadEquipment = (department) => {
    let {
      equipmentPayload,
      uploadedFile,
      isOverwriteDupId,
      isTempEquipmentExist,
    } = this.state;

    if (!this.formValidation('equipmentPayload', 'equipmentModal')) {
      return;
    } else {
      this.setState({ isEquipmentProcessing: true });
      switch (true) {
        case !isTempEquipmentExist:
          let protocol = department.protocols.filter(
            (obj) =>
              obj['equipment_type_id'] === equipmentPayload['equipment_type_id']
          )[0];
          let data = new FormData();
          if (protocol) {
            data.append('protocol[equipment_items_upload]', uploadedFile);
            data.append('protocol[equipment_type_id]', protocol.id);
            data.append(
              'protocol[deactivate_duplicate_tags]',
              isOverwriteDupId
            );
            return reportAPI
              .addNewEquipmentByDepartmentIdWithExistProtocolId(
                department.id,
                protocol.id,
                data
              )
              .then((res) => {
                if (res) {
                  this.setState(
                    {
                      isEquipmentProcessing: false,
                    },
                    () => {
                      // this.props.setToaster(toasterMessageType.SUCCESS, `${res.length} items added to department`);
                      this.props.setStackToaster(
                        <ToasterMessageTemplate
                          icon={iconSuccess}
                          title={`${res.length} items added to department`}
                        />
                      );
                      this.handleClose(
                        this.props.department,
                        false,
                        this.props.onSubmit
                      );
                    }
                  );
                }
              })
              .catch((err) => {
                if (
                  err &&
                  err.response &&
                  err.response.status &&
                  err.response.status === 422
                ) {
                  let errors = err.response.data.errors;
                  // this.props.setToaster(toasterMessageType.ERROR, `${errors.equipment_items_upload}`)
                  this.props.setStackToaster(
                    <ToasterMessageTemplate
                      icon={iconError}
                      title={errors.equipment_items_upload}
                    />
                  );
                } else {
                  // this.props.setToaster(toasterMessageType.ERROR, 'A server error occurred. We have been notified of the problem.')
                  this.props.setStackToaster(
                    <ToasterMessageTemplate
                      icon={iconError}
                      title={
                        'Something may be wrong with your CSV file, please review the contents and format and try again.'
                      }
                    />
                  );
                }
                this.setState({
                  isEquipmentProcessing: false,
                });
              });
          } else {
            data.append('protocol[frequency]', equipmentPayload['frequency']);
            data.append(
              'protocol[equipment_type_id]',
              equipmentPayload['equipment_type_id']
            );
            data.append('protocol[equipment_items_upload]', uploadedFile);
            data.append(
              'protocol[deactivate_duplicate_tags]',
              isOverwriteDupId
            );
            return reportAPI
              .addNewEquipmentByDepartmentId(department.id, data)
              .then((res) => {
                if (res) {
                  this.setState(
                    {
                      isEquipmentProcessing: false,
                    },
                    () => {
                      // this.props.setToaster(toasterMessageType.SUCCESS, `${res.length} items added to department`);
                      this.props.setStackToaster(
                        <ToasterMessageTemplate
                          icon={iconSuccess}
                          title={`${res.length} items added to department`}
                        />
                      );
                      this.handleClose(
                        this.props.department,
                        false,
                        this.props.onSubmit
                      );
                    }
                  );
                }
              })
              .catch((err) => {
                if (
                  err &&
                  err.response &&
                  err.response.status &&
                  err.response.status === 422
                ) {
                  let errors = err.response.data.errors;
                  this.props.setToaster(
                    toasterMessageType.ERROR,
                    `${errors.equipment_items_upload}`
                  );
                  this.props.setStackToaster(
                    <ToasterMessageTemplate
                      icon={iconError}
                      title={errors.equipment_items_upload}
                    />
                  );
                } else {
                  // this.props.setToaster(toasterMessageType.ERROR, 'A server error occurred. We have been notified of the problem.')
                  this.props.setStackToaster(
                    <ToasterMessageTemplate
                      icon={iconError}
                      title={
                        'Something may be wrong with your CSV file, please review the contents and format and try again.'
                      }
                    />
                  );
                }
                this.setState({
                  isEquipmentProcessing: false,
                });
              });
          }
        // This case will be fixed in next sprint --> missing case in UI
        case isTempEquipmentExist:
          let dataWithNewType = new FormData();
          _.keys(equipmentPayload)
            .filter(
              (key) =>
                key !== 'equipment_type_id' && equipmentPayload[key] !== ''
            )
            .forEach((key) => {
              dataWithNewType.append(`protocol[${key}]`, equipmentPayload[key]);
            });
          dataWithNewType.append(
            'protocol[equipment_items_upload]',
            uploadedFile
          );
          dataWithNewType.append(
            'protocol[deactivate_duplicate_tags]',
            isOverwriteDupId
          );
          dataWithNewType.append(
            'protocol[equipment_type_attributes][name]',
            this.state.newEquipmentType
          );
          dataWithNewType.append(
            'protocol[equipment_type_attributes][department_id]',
            this.props.department.id
          );
          return reportAPI
            .addNewEquipmentByDepartmentId(department.id, dataWithNewType)
            .then((res) => {
              if (res) {
                this.setState(
                  {
                    isEquipmentProcessing: false,
                  },
                  () => {
                    // this.props.setToaster(toasterMessageType.SUCCESS, `${res.length} items added to department`);
                    this.props.setStackToaster(
                      <ToasterMessageTemplate
                        icon={iconSuccess}
                        title={`${res.length} items added to department`}
                      />
                    );
                    this.handleClose(
                      this.props.department,
                      false,
                      this.props.onSubmit
                    );
                  }
                );
              }
            })
            .catch((err) => {
              if (
                err &&
                err.response &&
                err.response.status &&
                err.response.status === 422
              ) {
                let errors = err.response.data.errors;
                // errors.hasOwnProperty('equipment_items_upload') && this.props.setToaster(toasterMessageType.ERROR, `${errors['equipment_items_upload']}`)
                errors.hasOwnProperty('equipment_items_upload') &&
                  this.props.setStackToaster(
                    <ToasterMessageTemplate
                      icon={iconError}
                      title={errors['equipment_items_upload']}
                    />
                  );
                errors.hasOwnProperty('equipment_type.name') &&
                  this.handleError(err);
              } else {
                // this.props.setToaster(toasterMessageType.ERROR, 'A server error occurred. We have been notified of the problem.')
                this.props.setStackToaster(
                  <ToasterMessageTemplate
                    icon={iconError}
                    title={
                      'Something may be wrong with your CSV file, please review the contents and format and try again.'
                    }
                  />
                );
              }
              this.setState({
                isEquipmentProcessing: false,
              });
            });
        default:
          return;
      }
    }
  };

  editEquipmentType = (department) => {
    let { equipmentPayload, newEquipmentTypeName, isEditEquipmentTypeName } =
      this.state;
    if (!this.formValidation('equipmentPayload', 'equipmentModal')) {
      return;
    } else {
      this.setState({ isEquipmentProcessing: true });
      let protocolId =
        department.protocols.length !== 0
          ? department.protocols.filter(
              (obj) =>
                obj['equipment_type_id'] ===
                equipmentPayload['equipment_type_id']
            )[0].id
          : '';
      let data = isEditEquipmentTypeName
        ? {
            protocol: {
              ...equipmentPayload,
              equipment_type_name: newEquipmentTypeName,
            },
          }
        : { protocol: { ...equipmentPayload } };
      return reportAPI
        .addNewEquipmentByDepartmentIdWithExistProtocolId(
          this.props.department.id,
          protocolId,
          data
        )
        .then((res) => {
          if (res) {
            this.setState(
              {
                isEquipmentProcessing: false,
              },
              () => {
                // this.props.setToaster(toasterMessageType.SUCCESS, 'Protocol updated successfully.');
                this.props.setStackToaster(
                  <ToasterMessageTemplate
                    icon={iconSuccess}
                    title={'Protocol updated successfully.'}
                  />
                );
                this.handleClose(
                  this.props.department,
                  false,
                  this.props.onSubmit
                );
              }
            );
          }
        })
        .catch((err) => this.handleError(err));
    }
  };

  removeEquipmentType = (equipmentType, department) => {
    this.setState({ isEquipmentProcessing: true });
    return reportAPI
      .removeEquipmentType(department.id, equipmentType['equipment_type_id'])
      .then((res) => {
        if (res) {
          this.setState(
            {
              isEquipmentProcessing: false,
            },
            () => {
              // this.props.setToaster(toasterMessageType.SUCCESS, 'Equipment Type deactivated.');
              this.props.setStackToaster(
                <ToasterMessageTemplate
                  icon={iconSuccess}
                  title={'Equipment Type deactivated.'}
                />
              );
              this.handleClose(
                this.props.department,
                false,
                this.props.onSubmit
              );
            }
          );
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 422
        ) {
          // let errors = err.response.data.errors;
          // this.props.setToaster(toasterMessageType.ERROR, `Equipment Type ${errors.equipment_type}`)
          this.props.setStackToaster(
            <ToasterMessageTemplate
              icon={iconError}
              title={'An error was encountered'}
            />
          );
        } else {
          // this.props.setToaster(toasterMessageType.ERROR, 'An occur error')
          this.props.setStackToaster(
            <ToasterMessageTemplate
              icon={iconError}
              title={'An error was encountered'}
            />
          );
        }
        this.setState({
          isEquipmentProcessing: false,
        });
      });
  };

  removeEquipment = (equipment, department) => {
    this.setState({ isEquipmentProcessing: true });
    return reportAPI
      .removeEquipment(department.id, equipment.id)
      .then((res) => {
        if (res) {
          this.setState(
            {
              isEquipmentProcessing: false,
              equipmentPayload: null,
            },
            () => {
              // this.props.setToaster(toasterMessageType.SUCCESS, 'Protocol updated successfully.');
              this.props.setStackToaster(
                <ToasterMessageTemplate
                  icon={iconSuccess}
                  title={'Protocol updated successfully.'}
                />
              );
              this.handleClose(
                this.props.department,
                false,
                this.props.onSubmit
              );
            }
          );
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 422
        ) {
          // let errors = err.response.data.errors;
          // this.props.setToaster(toasterMessageType.ERROR, `Equipment ${errors.equipment_type}`)
          this.props.setStackToaster(
            <ToasterMessageTemplate
              icon={iconError}
              title={'An error was encountered'}
            />
          );
        } else {
          // this.props.setToaster(toasterMessageType.ERROR, 'An occur error')
          this.props.setStackToaster(
            <ToasterMessageTemplate
              icon={iconError}
              title={'An error was encountered'}
            />
          );
        }
        this.setState({
          isEquipmentProcessing: false,
        });
      });
  };

  clearModalError = () => {
    let initializeModal = this.state.equipmentModal.map((field) => {
      let obj = { ...field, isError: false, errorMessage: '' };
      return obj;
    });
    this.setState({ equipmentModal: initializeModal });
    return initializeModal;
  };

  titleRender = (action) => {
    switch (action) {
      case ADD_EQUIPMENT:
      case EQUIPMENT_ADD_OPTIONS:
        return 'Add Equipment';
      case EDIT_EQUIPMENT_TYPE:
        return 'Edit Equipment Type';
      case BULK_ADD_EQUIPMENT:
        return 'Bulk Upload Equipment';
      case DELETE_EQUIPMENT_TYPE:
        return 'Remove Equipment Type';
      case DELETE_EQUIPMENT:
        return 'Remove Equipment';
      case DELETE_EQUIPMENT_TYPE_WARNING:
        return 'Remove Equipment Type';
      default:
        return '';
    }
  };

  isCreate = (action) => [ADD_EQUIPMENT, BULK_ADD_EQUIPMENT].includes(action);

  mouseEnter = (e, id) => {
    const types = this.state.department['equipment_types'].map((value, indx) => {
      if (value.id === id && e.target.scrollWidth > e.target.clientWidth) {
        value.isOpenName = true;
      } else {
        value.isOpenName = false;
      }
      return value;
    });
    this.setState({ department: { ...this.state.department, 'equipment_types': types } });
  }

  render = () => {
    const { open, action, onClose, onOpen } = this.props;
    const {
      newEquipmentType,
      isAddNewType,
      newTypeError,
      equipmentModal,
      isEditSelectedTypeFrequency,
      isOverwriteDupId,
      uploadedFile,
      equipmentPayload,
      isEquipmentProcessing,
      department,
      hideAddNewType,
      selectedEquipment,
      isEditEquipmentTypeName,
      newEquipmentTypeName,
    } = this.state;
    const arrActions = [DELETE_EQUIPMENT_TYPE, DELETE_EQUIPMENT, DELETE_EQUIPMENT_TYPE_WARNING, EQUIPMENT_ADD_OPTIONS];
    return (
      <Modal
        open={open}
        title={this.titleRender(action)}
        content={
          <div className='edit-equipment-modal'>
            {department &&
              equipmentPayload &&
              !arrActions.includes(action)  && (
                <React.Fragment>
                  <div className='require-title-container'>
                    <h6 className='require-title required'>Required</h6>
                  </div>
                  {action === EDIT_EQUIPMENT_TYPE && (
                    <div className='equipment-type-container'>
                      <Typography variant='h5' className='required'>
                        Equipment Type
                      </Typography>
                      {!isEditEquipmentTypeName ? (
                        <Typography variant='h6'>
                          {equipmentPayload['equipment_type_name']}
                        </Typography>
                      ) : (
                        <div className='add-equipment-type-container'>
                          <div className='add-type-inner-container'>
                            <TextField
                              title=''
                              value={newEquipmentTypeName}
                              className='add-type-textfield full-width'
                              errorName={newTypeError !== '' && newTypeError}
                              onChange={(val) =>
                                this.setState({ newEquipmentTypeName: val })
                              }
                              onFocus={() =>
                                this.setState({ newTypeError: '' })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {(action === ADD_EQUIPMENT ||
                    action === BULK_ADD_EQUIPMENT) && (
                    <div className='equipment-type-container'>
                      <Typography variant='h5' className='required'>
                        Select an Equipment Type
                      </Typography>
                      {department && (
                        <Grid
                          className='equipment-type-list'
                          container
                          spacing={2}
                          justify='flex-start'
                        >
                          {department['equipment_types'].map((item) => (
                            <Grid item xs={3} key={item.id}>
                              <button
                                className={
                                  item.id ===
                                  equipmentPayload['equipment_type_id']
                                    ? 'btn primary size-small equipment-type-item active'
                                    : 'btn primary size-small equipment-type-item'
                                }
                                onClick={() => this.setSelectedType(item.id)}
                              >
                                <Tooltip title={item.name} placement="top" disableHoverListener={!item.isOpenName}>
                                  <h4 onMouseEnter={(e) => this.mouseEnter(e, item.id)}>{item.name}</h4>
                                </Tooltip>
                              </button>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      {!hideAddNewType && (
                        <div className='add-equipment-type-container'>
                          {isAddNewType ? (
                            <div className='add-type-inner-container'>
                              <TextField
                                title=''
                                id={'add-equipment-type'}
                                value={newEquipmentType}
                                errorName={newTypeError !== '' && newTypeError}
                                className='add-type-textfield'
                                onChange={(val) =>
                                  this.setState({ newEquipmentType: val })
                                }
                                onFocus={() =>
                                  this.setState({ newTypeError: '' })
                                }
                              />
                              <div className='cta-type'>
                                <button
                                  className='btn primary size-tiny transparent'
                                  onClick={() =>
                                    this.setState({
                                      isAddNewType: false,
                                      newEquipmentType: '',
                                      newTypeError: '',
                                    })
                                  }
                                >
                                  <Close className='cta-icon' />
                                </button>
                                <button
                                  className='btn primary size-tiny transparent'
                                  onClick={() =>
                                    this.addTemporaryEquipmentType()
                                  }
                                >
                                  <Check className='cta-icon' />
                                </button>
                              </div>
                            </div>
                          ) : (
                            <button
                              className='btn primary size-small transparent'
                              onClick={() =>
                                this.setState({ isAddNewType: true }, () =>
                                  this.clearModalError()
                                )
                              }
                            >
                              <span>
                                <img src={iconAdd} alt='' width='21' />
                              </span>
                              <span>
                                <Typography variant='h6'>
                                  Add New Type
                                </Typography>
                              </span>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {action !== DELETE_EQUIPMENT && action !== EQUIPMENT_ADD_OPTIONS && (
                    <div className='cycle-freq-container'>
                      <div ref={this.cycleRef} />
                      <Typography variant='h5' className='required'>
                        Cycle Frequency
                      </Typography>
                      <div className='cycle-freq-inner'>
                        <img src={iconCycle} width='60' alt='' />
                        {equipmentPayload['frequency'] !== '' &&
                        !isEditSelectedTypeFrequency &&
                        action !== EDIT_EQUIPMENT_TYPE ? (
                          <div className='cycle-freq-textfield-container'>
                            <Typography variant='h5' className='days black'>
                              {`Currently set to:`}
                              <strong>{`${equipmentPayload['frequency']} Day(s)`}</strong>
                              {/* <span>
                                                            <button 
                                                                className="btn primary size-tiny edit"
                                                                onClick={() => this.setState({isEditSelectedTypeFrequency: true})}
                                                                disabled>
                                                                <img src={iconEdit} alt="" witdh="16" />
                                                            </button>
                                                        </span> */}
                            </Typography>
                          </div>
                        ) : (
                          <div className='cycle-freq-textfield-container'>
                            {this.state.equipmentModal
                              .filter((field) => field.key === 'frequency')
                              .map((field) => (
                                <React.Fragment key={field.key}>
                                  <TextField
                                    title=''
                                    type='number'
                                    id={'add-equipment-type'}
                                    value={equipmentPayload[field.key]}
                                    errorName={
                                      field.isError && field.errorMessage
                                    }
                                    className='cycle-freq-textfield'
                                    onChange={(val) => {
                                      this.onChange('frequency', val);
                                      this.setState({
                                        isEditSelectedTypeFrequency: true,
                                      });
                                    }}
                                    onFocus={(e) =>
                                      this.setState((prevState) => ({
                                        equipmentModal:
                                          prevState.equipmentModal.map((obj) =>
                                            obj.key === field.key && obj.isError
                                              ? Object.assign(obj, {
                                                  isError: false,
                                                  errorMessage: '',
                                                })
                                              : obj
                                          ),
                                      }))
                                    }
                                  />
                                  <Typography
                                    variant='h5'
                                    className={
                                      field.isError ? 'days with-error' : 'days'
                                    }
                                  >
                                    {'Day(s)'}
                                  </Typography>
                                </React.Fragment>
                              ))}
                          </div>
                        )}
                        <div className='free-space'></div>
                      </div>
                      <Typography variant='h5' className='cycle-freq-note'>
                        <span>Please note: </span>
                        <span>
                          {UVCAdmin.facilityDetails.equipments.addEquipmentNote}
                        </span>
                      </Typography>
                    </div>
                  )}
                  {action === BULK_ADD_EQUIPMENT && (
                    <React.Fragment>
                      <div className='bulk-uploader-container'>
                        <Typography variant='h5'>
                          Please download our CSV template and add your
                          equipment to it.
                        </Typography>
                        <a
                          className='btn primary size-tiny download-template'
                          href={`${
                            CONFIG.get_ciis_service_url().split('/')[0]
                          }//${
                            CONFIG.get_ciis_service_url().split('/')[2]
                          }/equipment_items_csv_template.csv`}
                        >
                          Download Template
                        </a>
                        <div className='bulk-upload-note-container'>
                          <p>
                            All Indicator Tag IDs should be listed in the first
                            column of the spreadsheet, and are 12 characters
                            long composed of A-F & 0-9.
                          </p>
                          <p>
                            You may also enter an optional Equipment ID in the
                            second column for an existing ID your hospital uses
                            to identify that piece of equipment.
                          </p>
                        </div>
                        <Typography variant='h5' className='required'>
                          Select your CSV file to upload
                        </Typography>
                        <div className='upload-file'>
                          <label htmlFor='equipment-bulk-upload-file'>
                            <span
                              className={
                                !equipmentPayload['equipment_type_id']
                                  ? 'btn primary size-normal disabled'
                                  : 'btn primary size-normal'
                              }
                            >
                              Browse
                            </span>
                            <input
                              ref={this.uploadFieldRef}
                              accept='.csv'
                              id='equipment-bulk-upload-file'
                              type='file'
                              disabled={!equipmentPayload['equipment_type_id']}
                            onChange={this.handleUpload}
                            onClick={this.onInputClick}
                            />
                          </label>
                          {uploadedFile !== null && (
                            <Typography variant='h5' className='path-name'>
                              {uploadedFile.name}
                              <span>
                                <img src={iconSuccessBlue} alt='' width='15' />
                              </span>
                            </Typography>
                          )}
                        </div>
                      </div>
                      <div className='help-container bulk-help'>
                        <Switch
                          title={"Overwrite duplicate indicator tag ID's"}
                          checked={isOverwriteDupId}
                          setValue={(val) =>
                            this.setState({ isOverwriteDupId: val })
                          }
                        />
                        <Tooltip
                          title={
                            UVCAdmin.facilityDetails.equipments
                              .overwriteTagIDHelp
                          }
                          placement='top'
                          classes={{ tooltipPlacementTop: 'tooltip' }}
                        >
                          <img src={iconHelp} alt='' width='16' />
                        </Tooltip>
                        <div className='free-space'></div>
                      </div>
                    </React.Fragment>
                  )}
                  {equipmentPayload['equipment_type_id'] !== '' &&
                    action === ADD_EQUIPMENT && (
                      <div className='new-equipment-info'>
                        <Grid
                          container
                          spacing={2}
                          justify='center'
                          alignContent='center'
                        >
                          {equipmentModal.map((field) =>
                            field.key !== 'deactivate_duplicate_tags' ? (
                              <React.Fragment key={field.key}>
                                {field.isShow && (
                                  <Grid item xs={field['grid-xs']}>
                                    <div ref={this.indicatorRef} />

                                    <TextField
                                      title={field.title}
                                      id={`edit-equipment-${field.key}`}
                                      requiredStyle={
                                        field.isRequired && 'required'
                                      }
                                      errorName={
                                        field.isError && field.errorMessage
                                      }
                                      value={equipmentPayload[field.key]}
                                      onChange={(val) => {
                                        this.onChange(field.key, val);
                                      }}
                                      onFocus={(e) =>
                                        this.setState((prevState) => ({
                                          equipmentModal:
                                            prevState.equipmentModal.map(
                                              (obj) =>
                                                obj.key === field.key &&
                                                obj.isError
                                                  ? Object.assign(obj, {
                                                      isError: false,
                                                      errorMessage: '',
                                                    })
                                                  : obj
                                            ),
                                        }))
                                      }
                                    />
                                  </Grid>
                                )}
                              </React.Fragment>
                            ) : (
                              <Grid item xs={field['grid-xs']} key={field.key}>
                                <div className='help-container'>
                                  <Switch
                                    title={
                                      "Overwrite duplicate indicator tag ID's"
                                    }
                                    checked={equipmentPayload[field.key]}
                                    setValue={(val) => {
                                      this.onChange(
                                        'deactivate_duplicate_tags',
                                        val
                                      );
                                      this.clearModalError();
                                    }}
                                  />
                                  <Tooltip
                                    title={
                                      UVCAdmin.facilityDetails.equipments
                                        .overwriteTagIDHelp
                                    }
                                    placement='top'
                                    classes={{ tooltipPlacementTop: 'tooltip' }}
                                  >
                                    <img src={iconHelp} alt='' width='16' />
                                  </Tooltip>
                                </div>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </div>
                    )}
                </React.Fragment>
              )}

            {action === DELETE_EQUIPMENT_TYPE &&
              department &&
              equipmentPayload && (
                <div className='remove-container'>
                  <img
                    src={warningIcon}
                    className='remove-icon'
                    alt=''
                    width='44'
                  />
                  <Typography variant='h6' className='remove-message'>
                    {
                      UVCAdmin.facilityDetails.equipments
                        .deleteEquipmentTypeMessage
                    }
                  </Typography>
                  <Typography variant='h6' className='remove-title'>
                    {equipmentPayload['equipment_type_name']}
                  </Typography>
                </div>
              )}

            {action === DELETE_EQUIPMENT_TYPE_WARNING && (
              <div className='remove-container'>
                <img
                  src={warningIcon}
                  className='remove-icon'
                  alt=''
                  width='44'
                />
                <Typography variant='h6' className='remove-message'>
                  Equipment Type is being used by active Equipment Items.
                </Typography>
              </div>
            )}

            {action === DELETE_EQUIPMENT && selectedEquipment && department && (
              <div className='remove-container'>
                <img
                  src={warningIcon}
                  className='remove-icon'
                  alt=''
                  width='44'
                />
                <Typography variant='h6' className='remove-message'>
                  {UVCAdmin.facilityDetails.equipments.deleteEquipmentMessage}
                </Typography>
                <Typography variant='h6' className='remove-title'>
                  {selectedEquipment['tag_id']}
                </Typography>
              </div>
            )}
            {action === EQUIPMENT_ADD_OPTIONS && 
              <EquipmentAddOptions openDialog={onOpen} />}
          </div>
        }
        onClose={(cb) => this.handleClose(null, cb, onClose)}
        loading={isEquipmentProcessing}
        action={
          <div className='edit-equipment-modal'>
            {action !== EQUIPMENT_ADD_OPTIONS &&
            action !== DELETE_EQUIPMENT_TYPE_WARNING &&
              ((department && equipmentPayload) || selectedEquipment) && (
                <div className={this.isCreate(action) ? "action-container multiple-actions" : "action-container"}>
                  <React.Fragment>
                    {this.isCreate(action) && (
                    <button 
                        className="btn tertiary size-normal"
                        onClick={() => onOpen(true, EQUIPMENT_ADD_OPTIONS)}>
                        Back
                    </button> 
                    )}
                    <button
                      className='btn tertiary size-normal'
                      onClick={() => this.handleClose(null, false, onClose)}
                    >
                      Cancel
                    </button>
                    {action === EDIT_EQUIPMENT_TYPE && (
                      <button
                        className='btn primary size-normal'
                        onClick={() => this.editEquipmentType(department)}
                      >
                        {'Save'}
                      </button>
                    )}
                    {action === BULK_ADD_EQUIPMENT && (
                      <button
                        className='btn primary size-normal'
                        disabled={
                          !equipmentPayload['equipment_type_id'] ||
                          !uploadedFile
                        }
                        onClick={() => this.bulkUploadEquipment(department)}
                      >
                        {'Upload CSV'}
                      </button>
                    )}
                    {action === ADD_EQUIPMENT && (
                      <button
                        className='btn primary size-normal'
                        onClick={() => this.addNewEquipment(department)}
                        disabled={!equipmentPayload['equipment_type_id']}
                      >
                        {'Add Equipment'}
                      </button>
                    )}
                    {action === DELETE_EQUIPMENT_TYPE && (
                      <button
                        className='btn primary size-normal delete-button'
                        onClick={() =>
                          this.removeEquipmentType(equipmentPayload, department)
                        }
                      >
                        {'Remove'}
                      </button>
                    )}
                    {action === DELETE_EQUIPMENT && (
                      <button
                        className='btn primary size-normal delete-button'
                        onClick={() =>
                          this.removeEquipment(selectedEquipment, department)
                        }
                      >
                        {'Remove'}
                      </button>
                    )}
                  </React.Fragment>
                </div>
              )}

            {action === DELETE_EQUIPMENT_TYPE_WARNING && (
              <div className='action-container single-action'>
                {' '}
                <React.Fragment>
                  <button
                    className='btn tertiary size-normal'
                    onClick={() => this.handleClose(null, false, onClose)}
                  >
                    {'Ok'}
                  </button>
                </React.Fragment>
              </div>
            )}
             {action === EQUIPMENT_ADD_OPTIONS && (
                <div className="action-container single-action"> 
                  <React.Fragment>
                  <button 
                      className="btn tertiary size-normal"
                      onClick={() => this.handleClose(null, false, onClose)}>
                      {"Cancel"}
                  </button>
                  </React.Fragment>
                </div>
             )}
          </div>
        }
      />
    );
  };
}
