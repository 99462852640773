import React from "react";
import _ from "lodash";
import "./Detail.scss";

import SubLayout from "layout/Sub";
import iconBack from "images/icon-back-prim.svg";
import iconEdit from "images/icon-edit.svg";

import { browserStorage, report as reportAPI } from "services/api";

import { UVCAdmin, uvcAdminRole } from "helpers/constant";

import { Departments, Enclosures, Operators, Users } from "components/uv-admin/details";
import { ModifyFacilityModal } from "components";
import { MODIFY } from "components/uv-admin/facilities/Facilities";
import { Tooltip } from "@material-ui/core";

export const ENCLOSURES = 0;
export const USERS = 1;
export const DEPARTMENTS = 2;
export const OPERATORS = 3;

export default class Detail extends React.Component {
    state = {
        selectedId: browserStorage.getSessionData(this.props.username),
        loading: true,
        facility: null,
        currentSubTab: ENCLOSURES,
        isEditFacilityModalOpen: false,
        addFacilityFields: [...UVCAdmin.addFacilityModal],
        isSubmitting: false,
        isOpenTitle: false,
        isOpenAddr: false,
    };

    componentDidMount = () => {
        const facilityId = this.props.match.params.id;
        if (facilityId) {
            this.getFacilityDetail(facilityId);
            this.props.setActiveTab(0);
        } else {
            this.onBack();
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.tab !== this.props.tab) {
            this.setState({ currentSubTab: this.props.tab });
        }
    };

    getFacilityDetail = (id) => {
        this.setState({ loading: true });
        return reportAPI
            .getFalicityById(id)
            .then((facility) => {
                facility.users = this.transformUsers(facility.users, facility.id);
                this.setState({ facility: facility });
            })
            .then(() => {
                this.setState({ loading: false });
            })
            .catch((err) => {
                this.onBack();
            });
    };

    transformUsers = (userList, facilityId) => {
        userList.map((user) => {
            user.roles = user.roles.filter((role) => role["facility_id"] === facilityId);
            user["role_name"] = user.roles[0]["role_name"];
            delete user.roles;
            return user;
        });
        return userList;
    };

    closeAndResetForm = (cb) => {
        this.setState({
            isEditFacilityModalOpen: cb,
            addFacilityFields: [...UVCAdmin.addFacilityModal],
            selectedFacility: null,
        });
    };

    onBack = () => {
        this.props.setActiveTab(0);
        this.props.history.push("/facilities");
        window.sessionStorage.clear();
    };

    addrFacility = (facility) => `${facility.address_1} ${facility.address_2 !== null && facility.address_2 !== "" ? facility.address_2 + ", " : ""}${facility.city}, ${facility.state} ${facility.zip}`;

    mouseEnter = (e, type) => {
        if (e.target.scrollWidth > e.target.clientWidth) {
            type === 'title' ? this.setState({ isOpenTitle: true }) : this.setState({ isOpenAddr: true });
        }
    }


    render = () => {
        const { loading, facility, currentSubTab, isEditFacilityModalOpen, isOpenTitle, isOpenAddr } = this.state;
        const { roles, location, setActiveTab, updateRole, setStackToaster, isUserSessionExpire } = this.props;

        return (
            <React.Fragment>
                <SubLayout
                    loading={loading}
                    activeTab={currentSubTab}
                    setActiveTab={(cb) => {
                        this.setState({ currentSubTab: cb })
                        setActiveTab(cb)
                    }}
                    loadingTitle={UVCAdmin.facilityDetails.loadingMessage}
                    title={
                        !loading && (
                            <div className="facility-detail-header-container">
                                <div className="facility-title-container">
                                    {location.state && 
                                    location.state.hasOwnProperty('singleFacility') &&
                                    location.state.singleFacility === false && (
                                        <button className="btn tertiary size-tiny back-button" onClick={() => this.onBack()}>
                                            <img src={iconBack} alt="" width="20" />
                                        </button>
                                    )}
                                    <div>
                                        <div className="facility-title-inner-container">
                                            <Tooltip title={facility.name} placement="top" disableHoverListener={!isOpenTitle}>
                                                <h4 className="facility-title" onMouseEnter={(e) => this.mouseEnter(e, 'title')}>
                                                    {facility.name}
                                                </h4>
                                            </Tooltip>
                                            {_.find(roles, { name: uvcAdminRole, facility_id: null }) && (
                                                <button 
                                                    className="btn tertiary size-small action-button" 
                                                    onClick={() => this.setState({ isEditFacilityModalOpen: true })}>
                                                    <img src={iconEdit} width="15" alt="Edit facility" />
                                                </button>
                                            )}
                                        </div>
                                        <Tooltip title={this.addrFacility(facility)} placement="top" disableHoverListener={!isOpenAddr}>
                                            <h5 className="facility-address" onMouseEnter={(e) => this.mouseEnter(e, 'addr')}>{this.addrFacility(facility)}</h5>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="flex-space"></div>
                            </div>
                        )
                    }
                >
                    {!loading && (
                        <React.Fragment>
                            {currentSubTab === ENCLOSURES && <Enclosures
                                roles={roles} 
                                facilityId={facility.id} 
                                enclosures={facility.enclosures} 
                                softwareVersions={facility["software_packages"]} 
                                fetchFacilityDetail={() => {
                                    updateRole();
                                    this.getFacilityDetail(this.props.match.params.id)
                                }}
                                setToaster={(type, msg) => this.setState({
                                    toasterMessage: msg,
                                    toasterType: type,
                                })}
                                setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                                isUserSessionExpire={isUserSessionExpire}
                            />}
                            
                            {currentSubTab === USERS && (
                                <Users
                                    roles={roles} 
                                    facilityId={facility.id}
                                    users={facility.users}
                                    fetchFacilityDetail={() => {
                                        updateRole();
                                        this.getFacilityDetail(this.props.match.params.id)
                                    }}
                                    setToaster={(type, msg) =>
                                        this.setState({
                                            toasterMessage: msg,
                                            toasterType: type,
                                        })
                                    }
                                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                                    isUserSessionExpire={isUserSessionExpire}
                                />
                            )}
                            
                            {currentSubTab === OPERATORS && <Operators 
                                facilityId={facility.id} 
                                departments={facility.departments}
                                setToaster={(type, msg) => this.setState({
                                    toasterMessage: msg,
                                    toasterType: type,
                                })}
                                setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                                isUserSessionExpire={isUserSessionExpire} />}

                            {currentSubTab === DEPARTMENTS && <Departments 
                                facilityId={facility.id} 
                                departments={facility.departments}
                                setToaster={(type, msg) => this.setState({
                                    toasterMessage: msg,
                                    toasterType: type,
                                })}
                                fetchFacilityDetail={() => {
                                    updateRole();
                                    this.getFacilityDetail(this.props.match.params.id)
                                }}
                                setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                                isUserSessionExpire={isUserSessionExpire} />}

                            {/* Add/Edit facility modal */}
                            <ModifyFacilityModal
                                id={this.props.match.params.id} 
                                open={isEditFacilityModalOpen} 
                                action={MODIFY} 
                                facility={facility}
                                onClose={(cb) => this.setState({
                                    isEditFacilityModalOpen: cb})}
                                onSubmit={(cb) => {
                                    this.setState({isEditFacilityModalOpen: cb})
                                    this.getFacilityDetail(this.props.match.params.id)
                                }}
                                setToaster={(type, msg) => this.setState({
                                    toasterType: type,
                                    toasterMessage: msg
                                })}
                                setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                                isUserSessionExpire={isUserSessionExpire} />
                        </React.Fragment>
                    )}
                </SubLayout>
            </React.Fragment>
        );
    };
}
