import React from "react";
import { Snackbar } from "@material-ui/core";

import "./ToasterMessage.scss";

export class ToasterWithFunctionTemplate extends React.Component {
    render = () => {
        const { icon, title, actionFn, actionTitle } = this.props;
        return (
            <div className="custom-toaster">
                <div className="toaster-with-function">
                    <span>{title}</span>
                    <button className="btn secondary size-tiny" onClick={() => actionFn()}>
                        <img className="icon left" src={icon} alt="" />
                        {actionTitle}
                    </button>
                </div>
            </div>
        )
    }
}

export class ToasterMessageTemplate extends React.Component {
    render = () => {
        const { icon, title } = this.props;
        return (
            <div className="custom-toaster">
                <div className="toaster-no-function">
                    <img className="icon left" src={icon} alt="" />
                    <span>{title}</span>
                </div>
            </div>
        )
    }
}

export default class ToasterMessage extends React.Component {

    closeToaster = (e, reason) => {
        if(this.props.actionFn) return;
        if(reason === 'timeout' && this.props.handleClose) {
            return this.props.handleClose(null)
        }
    }

    render = () => {
        const { open, title, actionFn, actionTitle, icon, isPersistent } = this.props;

        return (
            <Snackbar
                className="toaster-container" 
                open={open}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                disableWindowBlurListener
                message={
                    actionFn ? (
                        <div className="custom-toaster">
                            <div className="toaster-with-function">
                                <span>{title}</span>
                                <button className="btn secondary size-tiny" onClick={() => actionFn()}>
                                    <img className="icon left" src={icon} alt="" />
                                    {actionTitle}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="custom-toaster">
                            <div className="toaster-no-function">
                                <img className="icon left" src={icon} alt="" />
                                <span>{title}</span>
                            </div>
                        </div>
                    )
                }
                onClose={(e, reason) => this.closeToaster(e, reason)}
                autoHideDuration={isPersistent || isPersistent === true ? null : 6000}
            />
        );
    };
}
