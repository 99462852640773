import JSON_CONFIG from "env.json"

export default {

    get_api_url: () => {
        return JSON_CONFIG["UVC_API_URL"]
    },

    get: (key) => {
        return JSON_CONFIG[key]
    },

    get_max_facilities: () => {
        return JSON_CONFIG["MAX_FACILITY_COUNT"]
    },

    get_text_field_length: () => {
        return JSON_CONFIG["MAX_TEXTFIELD_INPUT"]
    },

    get_offset_timeout: () => {
        return JSON_CONFIG["OFFSET_TIMEOUT"]
    },

    get_ciis_service_url: () => {
        return JSON_CONFIG["CIIS_SERVICE_URL"]
    },

    get_ciis_service_url_without_version: () => {
        return JSON_CONFIG["CIIS_SERVICE_URL_WITHOUT_VERSION"]
    }

}
