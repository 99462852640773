import React from 'react';
import _ from 'lodash';
import { Modal } from 'components/common';

import "components/uv-admin/details/Detail.scss";
import { ADD_OPERATOR, EDIT_OPERATOR, DELETE_OPERATOR } from "components/uv-admin/details/operators/Operators";

import { AddOptions, SingleAddModal, DeleteModal, BulkAddModal } from "components/uv-admin/details";

export const OPERATOR_ADD_OPTIONS = 0;
export const ADD_SINGLE_OPERATOR = 1;
export const BULK_ADD_OPERATORS = 2;

export default class ModifyOperator extends React.Component {
    initialState = {
        currentTab: OPERATOR_ADD_OPTIONS,
        title: 'Add Operators',
        departments: null,
        selectedOperator: null,
    }

    state = {...this.initialState}

    componentDidUpdate = (prevProps) => {
        if(prevProps.open !== this.props.open) {
            this.setInitialValue(this.props.action)
        }
        if(prevProps.isUserSessionExpire !== this.props.isUserSessionExpire && this.props.isUserSessionExpire === true) {
            this.handleClose(false);
        }
    }

    setInitialValue = (action) => {
        switch(action) {
            case ADD_OPERATOR:
            case EDIT_OPERATOR:
                let filteredDepartments = [];
                _.forEach(this.props.departments, (field) => {
                    let obj = {};
                    obj['key'] = field.id;
                    obj['title'] = field.title;
                    filteredDepartments.push(obj)
                })
                this.setState(action === EDIT_OPERATOR 
                    ? {departments: filteredDepartments, selectedOperator: this.props.operator} 
                    : {departments: filteredDepartments})
                if(action === EDIT_OPERATOR) {
                    this.switchTab(ADD_SINGLE_OPERATOR)
                }
                else {
                    this.switchTab(OPERATOR_ADD_OPTIONS)
                }
                break;
            case DELETE_OPERATOR:
                this.setState({selectedOperator: this.props.operator})
                this.switchTab(-1)
                break;
            default: return;
        }
    }

    handleClose = (cb) => {
        this.setState(this.initialState, () => this.props.onClose(cb))
    }

    handleSubmit = (cb) => {
        this.setState(this.initialState, () => this.props.onSubmit(cb))
    }

    switchTab = (number) => {
        this.setState({
            currentTab: number
        }, () => this.changeTitle(this.props.action === EDIT_OPERATOR ? -2 : number))
    }

    changeTitle = (number) => {
        switch (number) {
            case OPERATOR_ADD_OPTIONS:
                return this.setState({title: 'Add Operators'});
            case ADD_SINGLE_OPERATOR:
                return this.setState({title: 'Add an Operator'});
            case BULK_ADD_OPERATORS:
                return this.setState({title: 'Bulk Upload Operators'});
            case -1:
                return this.setState({title: 'Delete Operator'})
            case -2:
                return this.setState({title: 'Edit Operator'})
            default: 
                return this.setState({title: ''});
        }
    }

    render = () => {
        const { open, action, facilityId, setToaster, rawOperators, setStackToaster } = this.props;
        const { currentTab, title, departments, selectedOperator } = this.state;

        return (
            <Modal
                open={open} 
                title={title}
                content={
                    <React.Fragment>
                        {action !== DELETE_OPERATOR && (
                            <form className="edit-operator-modal">
                                {currentTab === OPERATOR_ADD_OPTIONS && <AddOptions 
                                    tab={currentTab}
                                    closeForm={(cb) => this.handleClose(cb)} 
                                    setTab={(tab) => this.switchTab(tab)} 
                                    />}
                                {currentTab === ADD_SINGLE_OPERATOR && <SingleAddModal
                                    action={action}
                                    tab={currentTab} 
                                    closeForm={(cb) => this.handleClose(cb)} 
                                    submit={(cb) => this.handleSubmit(cb)}
                                    goBack={() => this.switchTab(OPERATOR_ADD_OPTIONS)} 
                                    departments={departments}
                                    operator={
                                        action === EDIT_OPERATOR 
                                        && selectedOperator 
                                        && rawOperators.filter(opt => opt.id === selectedOperator.id)[0]}
                                    facilityId={facilityId}
                                    setToaster={(type, msg) => setToaster(type, msg)}
                                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                                    />}
                                {currentTab === BULK_ADD_OPERATORS && <BulkAddModal
                                    facilityId={facilityId}
                                    closeForm={(cb) => this.handleClose(cb)}
                                    submit={(cb) => this.handleSubmit(cb)}
                                    setToaster={(type, msg) => setToaster(type, msg)}
                                    goBack={() => this.switchTab(OPERATOR_ADD_OPTIONS)}
                                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                                    />}
                            </form>
                        )}
                        {action === DELETE_OPERATOR && selectedOperator && (
                            <DeleteModal 
                                operator={selectedOperator}
                                facilityId={facilityId}
                                closeForm={(cb) => this.handleClose(cb)}
                                submit={(cb) => this.handleSubmit(cb)}
                                setToaster={(type, msg) => setToaster(type, msg)}
                                setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                            />
                        )}
                    </React.Fragment>
                }
                onClose={(cb) => this.handleClose(cb)}
            />
        )
    }
}
