import React from "react";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import iconEdit from "images/icon-edit.svg";
import iconWarning from "images/icon-warning-white.svg";
import iconEnclosures from "images/graphic-enclosures.svg";

import { facilityAdminRole, UVCAdmin, uvcAdminRole } from "helpers/constant";
import { pastDateCalculator } from "helpers/functions";

import { EditEnclosureModal, EditSoftwareModal } from "components/uv-admin/details";
import _ from "lodash";

export const OFFLINE_STATUS = "";
export const LOST_CONNECTION = "lost_connection";

export const EDIT_ENCLOSURE = "edit";
export const ADD_ENCLOSURE = "add";
export default class Enclosures extends React.Component {
    state = {
        selectedEnclosure: null,
        isEditVersionModalOpen: false,
        isEditEnclosureModalOpen: false,
        enclosureAction: ADD_ENCLOSURE,
        enclosures: this.props.enclosures,
    };

    mouseEnter = (e, id, type) => {
        const enclosures = this.state.enclosures.map((value, indx) => {
            if (value.id === id && e.target.scrollWidth > e.target.clientWidth) {
                if (type === 'title') value.isOpenTitle = true;
                if (type === 'location') value.isOpenLocation = true;
                if (type === 'udi') value.isOpenUdi = true;
                if (type === 'last_check_in') value.isOpenLastCI = true;
            } else {
                value.isOpenTitle = false;
                value.isOpenLocation = false;
                value.isOpenUdi = false;
                value.isOpenLastCI = false;
            }
            return value;
        });

        this.setState({ enclosures });
    }

    render = () => {
        const { softwareVersions, roles, fetchFacilityDetail, setToaster, facilityId, setStackToaster, isUserSessionExpire } = this.props;
        const {
            isEditVersionModalOpen,
            selectedEnclosure,
            isEditEnclosureModalOpen,
            enclosureAction,
            enclosures,
        } = this.state;

        return (
            <React.Fragment>
                <div className="details-container" id="enclosures">
                    {enclosures.length > 0 ? (
                        <React.Fragment>
                            <div className="details-header">
                                <h5 className="title">{UVCAdmin.facilityDetails.enclosures.grantTitle}</h5>
                                <div className="flex-spacer"></div>
                                {_.find(roles, { name: uvcAdminRole, facility_id: null }) && (
                                    <button className="btn primary size-small" onClick={() => this.setState({ isEditEnclosureModalOpen: true, enclosureAction: ADD_ENCLOSURE })}>
                                        {"Add An Enclosure"}
                                    </button>
                                )}
                            </div>
                            <Grid container spacing={4} justify="flex-start" alignItems="stretch" alignContent="flex-start" className="enclosures-content">
                                {enclosures.map((enclosure) => (
                                    <Grid item sm={12} lg={6} key={enclosure.id}>
                                        <div className="enclosure">
                                            <div className="nickname-container">
                                                <Tooltip title={enclosure["nickname"]} placement="top" disableHoverListener={!enclosure.isOpenTitle}>
                                                    <h6 className="nickname" onMouseEnter={(e) => this.mouseEnter(e, enclosure.id, 'title')}>{enclosure["nickname"]}</h6>
                                                </Tooltip>
                                                <div className="badge size-small">
                                                    {`Version ${enclosure["software_version"] || "Unknown"}`}
                                                </div>
                                                {Boolean(_.find(roles, { name: uvcAdminRole, facility_id: null })) ? (
                                                    <div className="action-buttons edit-software-action">
                                                        <button
                                                            className="btn tertiary size-small"
                                                            onClick={() => this.setState({ isEditVersionModalOpen: true, selectedEnclosure: enclosure })}
                                                        >
                                                            <img src={iconEdit} alt="" />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="flex-spacer"></div>
                                                )}
                                                <div className="flex-spacer"></div>
                                                {(Boolean(_.find(roles, { name: uvcAdminRole, facility_id: null })) || Boolean(_.find(roles, { name: facilityAdminRole, facility_id: facilityId }))) &&
                                                    <div className="action-buttons">
                                                        <button
                                                            className="btn tertiary size-small"
                                                            onClick={() => this.setState({ isEditEnclosureModalOpen: true, selectedEnclosure: enclosure, enclosureAction: EDIT_ENCLOSURE })}
                                                        >
                                                            <img src={iconEdit} alt="" />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            <Grid container spacing={1} justify="flex-start" className="property-container">
                                                {UVCAdmin.facilityDetails.enclosures.enclosureModal.map(
                                                    (item) =>
                                                        item.key !== "nickname" && (
                                                            <Grid item xs={4} key={item.key}>
                                                                <div key={item.key} className="property-item">
                                                                    {enclosure.hasOwnProperty(item.key) && (
                                                                        <React.Fragment>
                                                                            <Typography variant="h5">{item.title}</Typography>
                                                                            {!["last_check_in", "display_due_date_on_tags", "location", "udi"].includes(item.key)
                                                                            && <h6>{enclosure[item.key]}</h6>}
                                                                            
                                                                            {item.key === "last_check_in" 
                                                                                && (
                                                                                    <Tooltip title={pastDateCalculator(enclosure[item.key])} placement="top" disableHoverListener={!enclosure.isOpenLastCI}>
                                                                                        <Typography variant="h6" onMouseEnter={(e) => this.mouseEnter(e, enclosure.id, item.key)}>{pastDateCalculator(enclosure[item.key])}
                                                                                        </Typography>
                                                                                    </Tooltip>
                                                                                )}

                                                                            {item.key === "display_due_date_on_tags"
                                                                                && (
                                                                                    <Typography variant="h6">
                                                                                        {enclosure[item.key].toString() === 'true' ? 'Yes' : 'No'}
                                                                                    </Typography>
                                                                                )}
                                                                            {(item.key === "location" || item.key === "udi")
                                                                                && (
                                                                                    <Tooltip title={enclosure[item.key]} placement="top" disableHoverListener={item.key === "location" ? !enclosure.isOpenLocation : !enclosure.isOpenUdi}>
                                                                                        <Typography variant="h6" onMouseEnter={(e) => this.mouseEnter(e, enclosure.id, item.key)}>{enclosure[item.key]}</Typography>
                                                                                    </Tooltip>
                                                                                )}
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        )
                                                )}
                                            </Grid>
                                            <div className="offline-container">
                                                {enclosure["connection_status"] === LOST_CONNECTION && (
                                                    <div className="inner">
                                                        <h6>
                                                            <span>
                                                                <img src={iconWarning} alt="" />
                                                            </span>
                                                            {UVCAdmin.facilityDetails.enclosures.offlineMessage}
                                                        </h6>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <div className="no-details">
                            <div className="img-container">
                                <img src={iconEnclosures} alt="No-Enclosures" width="130" />
                            </div>
                            <div className="content-container">
                                <div>
                                    <h1>{UVCAdmin.facilityDetails.enclosures.grantTitle}</h1>
                                    <h5>{UVCAdmin.facilityDetails.enclosures.noEnclosureDesc}</h5>
                                    {_.find(roles, { name: uvcAdminRole }) && (
                                        <button 
                                            className="btn primary size-small" 
                                            onClick={() => this.setState({ 
                                                isEditEnclosureModalOpen: true, 
                                                enclosureAction: ADD_ENCLOSURE })}>
                                            {UVCAdmin.facilityDetails.enclosures.addAnEnclosure}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <EditSoftwareModal
                    open={isEditVersionModalOpen}
                    versions={softwareVersions}
                    enclosure={selectedEnclosure}
                    onClose={(cb) => this.setState({ isEditVersionModalOpen: cb, selectedEnclosure: null })}
                    onSubmit={(cb) => {
                        this.setState({
                            isEditVersionModalOpen: cb,
                            selectedEnclosure: null,
                        });
                        fetchFacilityDetail();
                    }}
                    setToaster={(type, msg) => setToaster(type, msg)}
                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                    isUserSessionExpire={isUserSessionExpire}
                />
                <EditEnclosureModal
                    roles={roles}
                    facilityId={facilityId}
                    open={isEditEnclosureModalOpen}
                    onClose={(cb) => {
                        this.setState({
                            isEditEnclosureModalOpen: cb,
                            selectedEnclosure: null,
                        });
                    }}
                    onSubmit={(cb) => {
                        this.setState({
                            isEditEnclosureModalOpen: cb,
                            selectedEnclosure: null,
                        });
                        fetchFacilityDetail();
                    }}
                    enclosure={selectedEnclosure}
                    action={enclosureAction}
                    versions={softwareVersions}
                    setToaster={(type, msg) => setToaster(type, msg)}
                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                    isUserSessionExpire={isUserSessionExpire}
                />
            </React.Fragment>
        );
    };
}
