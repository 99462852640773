/******************************************************************************\
 * File: Login.jsx
 *
 * Author: CIIS
 *
 * Description:
 *
 * Notes:
\******************************************************************************/
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import "./style.scss";
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import { Spinner, TextField } from "components/common";
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { auth as authAPI, browserStorage } from "services/api";
import { validateEmail } from "helpers/functions";
import { withRouter } from "react-router";
//------------------------------------------------------------------------------

export const LOGIN_FORM = 0;
export const FORGOT_FORM = 1;
export const FORGOT_CONFIRMATION_FORM = 2;
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loggedIn: false,
            ciisUserInfo: null,
            username: "",
            password: "",
            loginError: false,
            isLoginProcessing: false,
            currentTab: LOGIN_FORM,
            forgotEmail: '',
            inputError: null,
            isNoRoles: false
        };
    }

    componentDidMount() {
        const { history, location } = this.props;
        if (location.state?.tab) {
            this.setState({ currentTab: location.state.tab });
            history.replace();
        }
    }

    onLogin = (event) => {
        const { username, password } = this.state;
        //var username = "CIISService@ciis.canon.com";
        //var password = "satbhpW0rd";
        this.setState({
            loginError: false,
            isLoginProcessing: true,
        });

        const formData = {
            email: username,
            password: password,
        };

        return authAPI.ciis_login(formData)
            .then((resp) => {
                if (resp && resp.roles.length === 0) {
                    this.setState({
                        loggedIn: false,
                        ciisUserInfo: null,
                        loginError: true,
                        isLoginProcessing: false,
                        isNoRoles: true
                    });
                    browserStorage.removeData("header");
                } else {
                    this.setState({
                        ciisUserInfo: resp,
                        loggedIn: true,
                        isLoginProcessing: false,
                    });
                    if (browserStorage.getData('savedFacility')) {
                        let savedFacility = browserStorage.getData('savedFacility')
                        if (!browserStorage.getData('savedFacility')[resp.profile.user_id]) {
                            savedFacility[`${resp.profile.user_id}`] = {
                                username: resp.profile.user_id,
                                info: resp,
                                featureReport: null,
                                facilityReport: null,
                            }
                        } 
                        
                        browserStorage.saveData('savedFacility', savedFacility);
                    }
                    this.props.setUsername(resp);
                }
            })
            .catch((err) => {
                this.setState({
                    loggedIn: false,
                    ciisUserInfo: null,
                    loginError: true,
                    isLoginProcessing: false,
                });
            });
    };

    resetPassword = (email) => {
        switch (true) {
            case email === null:
            case email === "":
            case email === undefined:
                this.setState({inputError: 'Required Field'})
                return;
            case !validateEmail(email):
                this.setState({inputError: 'Invalid Email format!'})
                return;
            default:
                this.setState({isLoginProcessing: true})
                return authAPI.resetPassword(email)
                    .then((res) => {
                        if(res) {
                            this.setState({
                                isLoginProcessing: false,
                                currentTab: FORGOT_CONFIRMATION_FORM,
                                forgotEmail: '',
                            })
                        }
                    })
                    .catch(err => {
                        this.setState({
                            isLoginProcessing: false,
                            inputError: err.response.data.errors[0],
                        })
                    })
        }
    }

    onChangeHandler = (value, name) => {
        let nam = name;
        let val = value;
        this.setState({ [nam]: val });
    };

    handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.charCode === 13 && this.state.currentTab === LOGIN_FORM) {
            this.onLogin();
        }

        if (e.charCode === 13 && this.state.currentTab === FORGOT_FORM) {
            this.resetPassword(this.state.forgotEmail)
        }
    };

    render() {
        const { loginError, currentTab, forgotEmail, inputError, isNoRoles } = this.state;

        return (
            <div>
                {/* Since this is not a form keypress is handled on inputs and button*/}
                {/* {!loggedIn && !loading && ( */}
                <div className="container-login">
                    <header>
                        <div className="logo"></div>
                    </header>
                    <div className="login">
                        {currentTab === LOGIN_FORM && (
                            <div className="login-content">
                                <h2>Login</h2>
                                <h3>Please login with your credentials</h3>

                                <div className="fields">
                                    <div className="field">
                                        <TextField 
                                            title="Email"
                                            type="text"
                                            className="input-primary size-full"
                                            placeholder="Email"
                                            autoFocus
                                            value={this.state.username}
                                            name="username"
                                            onChange={(val, name) => this.onChangeHandler(val, name)}
                                            onKeyPress={this.handleKeypress}
                                        />
                                        {/* <input type="text" className="input-primary size-full" placeholder="Email" autoFocus name="username" onChange={this.onChangeHandler} onKeyPress={this.handleKeypress} /> */}
                                    </div>

                                    <div className="field">
                                        <TextField 
                                            title="Password"
                                            type="password"
                                            className="input-primary size-full"
                                            placeholder="Password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={(val, name) => this.onChangeHandler(val, name)}
                                            onKeyPress={this.handleKeypress}
                                        />
                                        {/* <input type="password" className="input-primary size-full" placeholder="Password" name="password" onChange={this.onChangeHandler} onKeyPress={this.handleKeypress} /> */}
                                    </div>
                                    <div className="forgot-password">
                                        <button 
                                            className="btn primary size-small transparent"
                                            onClick={() => this.setState({currentTab: FORGOT_FORM})}>
                                            Forgot Password?
                                        </button>
                                    </div>
                                </div>

                                {loginError && <div className="error-message">{isNoRoles ? "You are not authorized to access this site, please contact your facility administrator." : "Login failed. Please try again." }</div>}

                                <div className="login-button">
                                    <Spinner open={this.state.isLoginProcessing} fullscreen={false} />
                                    {!this.state.isLoginProcessing && (
                                        <button className="btn primary size-normal full" onClick={() => this.onLogin()} onKeyPress={this.handleKeypress}>
                                            Login
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}

                        {currentTab === FORGOT_FORM && (
                            <div className="login-content">
                                <h2>Reset Password</h2>
                                <h3>
                                    Please enter your email address and we'll send <br />
                                    you a link to reset your password
                                </h3>
                                <div className="require-title-container">
                                    <h6 className="require-title required">Required</h6>
                                </div>
                                <div className="fields">
                                    <div className="field">
                                        <TextField 
                                            title="Email"
                                            type="email"
                                            className="input-primary size-full"
                                            requiredStyle="required"
                                            placeholder="Email"
                                            autoFocus
                                            errorName={inputError}
                                            value={forgotEmail}
                                            name="forgotEmail"
                                            onChange={(val, name) => this.onChangeHandler(val, name)}
                                            onKeyPress={this.handleKeypress}
                                            onFocus={() => this.setState({inputError: null})}
                                        />
                                    </div>
                                </div>
                                <div className="action-container login-button">
                                    <Spinner open={this.state.isLoginProcessing} fullscreen={false} />
                                    {!this.state.isLoginProcessing && (
                                        <React.Fragment>
                                            <button 
                                                className="btn tertiary size-normal"
                                                onClick={() => this.setState({currentTab: LOGIN_FORM})}>
                                                Cancel
                                            </button>
                                            <button 
                                                className="btn primary size-normal"
                                                onClick={() => this.resetPassword(forgotEmail)}>
                                                Reset Password
                                            </button>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        )}

                        {currentTab === FORGOT_CONFIRMATION_FORM && (
                            <div className="login-content center-content">
                                <h2>Successfully Sent!</h2>
                                <h3>
                                    Please check your email for instructions on how to <br/> 
                                    reset your password
                                </h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
