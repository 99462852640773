import React from "react";
import _ from "lodash"
import { Grid, Tooltip, Typography } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import iconNoDepartments from "images/graphic-departments.svg";

import { UVCAdmin } from "helpers/constant";
import { report as reportAPI } from "services/api";

import { Equipments } from "components/uv-admin/details";
import { DotLoading } from "components/common";
import { EditDepartmentModal, EditEquipmentModal } from "components/uv-admin/details";
import { ADD_EQUIPMENT } from "components/uv-admin/details/equipments/Equipments";

export const ADD_DEPARTMENT = "add";
export const EDIT_DEPARTMENT = "edit";

export default class Departments extends React.Component {
    state = {
        selectedDepartment: null,
        equipmentGeneralInfo: null,
        isLoading: false,
        action: ADD_DEPARTMENT,
        isEditDepartmentModal: false,
        modalDepartment: null,
        equipmentAction: ADD_EQUIPMENT,
        isEditEquipmentDialog: false,
        selectedEquipmentType: null,
        selectedEquipment: null,
        groupedEquipment: null,
        searchData: null,
        isAddEquipmentFired: true,
        departments: this.props.departments,
    };

    componentDidMount = () => {
        if (this.props.departments.length > 0) {
            this.handleGetDepartmentBasedOnFlag();
        }
    };

    handleGetDepartmentBasedOnFlag = () => {
        if (window.sessionStorage.getItem('isAddEquipmentFired') 
            && window.sessionStorage.getItem('isAddEquipmentFired').toString() === 'true') {
                const savedDepartment = JSON.parse(window.sessionStorage.getItem('currentDepartment'))
                let newDepartment = this.props.departments.filter(department => 
                    !savedDepartment.some(savedDept => savedDept.id === department.id))[0]
                this.setState({ 
                    selectedDepartment: newDepartment 
                }, () => this.getDeparmentsInfo(this.state.selectedDepartment.id));
        }
        else {
            this.setState({ 
                selectedDepartment: this.props.departments[0] 
            }, () => this.getDeparmentsInfo(this.state.selectedDepartment.id));
        }
    }

    getDeparmentsInfo = (id) => {
        this.setState({ isLoading: true, equipmentGeneralInfo: null, searchData: null });

        return reportAPI
            .getDepartmentById(id)
            .then((data) => {
                this.setState({ isLoading: false, equipmentGeneralInfo: data });
                return data;
            })
            .then(res => this.reGroupEquipments('groupedEquipment', res["equipment_items"], "equipment_type", res["equipment_types"], res["protocols"]))
            .catch((err) => {
                this.setState({ isLoading: false });
            })
            .finally(() => {
                if (window.sessionStorage.getItem('isAddEquipmentFired') 
                && window.sessionStorage.getItem('isAddEquipmentFired').toString() === 'true') {
                    this.setState({
                        isEditEquipmentDialog: true,
                        equipmentAction: ADD_EQUIPMENT,
                        modalDepartment: this.state.equipmentGeneralInfo
                    })
                }
            });
    };

    getEquipmentbySearch = (id, val, deptInfo) => {
        return reportAPI.searchEquipment(id, val)
        .then((data) => {
            this.reGroupEquipments('searchData', data, "equipment_type", deptInfo["equipment_types"], deptInfo["protocols"])
        })
    }

    reGroupEquipments = (stateKey, equipments, key, types, protocol) => {
        if (equipments && types && protocol.length !== 0) {
            let groupedEquipments = _.groupBy(equipments, key);
            if (stateKey === 'groupedEquipment') {
                types.forEach(item => {
                    if(!groupedEquipments.hasOwnProperty(item.name)) {
                        groupedEquipments = {...groupedEquipments, [item.name]: []}
                    }
                })
            }
            this.setState({ [stateKey]: 
                groupedEquipments 
            });
        }
        else {
            this.setState({ [stateKey]: 
                null 
            });
        }
    };

    onChangeDepartment = (val) => {
        this.setState({ selectedDepartment: val }, () => {
            this.getDeparmentsInfo(val.id);
        });
    };

    handleOpenEquipmentDialog =(cb, action) => {
        this.setState({
            isEditEquipmentDialog: cb,
            equipmentAction: action,
        })
    }

    mouseEnter = (e, id) => {
        const departments = this.state.departments.map((value, indx) => {
            if (value.id === id && e.target.scrollWidth > e.target.clientWidth) {
                value.isOpenTitle = true;
            } else {
                value.isOpenTitle = false;
            }
            return value;
        });
        this.setState({ departments });
    }
    render = () => {
        const { 
            selectedDepartment, 
            equipmentGeneralInfo, 
            isEditDepartmentModal, 
            action, 
            modalDepartment, 
            equipmentAction, 
            isEditEquipmentDialog, 
            selectedEquipmentType, 
            selectedEquipment,
            groupedEquipment,
            searchData,
            isAddEquipmentFired,
            departments,
        } = this.state;
        const { setToaster, fetchFacilityDetail, facilityId, setStackToaster, isUserSessionExpire } = this.props;

        return (
            <React.Fragment>
                <div className="details-container" id="departments">
                        {departments && departments.length > 0 && selectedDepartment ? (
                            <React.Fragment>
                                <h5 className="outer-title">{UVCAdmin.facilityDetails.departments.grantTitle}</h5>
                                <div className="departments-content">
                                    <Grid container className="departments-grid-container">
                                        <Grid item zeroMinWidth xs={2} className="departments-grid-item">
                                            <button
                                                className="btn primary size-small"
                                                onClick={() =>
                                                    this.setState({
                                                        isEditDepartmentModal: true,
                                                        action: ADD_DEPARTMENT,
                                                    })
                                                }
                                            >
                                                Add
                                            </button>
                                            <div className="departments-title-list">
                                                {departments.map((dept) => (
                                                    <div className={dept.id === selectedDepartment.id ? "item active" : "item"} key={dept.id} onClick={() => this.onChangeDepartment(dept)}>
                                                        <Tooltip title={dept.title} placement="top" disableHoverListener={!dept.isOpenTitle}>
                                                            <Typography variant="h5" onMouseEnter={(e) => this.mouseEnter(e, dept.id)}>{dept.title}</Typography>
                                                        </Tooltip>
                                                    </div>
                                                ))}
                                            </div>
                                        </Grid>
                                        <Grid item zeroMinWidth xs={10} className="departments-grid-item" style={{ position: "relative" }}>
                                            {equipmentGeneralInfo ? (
                                                <Equipments
                                                    searchData={searchData}
                                                    equipmentInfo={equipmentGeneralInfo}
                                                    groupedEquipment={groupedEquipment}
                                                    handleOpenDeparmentDialog={(cb) =>
                                                        this.setState({
                                                            isEditDepartmentModal: cb,
                                                            action: EDIT_DEPARTMENT,
                                                            modalDepartment: equipmentGeneralInfo
                                                        })
                                                    }
                                                    handleOpenEquipmentDialog={(cb, action) => 
                                                        this.setState({
                                                            isEditEquipmentDialog: cb,
                                                            equipmentAction: action,
                                                            modalDepartment: equipmentGeneralInfo
                                                        })
                                                    }
                                                    setSelectedEquipmentType={(cb) => {
                                                        this.setState({selectedEquipmentType: cb})
                                                    }}
                                                    setSelectedEquipment={(cb) => {
                                                        this.setState({selectedEquipment: cb})
                                                    }}
                                                    setToaster={(type, msg) => setToaster(type, msg)}
                                                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                                                    getEquipmentbySearch={(val) => this.getEquipmentbySearch(selectedDepartment.id, val, equipmentGeneralInfo)}
                                                    setEmptySeachData={() => this.setState({searchData: null})}
                                                />
                                            ) : (
                                                <DotLoading title="Loading Equipment..." />
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="no-details">
                                <div className="img-container">
                                    <img src={iconNoDepartments} alt="No-Department" width="130" />
                                </div>
                                <div className="content-container">
                                    <div>
                                        <h1>{UVCAdmin.facilityDetails.departments.grantTitle}</h1>
                                        <h5>{UVCAdmin.facilityDetails.departments.noDepartmentsDesc}</h5>
                                        <button 
                                            className="btn primary size-small" 
                                            onClick={() =>
                                                this.setState({
                                                    isEditDepartmentModal: true,
                                                    action: ADD_DEPARTMENT,
                                                })
                                            }>
                                            {UVCAdmin.facilityDetails.departments.addAnDepartment}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                <EditDepartmentModal 
                    open={isEditDepartmentModal} 
                    action={action}
                    facilityId={facilityId}
                    isAddEquipmentFired={isAddEquipmentFired}
                    setAddEquipmentFired={(val) => {
                        window.sessionStorage.setItem('isAddEquipmentFired', val);
                        window.sessionStorage.setItem('currentDepartment', JSON.stringify(this.props.departments))
                    }} 
                    department={modalDepartment} 
                    onClose={(cb) => this.setState({ 
                        isEditDepartmentModal: cb,
                        modalDepartment: null
                    })}
                    onSubmit={(cb) => this.setState({ 
                        isEditDepartmentModal: cb,
                        modalDepartment: null
                    }, () => fetchFacilityDetail())}
                    setToaster={(type, msg) => setToaster(type, msg)}
                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                    isUserSessionExpire={isUserSessionExpire} 
                    />
                <EditEquipmentModal 
                    open={isEditEquipmentDialog}
                    action={equipmentAction}
                    type={selectedEquipmentType}
                    department={modalDepartment}
                    equipment={selectedEquipment}
                    onClose={(cb) => this.setState({ 
                        isEditEquipmentDialog: cb,
                        modalDepartment: null
                    }, () => {
                        if (window.sessionStorage.getItem('isAddEquipmentFired') 
                        && window.sessionStorage.getItem('isAddEquipmentFired').toString() === 'true') {
                            window.sessionStorage.setItem('isAddEquipmentFired', false)
                        }
                    })}
                    onSubmit={(dept, cb) => this.setState({ 
                        isEditEquipmentDialog: cb,
                        modalDepartment: null,
                        searchData: null, 
                    }, () => {
                        this.setState({selectedDepartment: dept})
                        this.getDeparmentsInfo(dept.id)
                        if (window.sessionStorage.getItem('isAddEquipmentFired') 
                        && window.sessionStorage.getItem('isAddEquipmentFired').toString() === 'true') {
                            window.sessionStorage.setItem('isAddEquipmentFired', false)
                        }
                    })}
                    setToaster={(type, msg) => setToaster(type, msg)}
                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                    isUserSessionExpire={isUserSessionExpire}
                    onOpen={this.handleOpenEquipmentDialog}
                />
            </React.Fragment>
        );
    };
}
