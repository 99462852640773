import React from 'react';
import './Spinner.scss';

export default class Spinner extends React.Component {
  render = () => {
    const { open, fullscreen } = this.props;
    return (
      <React.Fragment>
        {open && (
          <div>
            {fullscreen ? (
              <React.Fragment>
                <div className='spinner-container'>
                  <div className='spinner-large'></div>
                </div>
                <div className='spinner-bkgrd'></div>
              </React.Fragment>
            ) : (
              <div className='spinner-container'>
                <div className='custom-spinner'></div>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };
}
