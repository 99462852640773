import React from "react";

import "components/common/DotLoading/DotLoading.scss";

export default class DotLoading extends React.Component {
    render = () => {
        const { title, className } = this.props;

        return (
            <div className={`spinner-bkgrd ${className && className}`}>
                <div className="dot-loading-container">
                    <div className="dot-loading"></div>
                </div>
                <h3>{title}</h3>
            </div>
        );
    };
}
