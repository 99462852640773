import React from 'react';
import classNames from 'classnames';
import CONFIG from 'services/config';

import './TextField.scss';

export default class TextField extends React.Component {
  render = () => {
    const {
      title,
      className,
      value,
      requiredStyle,
      containerStyle,
      errorName,
      id,
      onChange,
      style,
      ...props
    } = this.props;

    return (
      <div
        className={
          containerStyle
            ? classNames('input-container', containerStyle)
            : 'input-container'
        }
      >
        {requiredStyle ? (
          <div className='title-relative'>
            <h6 className='title'>{title}</h6>
            <div className='required-container'>
              <h6 className={requiredStyle}> </h6>
            </div>
          </div>
        ) : (
          <h6 className='title'>{title}</h6>
        )}

        <input
          {...props}
          id={`${title}-${id}`}
          value={value}
          style={style}
          maxLength={`${CONFIG.get_text_field_length()}`}
          className={
            errorName
              ? classNames('input', className, 'error')
              : classNames('input', className)
          }
          onChange={(e) => onChange(e.target.value, e.target.name)}
        />
        <h6 className='error-title'>{errorName}</h6>
      </div>
    );
  };
}
