// Node module
import React from "react";
// Styles
import "components/common/Footer/Footer.scss";
// Helpers
import { UVCFooter } from "helpers/constant";

export default class Footer extends React.Component {
    state = {
        currentDate: new Date(),
    };

    render = () => {
        const { currentDate } = this.state;
        const { isShowSublinks, setActiveSubTab } = this.props;

        return (
            <footer className="custom-footer">
                <h5>
                    <span>&#169;</span>
                    <span> </span>
                    {currentDate.getFullYear()}
                    <span> </span>
                    <span>
                        <strong>{UVCFooter.appName}</strong>
                        <span> </span>
                        {"All rights reserved"}
                    </span>
                </h5>
                <div className="custom-flex"></div>
                {isShowSublinks && (
                    <div className="external-links">
                        {UVCFooter.links.map((link, index) => (
                            <button className="btn primary size-small" id={link.key} key={link.key} onClick={() => setActiveSubTab(index)}>
                                {link.title}
                            </button>
                        ))}
                    </div>
                )}
            </footer>
        );
    };
}
