import React from "react";
import { Typography } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";
import warningIcon from "images/icon-warning-large-red.svg";
import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";

import { UVCAdmin } from "helpers/constant";
import { report as reportAPI } from "services/api";
import { Spinner } from "components/common";

export default class DeleteModal extends React.Component {
    initialState = {
        isOperatorProcessing: false,
    };

    state = { ...this.initialState };

    deleteOperator = (e, id) => {
        e.preventDefault();
        this.setState({isOperatorProcessing: true})
        return reportAPI.removeOperator(this.props.facilityId, id)
            .then(res => {
                if(res) {
                    this.setState({
                        isOperatorProcessing: false,
                    }, () => {
                        // this.props.setToaster(toasterMessageType.SUCCESS, 'Operator deactivated successfully!');
                        this.props.setStackToaster(
                            (
                                <ToasterMessageTemplate 
                                    icon={iconSuccess}
                                    title={'Operator deactivated successfully!'}
                                />
                            )
                        )
                        this.props.submit(false)
                    })
                }
            })
            .catch(err => this.handleError(err))
    }
    
    handleError = (err) => {
        if (err && err.response && err.response.status && err.response.status === 422) {
            this.setState({
                isOperatorProcessing: false,
            }, () => {
                // this.props.setToaster(toasterMessageType.ERROR, err.response.data.errors);
                this.props.setStackToaster(
                    (
                        <ToasterMessageTemplate 
                            icon={iconError}
                            title={err.response.data.errors}
                        />
                    )
                )
            })
        }
        else {
            this.setState({
                isOperatorProcessing: false,
            }, () => {
                // this.props.setToaster(toasterMessageType.ERROR, 'Error when deactivating operator');
                this.props.setStackToaster(
                    (
                        <ToasterMessageTemplate 
                            icon={iconError}
                            title={'Error when deactivating operator'}
                        />
                    )
                )
            })
        }
    }

    render = () => {
        const { closeForm, operator } = this.props;
        const { isOperatorProcessing } = this.state;

        return (
            <div className="edit-operator-modal">
                <div className="remove-container">
                    <img src={warningIcon} className="remove-icon" alt="" width="44" />
                    <Typography variant="h6" className="remove-message">{UVCAdmin.facilityDetails.operators.deleteMessage}</Typography>
                    <Typography variant="h6" className="remove-title">{operator['name']}</Typography>
                </div>
                {!isOperatorProcessing ? (
                    <div className="action-container margin-40">
                        <button 
                            className="btn tertiary size-normal"
                            onClick={() => closeForm(false)}>
                            Cancel
                        </button>
                        <button 
                            className="btn primary size-normal delete-button" 
                            onClick={(e) => this.deleteOperator(e, operator.id)}
                            >
                            {"Remove"}
                        </button>
                    </div>
                ) : (
                    <div className="action-container multiple-actions">
                        <div className="free-space"></div>
                        <Spinner open={isOperatorProcessing} fullscreen={false} />
                        <div className="free-space"></div>
                    </div>
                )}
            </div>
        )
    };
}
