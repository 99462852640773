import React from "react";
import _ from "lodash";

import "components/uv-admin/details/Detail.scss";
import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";
import { ADD_DEPARTMENT, EDIT_DEPARTMENT } from './Departments';

import { Modal, TextField, Switch } from "components/common";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";
import { UVCAdmin } from "helpers/constant";

import { report as reportAPI } from "services/api";

export default class EditDepartmentModal extends React.Component {
    state = {
        selectedDepartment: null,
        departmentModal: UVCAdmin.facilityDetails.departments.departmentModal,
        stopFlag: false,
        isDepartmentProcessing: false,
        isAddEquipmentFired: true,
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.isUserSessionExpire !== this.props.isUserSessionExpire && this.props.isUserSessionExpire === true) {
            this.handleClose(false, this.props.onClose);
        }
        if(prevProps.department !== this.props.department && this.props.action === EDIT_DEPARTMENT) {
            let initializeModal = this.state.departmentModal.map(field => {
                let obj = {...field, isError: false, errorMessage: ''};
                return obj
            })
            this.setState({selectedDepartment: this.props.department, departmentModal: initializeModal})
        }
        else if (!this.state.stopFlag && this.props.action === ADD_DEPARTMENT) {
            this.state.departmentModal.forEach(field => {
                this.setState(prevState => ({
                    selectedDepartment: {
                        ...prevState.selectedDepartment,
                        [field.key]: '',
                    },
                    stopFlag: true
                }))
            })
        }
    }

    onChange = (key, value) => {
        this.setState(
            (prevState) => ({
                selectedDepartment: {
                    ...prevState.selectedDepartment,
                    [key]: value,
                },
            })
        );
    };

    formValidation = (stateData, stateModal) => {
        let data = this.state[stateData];
        let modal = this.state[stateModal];
        modal.forEach((el, index) => {
            switch(true) {
                case !data:
                case !data.hasOwnProperty(el.key):
                case data && data[el.key] === "":
                    el.isError = true;
                    el.errorMessage = "Required Field";
                    break
                default:
                    break;
            }
            this.setState({
                [stateModal]: [
                ...this.state[stateModal].slice(0, index),
                Object.assign({}, this.state[stateModal][index], el),
                ...this.state[stateModal].slice(index + 1),
            ]});
        })
        return !Boolean(_.find(modal, ['isError', true]))
    }

    addDepartment = () => {
        if (!this.formValidation('selectedDepartment', 'departmentModal')){
            return
        }
        else {
            let newDeparment = {...this.state.selectedDepartment, 'facility_id': this.props.facilityId}
            this.setState({isDepartmentProcessing: true})
            return reportAPI.addDepartment(newDeparment)
                .then(res => {
                    if(res) {
                        this.setState({
                            isDepartmentProcessing: false,
                            selectedDepartment: null
                        }, () => {
                            this.state.isAddEquipmentFired
                            && this.state.isAddEquipmentFired === true 
                            && this.props.setAddEquipmentFired(this.state.isAddEquipmentFired)
                            // this.props.setToaster(toasterMessageType.SUCCESS, 'New department added to facility.');
                            this.props.setStackToaster(
                                (
                                    <ToasterMessageTemplate 
                                        icon={iconSuccess}
                                        title={'New department added to facility.'}
                                    />
                                )
                            )
                            this.handleClose(false, this.props.onSubmit)
                            
                        })
                    }
                })
                .catch(err => this.handleError(err))
        }
        
    }

    updateDepartment = () => {
        if(!this.formValidation('selectedDepartment', 'departmentModal')){
            return
        }
        else {
            const { selectedDepartment } = this.state;
            this.setState({isDepartmentProcessing: true})
            return reportAPI.updateDepartment(selectedDepartment.id, selectedDepartment)
                .then(res => {
                    if(res) {
                        this.setState({
                            isDepartmentProcessing: false,
                            selectedDepartment: null
                        }, () => {
                            // this.props.setToaster(toasterMessageType.SUCCESS, 'Department updated successfully!');
                            this.props.setStackToaster(
                                (
                                    <ToasterMessageTemplate 
                                        icon={iconSuccess}
                                        title={'Department updated successfully!'}
                                    />
                                )
                            )
                            this.handleClose(false, this.props.onSubmit)
                        })
                    }
                })
                .catch(err => this.handleError(err))
        }
    }

    handleClose = (cb, callback) => {
        let initializeModal = this.state.departmentModal.map(field => {
            let obj = {...field, isError: false, errorMessage: ''};
            return obj
        })
        this.setState({ departmentModal: initializeModal, isAddEquipmentFired: true});
        initializeModal.forEach(field => {
            this.setState(prevState => ({
                selectedDepartment: {
                    ...prevState.selectedDepartment,
                    [field.key]: '',
                },
                stopFlag: false
            }), () => callback(cb))
        })
    }

    handleError = (err) => {
        if (err && err.response && err.response.status && err.response.status === 422) {
            let errors = err.response.data.errors;
            let errorModal = this.state.departmentModal.map(field => {
                let obj = _.indexOf(_.keys(errors), field.key) !== -1 
                        ? {...field, isError: true, errorMessage:  _.result(errors, field.key)} 
                        : {...field, isError: false, errorMessage: ''}
                return obj
            })
            this.setState({departmentModal: errorModal})
        }
        else {
            // this.props.setToaster(toasterMessageType.ERROR, 'Error when updating Department!')
            this.props.setStackToaster(
                (
                    <ToasterMessageTemplate 
                        icon={iconError}
                        title={'Error when updating Department!'}
                    />
                )
            )
        }
        this.setState({
            isDepartmentProcessing: false,
        })
    }

    render = () => {
        const { open, action, onClose } = this.props;
        const { departmentModal, selectedDepartment, isDepartmentProcessing, isAddEquipmentFired } = this.state;
        
        return (
            <Modal 
                open={open}
                title={action === ADD_DEPARTMENT ? 'Add a Department' : 'Edit Department'}
                content={
                    <div className="edit-department-modal">
                        <div className="require-title-container">
                            <h6 className="require-title required">Required</h6>
                        </div>
                        <React.Fragment>
                            {departmentModal.map(field => (
                                <TextField 
                                    title={field.title} 
                                    id={"edit-department"} 
                                    key={field.key} 
                                    requiredStyle={field.isRequired && "required"} 
                                    errorName={field.isError && field.errorMessage} 
                                    value={selectedDepartment && selectedDepartment[field.key] ? selectedDepartment[field.key] : ""}
                                    onChange={(val) => this.onChange(field.key, val)}
                                    onFocus={(e) => this.setState((prevState) => ({
                                        departmentModal: prevState.departmentModal.map((obj) => (
                                            obj.key === field.key && obj.isError
                                            ? Object.assign(obj, {isError: false, errorMessage: ''})
                                            : obj
                                        ))
                                    }))}
                                    autoFocus={action === ADD_DEPARTMENT && field.key === 'title' ? 'autoFocus' : ''}
                                />
                            ))}
                        </React.Fragment>
                        {action === ADD_DEPARTMENT && (
                            <Switch
                                title={"Add equipment to this department"} 
                                checked={isAddEquipmentFired} 
                                setValue={(val) => this.setState({isAddEquipmentFired: val})} />
                        )}
                    </div>
                }
                onClose={(cb) => this.handleClose(cb, onClose)}
                action={
                    <div className="edit-enclosure-modal">
                        <div className="action-container">
                            <button 
                                className="btn tertiary size-normal"
                                onClick={() => this.handleClose(false, onClose)} >
                                Cancel
                            </button>
                            {action === EDIT_DEPARTMENT ? (
                                <button 
                                    className="btn primary size-normal" 
                                    onClick={() => this.updateDepartment()}>
                                {"Save"}
                            </button>
                            ) : (
                                <button 
                                    className="btn primary size-normal"
                                    onClick={() => this.addDepartment()} >
                                {"Add Department"}
                            </button>
                            )}
                        </div>
                    </div>
                }
                loading={isDepartmentProcessing}
            />
        )
    }
}
