import React from "react";
import _ from "lodash";
import { TableCell, TableRow, Typography, Tooltip } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import iconOperators from "images/graphic-operators-shadow.svg";
import iconSearch from "images/icon-search.svg";
import iconEdit from "images/icon-edit.svg";
import iconDelete from "images/icon-delete.svg";

import { UVCAdmin } from "helpers/constant";

import { report as reportAPI } from "services/api";

import { CustomizeAccordion, CustomizeTable, DotLoading, TextField } from "components/common";
import { EditOperatorModal } from 'components/uv-admin/details'

export const ADD_OPERATOR = "add";
export const EDIT_OPERATOR = "edit";
export const DELETE_OPERATOR = "delete";

export default class Operators extends React.Component {
    state = {
        headers: UVCAdmin.facilityDetails.operators.operatorModal.headers,
        operators: null,
        isLoading: false,
        searchText: "",
        operatorAction: ADD_OPERATOR,
        isEditOperatorModal: false,
        selectedOperator: null,
        searchedOperators: null,
        isSearchProcessing: false,
        ungroupedOperators: null,
    };

    typingTimer = null

    componentDidMount = () => {
        this.getOperators(this.props.facilityId);
    };

    componentWillUnmount = () => {
        clearTimeout(this.typingTimer)
    }

    getOperators = (id) => {
        this.setState({ isLoading: true, operators: null });
        return reportAPI
            .fetchOperatorsByFacilityId(id)
            .then((data) => {
                this.reGroupOperators("operators", data, "department_name");
                this.setState({ isLoading: false, ungroupedOperators: data });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    searchOperator = (id, search) => {
        return reportAPI
            .fetchOperatorsByFacilityId(id, search)
            .then(data => {
                this.setState({ ungroupedOperators: data });
                this.reGroupOperators("searchedOperators", data, "department_name");
            })
            .then(() => this.setState({isSearchProcessing: false}))
            .catch((err) => {
                this.setState({ isSearchProcessing: false });
            });
    }

    reGroupOperators = (stateKey, operators, key) => {
        if (operators.length === 0) {
            this.setState({ [stateKey]: {} });
            return
        };
        let res = _.groupBy(operators, key);
        this.setState({ [stateKey]: res });
    };

    onSearchChange = (value) => {
        if (value === '') {
            this.setState({ isSearchProcessing: false })
        }
        clearTimeout(this.typingTimer)
        this.setState({ searchText: value, isSearchProcessing: true }, () => 
            this.typingTimer = setTimeout(() => {
                return this.state.searchText 
                ? this.searchOperator(this.props.facilityId, this.state.searchText) 
                : this.setState({searchedOperators: null, isSearchProcessing: false})
            }, 1000));
    };

    mouseEnter = (e, id, key, type, typeData) => {
        const operators = this.state[typeData][key].map((value, indx) => {
            if (value.id === id && e.target.scrollWidth > e.target.clientWidth) {
                type === 'name' ? value.isOpenName = true : value.isOpenBadge = true;
            } else {
                value.isOpenName = false;
                value.isOpenBadge = false;
            }
            return value;
        });
        this.setState({ [typeData]: { ...this.state[typeData], [key]: operators } });
    }

    render = () => {
        const { 
            headers, 
            operators, 
            isLoading, 
            searchText, 
            operatorAction, 
            isEditOperatorModal, 
            selectedOperator, 
            searchedOperators, 
            isSearchProcessing,
            ungroupedOperators,
        } = this.state;
        const { departments, facilityId, setToaster, setStackToaster, isUserSessionExpire } = this.props;

        return (
            <React.Fragment>
                <div className="details-container" id="operators">
                    
                        {isLoading ? (
                            <DotLoading title={UVCAdmin.facilityDetails.operators.loadingMessage} />
                        ) : (
                            <React.Fragment>
                                {operators && _.keys(operators).length !== 0 ? (
                                    <React.Fragment>
                                        <h5 className="outer-title">{UVCAdmin.facilityDetails.operators.grantTitle}</h5>
                                        <div className="details-header">
                                            <div className="search-container">
                                                <img src={iconSearch} alt="" width="20" />
                                                <TextField 
                                                    placeholder="Search..." 
                                                    className="search-bar" 
                                                    id={UVCAdmin.facilityDetails.operators.grantTitle} 
                                                    value={searchText} 
                                                    onChange={(val) => this.onSearchChange(val)} />
                                            </div>
                                            <div className="flex-spacer"></div>
                                            <button 
                                                className="btn primary size-small add-operators" 
                                                disabled={isLoading}
                                                onClick={() => this.setState({isEditOperatorModal: true, operatorAction: ADD_OPERATOR})}>
                                                {UVCAdmin.facilityDetails.operators.addAnOperator}
                                            </button>
                                        </div>
                                        {!searchedOperators && searchedOperators === null && !isSearchProcessing ? (
                                            <div className="operators-content">
                                                {_.keys(operators).map((key) => (
                                                    <CustomizeAccordion
                                                        key={key}
                                                        title={key}
                                                        titleStyle="operator-title"
                                                        badge={`${operators[key].length} Operators`}
                                                        children={
                                                            <CustomizeTable
                                                                headers={headers}
                                                                children={
                                                                    <React.Fragment>
                                                                        {operators[key].map((row) => (
                                                                            <TableRow
                                                                                hover
                                                                                key={row.id}
                                                                                classes={{
                                                                                    root: "normal-cell",
                                                                                }}
                                                                            >
                                                                                {headers.map(
                                                                                    (header) =>
                                                                                        header.isShow && (
                                                                                            <TableCell key={header.key} id={header.key} style={{width: header.width ? header.width : 'auto'}}>
                                                                                                {header.key !== "id" &&
                                                                                                    (
                                                                                                        <Tooltip title={row[header.key]} placement="top" disableHoverListener={header.key === 'name' ? !row.isOpenName : !row.isOpenBadge}>
                                                                                                            <div onMouseEnter={(e) => this.mouseEnter(e, row.id, key, header.key, 'operators')}>
                                                                                                                {row[header.key]}
                                                                                                            </div>
                                                                                                        </Tooltip>
                                                                                                    )
                                                                                                }
                                                                                                {header.key === "id" && (
                                                                                                    <div className="action-bar">
                                                                                                        <button 
                                                                                                            className="btn primary size-small action-button"
                                                                                                            onClick={() => this.setState({
                                                                                                                isEditOperatorModal: true, 
                                                                                                                operatorAction: EDIT_OPERATOR,
                                                                                                                selectedOperator: row
                                                                                                                })}>
                                                                                                            <img src={iconEdit} width="15" alt="Edit operator" />
                                                                                                        </button>
                                                                                                        <button 
                                                                                                            className=" btn primary size-small action-button"
                                                                                                            onClick={() => this.setState({
                                                                                                                isEditOperatorModal: true, 
                                                                                                                operatorAction: DELETE_OPERATOR,
                                                                                                                selectedOperator: row
                                                                                                                })}>
                                                                                                            <img src={iconDelete} width="15" alt="Delete operator" />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                )}
                                                                                            </TableCell>
                                                                                        )
                                                                                )}
                                                                            </TableRow>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        ): (
                                            <div className="operators-search-content">
                                                {isSearchProcessing ? (
                                                    <DotLoading title="Loading..." />
                                                ): (
                                                    <React.Fragment>
                                                        {_.keys(searchedOperators).length !== 0 && _.keys(searchedOperators).map((key) => (
                                                            <CustomizeAccordion
                                                                defaultExpanded
                                                                key={key + 'search'}
                                                                title={key}
                                                                titleStyle="operator-title"
                                                                badge={`${searchedOperators[key].length} Operators`}
                                                                children={
                                                                    <CustomizeTable
                                                                        headers={headers}
                                                                        children={
                                                                            <React.Fragment>
                                                                                {searchedOperators[key].map((row) => (
                                                                                    <TableRow
                                                                                        hover
                                                                                        key={row.id}
                                                                                        classes={{
                                                                                            root: "normal-cell",
                                                                                        }}
                                                                                    >
                                                                                        {headers.map(
                                                                                            (header) =>
                                                                                                header.isShow && (
                                                                                                    <TableCell key={header.key} id={header.key}>
                                                                                                        {header.key !== "id" &&
                                                                                                            (
                                                                                                                <Tooltip title={row[header.key]} placement="top" disableHoverListener={header.key === 'name' ? !row.isOpenName : !row.isOpenBadge}>
                                                                                                                    <div onMouseEnter={(e) => this.mouseEnter(e, row.id, key, header.key, 'searchedOperators')}>
                                                                                                                        {row[header.key]}
                                                                                                                    </div>
                                                                                                                </Tooltip>
                                                                                                            )}
                                                                                                        {header.key === "id" && (
                                                                                                            <div className="action-bar">
                                                                                                                <button 
                                                                                                                    className="btn primary size-small action-button"
                                                                                                                    onClick={() => this.setState({
                                                                                                                        isEditOperatorModal: true, 
                                                                                                                        operatorAction: EDIT_OPERATOR,
                                                                                                                        selectedOperator: row
                                                                                                                        })}>
                                                                                                                    <img src={iconEdit} width="15" alt="Edit operator" />
                                                                                                                </button>
                                                                                                                <button 
                                                                                                                    className=" btn primary size-small action-button"
                                                                                                                    onClick={() => this.setState({
                                                                                                                        isEditOperatorModal: true, 
                                                                                                                        operatorAction: DELETE_OPERATOR,
                                                                                                                        selectedOperator: row
                                                                                                                        })}>
                                                                                                                    <img src={iconDelete} width="15" alt="Delete operator" />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </TableCell>
                                                                                                )
                                                                                        )}
                                                                                    </TableRow>
                                                                                ))}
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        ))}
                                                        {_.keys(searchedOperators).length === 0 && (
                                                            <Typography className="empty-operator" variant="h5">No Operators Found</Typography>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <div className="no-details operators-screen">
                                        <div className="img-container">
                                            <img src={iconOperators} alt="No-Users" width="130" />
                                        </div>
                                        <div className="content-container without-button">
                                            <div>
                                                <h1>{UVCAdmin.facilityDetails.operators.grantTitle}</h1>
                                                <h5>{UVCAdmin.facilityDetails.operators.noOperatorsDesc}</h5>
                                                <button
                                                    className="btn primary size-small"
                                                    onClick={() => this.setState({isEditOperatorModal: true, operatorAction: ADD_OPERATOR})}
                                                >
                                                    {
                                                        UVCAdmin.facilityDetails
                                                            .operators.addAnOperator
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                </div>
                <EditOperatorModal 
                    open={isEditOperatorModal}
                    action={operatorAction}
                    onClose={(cb) => this.setState({isEditOperatorModal: cb, selectedOperator: null})}
                    onSubmit={(cb) => this.setState({
                        isEditOperatorModal: cb, 
                        searchedOperators: null,
                        selectedOperator: null,
                        searchText: ""}, () => this.getOperators(this.props.facilityId))}
                    operator={selectedOperator}
                    rawOperators={ungroupedOperators}
                    departments={departments}
                    facilityId={facilityId}
                    setToaster={(type, msg) => setToaster(type, msg)}
                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                    isUserSessionExpire={isUserSessionExpire}
                />
            </React.Fragment>
        );
    };
}
