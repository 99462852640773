import React from "react";
import _ from "lodash";

// import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { Grid } from "@material-ui/core";

import { Modal, TextField, Switch, CustomizeSelect } from "components/common";
import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";
// import { SoftwareSelection } from "components/uv-admin/details";
import { EDIT_ENCLOSURE, ADD_ENCLOSURE } from "components/uv-admin/details/enclosures/Enclosures";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";

import { UVCAdmin, facilityAdminRole, uvcAdminRole } from "helpers/constant";
import { report as reportAPI } from "services/api";
export default class EditEnclosureModal extends React.Component {
    initialState = {
        selectedEnclosure: null,
        selectedSoftwareOption: null,
        stopFlag: false,
        isResetPassword: false,
        isUVEProcessing: false,
        isPasswordError: false,
        configurationTypes: [
            {
                key: 1,
                title: "1",
            },
            {
                key: 2,
                title: "2",
            },
            {
                key: 3,
                title: "3",
            },
            {
                key: 4,
                title: "4",
            },
        ],
        softwareOptions: UVCAdmin.facilityDetails.enclosures.softwareSelectionOptions,
        enclosureModal: UVCAdmin.facilityDetails.enclosures.enclosureDetails,
    };

    state = {...this.initialState};
    passwordFields = React.createRef();

    componentDidUpdate = (prevProps) => {
        if(prevProps.isUserSessionExpire !== this.props.isUserSessionExpire && this.props.isUserSessionExpire === true) {
            this.handleClose(false, this.props.onClose)
        }
        if (prevProps.enclosure !== this.props.enclosure && this.props.action === EDIT_ENCLOSURE) {
            let initializeModal = this.state.enclosureModal.map(field => {
                let obj = {...field, isError: false, errorMessage: ''};
                return obj
            })
            this.setState({ selectedEnclosure: this.props.enclosure, enclosureModal: initializeModal });
        }
        else if (this.props.action === ADD_ENCLOSURE && !this.state.stopFlag) {
            let initializeModal = this.state.enclosureModal.map(field => {
                let obj = {...field, isError: false, errorMessage: ''};
                return obj
            })
            this.setState({ enclosureModal: initializeModal })
            this.state.enclosureModal.forEach(field => {
                this.setState(prevState => ({
                    selectedEnclosure: {
                        ...prevState.selectedEnclosure,
                        [field.key]: this.renderInitialValue(field.key),
                    },
                    stopFlag: true
                }))
            })
            this.state.softwareOptions.forEach(field => {
                this.setState(prevState => ({
                    selectedSoftwareOption: {
                        ...prevState.selectedSoftwareOption,
                        [field.key]: false,
                    },
                }))
            })
        }
    };

    renderInitialValue = (key) => {
        switch(key) {
            case "display_due_date_on_tags":
                return true
            case "configuration_type":
                return this.state.configurationTypes[0].key
            case "enclosure_software_package_id":
                return null;
            default: return "";
        }
    }

    onChange = (key, value) => {
        this.setState(
            (prevState) => ({
                selectedEnclosure: {
                    ...prevState.selectedEnclosure,
                    [key]: value,
                },
            })
        );
    };

    onChangeChecked = (key, value) => {
        this.setState(
            (prevState) => ({
                selectedSoftwareOption: {
                    ...prevState.selectedSoftwareOption,
                    [key]: value,
                },
            })
        );
    }

    transformVersion = (versionName) => {
        return this.props.versions.filter(ver => ver.title === versionName)[0]['key'];
    }

    setVersion = (val) => {
        let versionName = this.props.versions.filter(ver => ver.key === val)[0]['title'];
        this.onChange('next_version', versionName);
        this.onChange('enclosure_software_package_id', val)
    }

    formValidation = (isPassword, stateData, stateModal) => {
        let data = this.state[stateData];
        let modal = !isPassword 
                    ? this.state[stateModal].filter(el => el.key !== 'password' && el.key !== 'password_confirmation') 
                    : this.state[stateModal];
        modal.forEach((el, index) => {
            switch(true) {
                case !data:
                case !data.hasOwnProperty(el.key):
                case data && data[el.key] === "":
                    el.isError = true;
                    el.errorMessage = "Required Field";
                    break
                default:
                    break;
            }
            this.setState({
                [stateModal]: [
                ...this.state[stateModal].slice(0, index),
                Object.assign({}, this.state[stateModal][index], el),
                ...this.state[stateModal].slice(index + 1),
            ]});
        })
        return !Boolean(_.find(modal, ['isError', true]))
    }

    editEnclosure = () => {
        if (!this.formValidation(this.state.isResetPassword, 'selectedEnclosure', 'enclosureModal')) {
            return
        } else {
            let { selectedEnclosure } = this.state;
            this.setState({isUVEProcessing: true}) 
            return reportAPI.updateUVE(selectedEnclosure.id, {'enclosure': selectedEnclosure})
            .then((res) => {
                if (res) {
                    this.setState({
                        isUVESoftwareProcessing: false,
                        selectedEnclosure: null,
                    }, () => {
                        // this.props.setToaster(toasterMessageType.SUCCESS, 'Enclosure updated successfully!');
                        this.props.setStackToaster(
                            (
                                <ToasterMessageTemplate 
                                    icon={iconSuccess}
                                    title={'Enclosure updated successfully!'}
                                />
                            )
                        )
                        this.handleClose(false, this.props.onSubmit)
                    })
                }
            })
            .catch(err => {
                this.handleError(err)
            })
        }
    }

    addEnclosure = () => {
        if (!this.formValidation(true, 'selectedEnclosure', 'enclosureModal')) {
            return
        } else {
            let enclosure = {...this.state.selectedEnclosure, 'facility_id': this.props.facilityId}
            enclosure = _.omit(enclosure, ['versionName']);
            this.setState({isUVEProcessing: true})
            return reportAPI.addUVE({'enclosure': enclosure})
                .then((res) => {
                    let updatedEnclosure = Object.assign(res, this.state.selectedSoftwareOption)
                    return reportAPI.updateUVE(updatedEnclosure.id, updatedEnclosure)
                })
                .then((res) => {
                    if(res) {
                        this.setState({
                            isUVEProcessing: false,
                            selectedEnclosure: null,
                        }, () => {
                            // this.props.setToaster(toasterMessageType.SUCCESS, 'New enclosure added to facility');
                            this.props.setStackToaster(
                                (
                                    <ToasterMessageTemplate 
                                        icon={iconSuccess}
                                        title={'New enclosure added to facility'}
                                    />
                                )
                            )
                            this.handleClose(false, this.props.onSubmit)
                        })
                    }
                })
                .catch(err => {
                    this.handleError(err)
                })
        }
    }

    handleClose = (val, callback) => {
        let initializeModal = this.state.enclosureModal.map(field => {
            let obj = {...field, isError: false, errorMessage: ''};
            this.setState(prevState => ({
                    
                selectedEnclosure: {
                    ...prevState.selectedEnclosure,
                    [field.key]: field.key !== "display_due_date_on_tags" ? '' : false,
                }
            }))
            return obj
        })
        this.setState({ enclosureModal: initializeModal, isResetPassword: false, stopFlag: false }, () => {
            callback(val)
        });
        
    };

    toggleResetPassword = (e) => {
        let { isResetPassword, enclosureModal } = this.state;
        this.setState({ 
            isResetPassword: !isResetPassword
        })
        if(!isResetPassword) {
            let modal = enclosureModal.map(field => {
                if(field.key === 'password' || field.key === 'password_confirmation') {
                    field = {
                        ...field,
                        isError: false,
                        errorMessage: '',
                    }
                }
                return field
            })
            this.setState({enclosureModal: modal})
        }
    }

    handleRemoveInputError = (key) => {
        this.setState((prevState) => ({
            enclosureModal: prevState.enclosureModal.map((obj) => {
                return obj.key === key && obj.isError
                ? Object.assign(obj, {isError: false, errorMessage: ''})
                : obj
            })
        }))
    }

    handleError = (err) => {
        if (err && err.response && err.response.status && err.response.status === 422) {
            let errors = err.response.data.errors;
            let errorModal = this.state.enclosureModal.map(field => {
                let obj = _.indexOf(_.keys(errors), field.key) !== -1 
                        ? {...field, isError: true, errorMessage:  _.result(errors, field.key)} 
                        : {...field, isError: false, errorMessage: ''}
                return obj
            })
            this.setState({enclosureModal: errorModal})
        }
        else {
            // this.props.setToaster(toasterMessageType.ERROR, 'Error when updating enclosure!')
            this.props.setStackToaster(
                (
                    <ToasterMessageTemplate 
                        icon={iconError}
                        title={'Error when updating enclosure!'}
                    />
                )
            )
        }
        this.setState({
            isUVEProcessing: false,
        })
    }

    render = () => {
        const { 
            open, 
            action, 
            // versions, 
            roles, 
            onClose } = this.props;
        const { 
            selectedEnclosure, 
            isResetPassword, 
            configurationTypes, 
            // softwareOptions, 
            enclosureModal, 
            isUVEProcessing, 
            isPasswordError, 
            // selectedSoftwareOption 
        } = this.state;

        return (
            <Modal
                open={open}
                title={action === EDIT_ENCLOSURE ? "Edit an Enclosure" : "Add an Enclosure"}
                content={
                    <div className="edit-enclosure-modal">
                        <div className="require-title-container">
                            <h6 className="require-title required">Required</h6>
                        </div>
                        <React.Fragment>
                            <Grid container spacing={2} justify="center" alignItems="flex-start">
                                {enclosureModal.filter(field => field.isShow && (field.key !== "password" && field.key !== 'password_confirmation')).map((field, index) =>
                                    field.key !== "display_due_date_on_tags" ? (
                                        <React.Fragment key={field.key}>
                                            {field.key !== "configuration_type" ? (
                                                <Grid item xs={field["grid-xs"]}>
                                                    <TextField 
                                                        title={field.title} 
                                                        id={"edit-enclosure"} 
                                                        key={field.key} 
                                                        requiredStyle={field.isRequired && "required"} 
                                                        errorName={field.isError && field.errorMessage} 
                                                        value={selectedEnclosure && selectedEnclosure[field.key] ? selectedEnclosure[field.key] : ""}
                                                        disabled={(field.key ==="udi")
                                                                    && Boolean(_.find(roles, {'name': facilityAdminRole}))} 
                                                        onChange={(val) => this.onChange(field.key, val)}
                                                        onFocus={(e) => this.handleRemoveInputError(field.key)}
                                                        autoFocus={action === ADD_ENCLOSURE && field.key === 'nickname' ? 'autoFocus' : ''}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item xs={field["grid-xs"]} key={field.key}>
                                                    <CustomizeSelect 
                                                        id="configuration-type" 
                                                        title={field.title} 
                                                        className="config-selection" 
                                                        required
                                                        disabled={Boolean(_.find(roles, {'name': facilityAdminRole}))} 
                                                        handleChange={(val) => this.onChange(field.key, val)} 
                                                        value={selectedEnclosure && selectedEnclosure['configuration_type'] ? selectedEnclosure[field.key] : configurationTypes[0].key} 
                                                        options={configurationTypes} />
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment key={field.key}>
                                            {Boolean(_.find(roles, {'name': uvcAdminRole})) ? (
                                            <Grid item xs={12}>
                                                <Switch 
                                                    title={field.title} 
                                                    checked={selectedEnclosure && selectedEnclosure.hasOwnProperty(field.key) ? selectedEnclosure[field.key] : true} 
                                                    setValue={(val) => this.onChange(field.key, val)} />
                                            </Grid>
                                        ) : (
                                            <div className="flex-spacer" key={field.key}></div>
                                        )}
                                        </React.Fragment>
                                    )
                                )}
                            </Grid>
                            {/* {action === ADD_ENCLOSURE && (
                                <div className="software-container">
                                    <React.Fragment>
                                        <Grid container spacing={2} justify="flex-start" alignItems="stretch" alignContent="flex-start" className="software-selection-grid-container">
                                            <Grid item xs={6}>
                                                <SoftwareSelection 
                                                    versions={versions} 
                                                    selectedVersion={selectedEnclosure && selectedEnclosure['next_version'] ? this.transformVersion(selectedEnclosure['next_version']) : versions[0].key} 
                                                    setVersion={(val) => this.setVersion(val)} />
                                            </Grid>
                                            <Grid>
                                                <div className="empty-space" style={{ width: "100%" }}></div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} alignItems="stretch" className="software-selection-grid-container">
                                            {softwareOptions.map((opt) => (
                                                <Grid item xs={6} key={opt.key}>
                                                    <FormControlLabel
                                                        classes={{root: "checkbox-root"}} 
                                                        control={
                                                            <Checkbox 
                                                                checked={selectedSoftwareOption && selectedSoftwareOption.hasOwnProperty(opt.key) ? selectedSoftwareOption[opt.key] : false} 
                                                                disableRipple 
                                                                disableFocusRipple 
                                                                color="primary" 
                                                                classes={{ colorPrimary: "custom-checkbox" }} 
                                                                onChange={(e) => this.onChangeChecked(opt.key, e.target.checked)} 
                                                                name={opt.desc} />} 
                                                        label={opt.desc} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </React.Fragment>
                                </div>
                            )} */}
                            {action === EDIT_ENCLOSURE && Boolean(_.find(roles, {'name': uvcAdminRole})) && (
                                <div className="divider"></div>
                            )}
                            {action === EDIT_ENCLOSURE && Boolean(_.find(roles, {'name': uvcAdminRole})) && (
                                <button 
                                    className="btn primary size-small reset-password" 
                                    onClick={(e) => this.toggleResetPassword(e)}
                                >
                                    Reset Password
                                </button>
                            )}
                            {(isResetPassword || action === ADD_ENCLOSURE) && (
                                <Grid ref={this.passwordFields} container spacing={2} justify="center" alignItems="flex-start" className="reset-password-container">
                                    {enclosureModal.filter(field => field.key === "password" || field.key === 'password_confirmation').map(field => (
                                        <Grid item xs={field["grid-xs"]} key={field.key}>
                                            <TextField 
                                                title={field.title}
                                                id={`edit-enclosure-${field.key}`} 
                                                requiredStyle="required" 
                                                type="password"
                                                errorName={field.isError && field.errorMessage}
                                                value={selectedEnclosure && selectedEnclosure.hasOwnProperty(field.key) ? selectedEnclosure[field.key] : ""} 
                                                onChange={(val) => this.onChange(field.key, val)}  
                                                autoComplete="new-password" 
                                                onFocus={(e) => this.setState((prevState) => ({
                                                    enclosureModal: prevState.enclosureModal
                                                        .map((obj) => {
                                                            return obj.key === 'password' || obj.key === 'password_confirmation'
                                                            ? Object.assign(obj, {isError: false, errorMessage: ''})
                                                            : obj
                                                        }),
                                                    isPasswordError: false,
                                                }))} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </React.Fragment>
                        {isPasswordError && <h6 className="password-not-match">Password is not match</h6>}
                    </div>
                }
                onClose={(cb) => this.handleClose(cb, onClose)}
                action={
                    <div className="edit-enclosure-modal">
                        <div className="action-container">
                            <button className="btn tertiary size-normal" onClick={() => this.handleClose(false, onClose)}>
                                Cancel
                            </button>
                            {action === EDIT_ENCLOSURE ? (
                                <button className="btn primary size-normal" onClick={() => this.editEnclosure()}>
                                {"Save"}
                            </button>
                            ) : (
                                <button className="btn primary size-normal" onClick={() => this.addEnclosure()}>
                                {"Add Enclosure"}
                            </button>
                            )}
                        </div>
                    </div>
                }
                loading={isUVEProcessing}
            />
        );
    };
}
