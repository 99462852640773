import React from "react";

import "components/common/Modal/Modal.scss";
import iconClose from "images/icon-close-white.svg";
import { Spinner } from "..";

export default class Modal extends React.Component {
    handleClose = (loading) => {
        if (!loading) {
            this.props.onClose(false);
        }
    };

    render = () => {
        const { open, title, content, action, loading } = this.props;

        return (
            <React.Fragment>
                {open && (
                    <div>
                        <div className="modal-bg" onClick={() => this.handleClose(loading)}></div>
                        <div className="modal-container">
                            <div className="modal-header">
                                <h3 className="title">{title}</h3>
                                <button className="btn primary size-tiny transparent" onClick={() => this.handleClose(loading)}>
                                    <img src={iconClose} alt="" width="18" />
                                </button>
                            </div>
                            <div className="modal-content">{content}</div>
                            {loading ? (
                                <div className="modal-action margin-top">
                                    <Spinner open={loading} fullscreen={false} />
                                </div>
                            ) : (
                                <React.Fragment>{action && <div className="modal-action">{action}<div className="spacer"></div></div>}</React.Fragment>
                            )}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    };
}
