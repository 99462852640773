import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// Router
import { Redirect } from 'react-router';
import { ToasterMessageTemplate } from 'components/common/Toaster/ToasterMessage';
import { useSnackbar } from 'notistack';
import iconError from 'images/status_red.svg';
import { Spinner, TextField } from 'components/common';
import {
  ResetPassword,
  ERROR_MESSAGE,
  TOAST_MESSAGE,
} from 'helpers/constant';
import { auth as authAPI, browserStorage } from 'services/api';
import './style.scss';


const initForm = {
  password: { value: '', error: false, isRequired: true, messageError: '' },
  confirmPassword: {
    value: '',
    error: false,
    isRequired: true,
    messageError: '',
  },
};

const SendResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const RESET_PASSWORD = 1;
  const RESET_PASSWORD_CONFIRM = 2;

  const { enqueueSnackbar } = useSnackbar();
  const [forms, setForms] = useState(initForm);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentTab, setCurrentTab] = useState(RESET_PASSWORD);
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.get('access-token')) {
      let tempHeaders = {
        'access-token': params.get('access-token'),
        client: params.get('client'),
        expiry: params.get('expiry'),
        uid: params.get('uid'),
        'token-type': 'Bearer'
      };
      browserStorage.saveData('tempHeaders', tempHeaders);
    }
  }, []);
  const setMessage = (form, item, error, msg) => {
    return {
      ...form,
      [item]: {
        ...forms[item],
        error,
        messageError: msg,
      },
    }
  }
  const onResetPassword = async () => {
    let oldForms = forms;
    let invalid = false;

    Object.keys(forms).forEach((item) => {
      oldForms = setMessage(oldForms, item, false, '');
      setForms(oldForms);
      if (
        forms[item].value.length === 0
      ) {
        oldForms = setMessage(oldForms, item, true, ERROR_MESSAGE.REQUIRED_FIELD);
        setForms(oldForms);
        invalid = true;
      } else {
        if (item === 'password' && forms.password.value.length < 8) {
          oldForms = setMessage(oldForms, item, true, `${ERROR_MESSAGE.IS_TOO_SHORT}`);
          setForms(oldForms);
          invalid = true;
        }
        if (
          item === 'confirmPassword' &&
          forms.password.value !== forms.confirmPassword.value
        ) {
          oldForms = setMessage(oldForms, item, true, `${ERROR_MESSAGE.DOES_NOT_MATCH_PASSWORD}`);
          setForms(oldForms);
          invalid = true;
        }
      }
    });

    if (!invalid) {
      setIsProcessing(true);
      const payload = {
        password: forms.password.value.trim(),
        password_confirmation: forms.confirmPassword.value.trim(),
      };
      return authAPI
        .sendResetPassword(payload)
        .then((resp) => {
          localStorage.removeItem('tempHeaders');
          setIsProcessing(false);
          setCurrentTab(RESET_PASSWORD_CONFIRM);
        })
        .catch((err) => {
          setIsProcessing(false);
          if (err?.response?.status === 401) {
            let errors = err?.response?.data?.errors;
            const errorsList = ['is invalid', 'Unauthorized'];
            if (errorsList.includes(String(errors))) {
              enqueueSnackbar(
                <ToasterMessageTemplate
                  icon={iconError}
                  title={TOAST_MESSAGE.INVALID_TOKEN}
                />
              );
            }
          }
          if (err?.response?.status === 422) {
            let errors = err?.response?.data?.errors;
            if (errors?.['password']) {
              oldForms = setMessage(oldForms, 'password', true, String(errors?.password));
              setForms(oldForms);
            }

            if (errors?.['password_confirmation']) {
              oldForms = setMessage(oldForms, 'confirmPassword', true, String(errors?.['password_confirmation']));
              setForms(oldForms);
            }
          }
        });
    }
  };

  const onChange = (value, name) => {
    const newForms = {
      ...forms,
      [name]: {
        value: value,
      },
    };
    setForms(newForms);
  };

  const returnLogin = () => {
    history.push('/login');
  }

  const token = params.get('token');
  return (
    <div className='container-login'>
      <header>
        <div className='logo'></div>
      </header>
      {token && (<main>
        {currentTab === RESET_PASSWORD && (
          <form className='reset-password-form'>
            <h3>{ResetPassword.title}</h3>
            <div className='desc'>
              <span>{ResetPassword.description}</span>
            </div>
            <div className='field-column'>
              <TextField
                title={ResetPassword.forms.newPassword}
                type='password'
                className='input-primary size-full'
                placeholder={ResetPassword.forms.newPassword}
                autoFocus
                value={forms.password.value}
                name='password'
                onChange={(val, name) => onChange(val, name)}
                errorName={forms.password.error && forms.password.messageError}
              />
              <TextField
                title={ResetPassword.forms.confirmPassword}
                type='password'
                className='input-primary size-full'
                placeholder={ResetPassword.forms.confirmPassword}
                autoFocus
                value={forms.confirmPassword.value}
                name='confirmPassword'
                onChange={(val, name) => onChange(val, name)}
                errorName={
                  forms.confirmPassword.error &&
                  forms.confirmPassword.messageError
                }
              />
            </div>
            <div className='btn-container'>
              {isProcessing ? (
                <div>
                  <Spinner open={isProcessing} fullscreen={false} />
                </div>
              ) : (
                <button type='button' onClick={() => onResetPassword()}>
                  {ResetPassword.resetPasswordButton}
                </button>
              )}
            </div>
          </form>
        )}

        {currentTab === RESET_PASSWORD_CONFIRM && (
          <div className="login-content center-content">
            <h2>Password Successfully Created!</h2>
            <h3>Please login with your new credentials</h3>
            <button
              className="btn primary size-normal return-login"
              onClick={returnLogin}>
              CONTINUE TO LOGIN
            </button>
          </div>
        )}
      </main>)}
      {!token && <Redirect to={{
        pathname: "/login",
        state: { tab: 1 }
      }}
      />}
    </div>
  );
};

export default SendResetPassword;
