import React from "react";
import { Grid } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import singleOperator from "images/graphic-operator.svg";
import bulkOperators from "images/graphic-operators.svg";

import { ADD_SINGLE_OPERATOR, BULK_ADD_OPERATORS } from "components/uv-admin/details/operators/ModifyOperatorsModal";

export default class AddOptions extends React.Component {
    render = () => {
        const { closeForm, setTab } = this.props;

        return (
            <div className="choose-option">
                <Grid container justify="center" alignItems="center" alignContent="center" spacing={3}>
                    <Grid item xs={6}>
                        <div className="single-operator" onClick={() => setTab(ADD_SINGLE_OPERATOR)}>
                            <div>
                                <img src={singleOperator} alt="" width="65" />
                                <h6><strong>Single Operator</strong></h6>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="bulk-operators" onClick={() => setTab(BULK_ADD_OPERATORS)}>
                            <div>
                                <img src={bulkOperators} alt="" width="117" />
                                <h6><strong>{"Bulk Upload"}</strong><br/><strong>{'Operators'}</strong><br/>{'(CSV File)'}</h6>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className="action-container single-action">
                    <div className="free-space"></div>
                    <button className="btn tertiary size-normal" onClick={() => closeForm(false)}>
                        Cancel
                    </button>
                    <div className="free-space"></div>
                </div>
            </div>
        );
    };
}
