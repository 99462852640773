// Node modules
import React from "react";
import { withSnackbar } from "notistack";
import { Redirect, Route, Switch } from "react-router-dom";

// Components
import { DotLoading, Footer, Header, Modal } from "components/common";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";
import routes from "routes";
// Styles
import "./Main.scss";
import warningIcon from "images/icon-warning-large-red.svg";
import iconError from "images/status_red.svg";
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { auth as authAPI, browserStorage, report as reportAPI } from "services/api";
import { getTimeout } from "helpers/functions";
import { Typography } from "@material-ui/core";
// import { limitInterval } from "helpers/constant";

export const actionEvents = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']

class MainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loggedIn: false,
            ciisUserInfo: null,
            currentTab: 0,
            isHeaderMenuOpen: false,
            isLoggingOut: false,
            isUVCAdmin: false,
            userActive: false, 
            expireTimeId: null,
            warningTimeId: null, 
            isShowUserExpiredToaster: false,
            sessionWarning: null,
        };

        this.interval = null;

        if(window.performance && window.performance.getEntriesByType("navigation")[0].type === "reload") {
            window.sessionStorage.setItem('isAddEquipmentFired', false)
        }
    }

    componentDidMount = () => {
        if (this.props.ciisInfo) {
            this.setState((prevState) => ({
                ciisUserInfo: { ...this.props.ciisInfo },
            }), () => this.userSessionCouting());
        }
        else {
            this.checkLoggedIn();
        }
        actionEvents.forEach(evt => window.addEventListener(evt, this.resetActivityTimer))
    };

    componentWillUnmount = () => {
        actionEvents.forEach(evt => window.removeEventListener(evt, this.resetActivityTimer));
        clearInterval(this.interval);
    };

    handleOpenStackSnackbar = (message, options) => this.props.enqueueSnackbar(message, options);
    
    handleCloseStackSnackbar = (key) => key ? this.props.closeSnackbar(key) : this.props.closeSnackbar();

    resetActivityTimer = () => {
        console.log("User active!!!")
        this.setState({
            userActive: true,
        }, () => {
            if (this.state.sessionWarning) {
                this.handleCloseStackSnackbar(this.state.sessionWarning)
            }
        })
    }

    userSessionCouting = () => {
        const timeoutOffset = 3 * 60 * 1000 // Offset for session timeout before expire. Currently: 3mins
        let timeout = getTimeout(parseInt(browserStorage.getData("header").expiry))
        let timeoutWarning = timeout - timeoutOffset
        
        if (timeout <= 0) {
            this.showExpireModal();
        } else if (timeoutWarning <= 0) {
            this.showExpireToaster();
        } else {
            if (this.state.expireTimeId) {
                clearTimeout(this.state.expireTimeId)
            }
            if (this.state.warningTimeId) {
                clearTimeout(this.state.warningTimeId)
            }
            this.setState({
                expireTimeId: setTimeout(this.showExpireModal, timeout),
                warningTimeId: setTimeout(this.showExpireToaster, timeoutWarning)
            })
        }
    }

    showExpireToaster = () => {
        if(!this.state.userActive) {
            this.setState({
                sessionWarning: this.props.enqueueSnackbar(
                    (
                        <ToasterMessageTemplate 
                            icon={iconError}
                            title="Your session is about to expire due to inactivity."
                        />
                    ), {
                        persist: true
                    }
                )
            })
        }
    }

    showExpireModal = () => {
        console.log("USER SESSION EXPIRED !!!");
        if (this.state.userActive) {
            this.setState({
                userActive: false,
            }, () => this.refresh())
        } else {
            this.setState({
                isShowUserExpiredToaster: true
            }, () => this.handleCloseStackSnackbar())
        }
    }

    refresh = () => {
        this.setState({
            isShowUserExpiredToaster: false
        }, () => {
            this.checkLoggedIn();
        });
    }

    // keepSession = () => {
    //     if (browserStorage.getData("header") && browserStorage.getData("header").expiry) {
    //         this.interval = intervalWithLimit(() => {
    //             this.checkLoggedIn();
    //         }, getTimeout(parseInt(browserStorage.getData("header").expiry)), this.state.intervalWithLimit); // Re-new the access-token based on expiry
    //     }
    // }

    refreshPBIKey = () => {
        let featureReport = null;
        let otherReports = {};
        let pbiKeys = browserStorage.getData('pbiKeys');
        return reportAPI.getFeatured()
            .then(res => {
                featureReport = res;
                pbiKeys['featuredReport'] = res;
                browserStorage.saveData('pbiKeys', pbiKeys)
                return reportAPI.getGroup()
            }) 
            .then(groups => {
                if (groups.total <= 0) {
                    return
                }
                otherReports['groupId'] = groups.items[0].id;
                return reportAPI.listReports(groups.items[0].id)
            })
            .then(reports => {
                if (reports.total <= 0) {
                    return
                }

                const reportId = reports.items[0].id;
                if(featureReport.item.id !== reportAPI) {
                    otherReports['reportId'] = reportId;

                    return reportAPI.viewReport(otherReports.groupId, otherReports.reportId)
                    .then(res => {
                        pbiKeys['facilityReport'] = res;
                        browserStorage.saveData('pbiKeys', pbiKeys)
                    })
                }
            })
            .catch(err => console.log(err))
    }

    checkLoggedIn = () => {
        return authAPI.refreshSession()
            .then(adminUser => {
                this.setState({
                    loggedIn: true,
                    ciisUserInfo: adminUser,
                    loading: false,
                });
                console.log("Is Admin user logged in: ", adminUser);
            })
            .then(() => this.refreshPBIKey())
            .then(() => this.userSessionCouting())
            .catch((err) => {
                console.log("User not logged in: ", err);
                this.setState({
                    loggedIn: false,
                    ciisUserInfo: null,
                    loading: false,
                }, () => {
                    clearInterval(this.interval);
                    clearTimeout(this.state.expireTimeId);
                    localStorage.removeItem('header')
                    localStorage.removeItem('pbiKeys')
                    window.location.replace('/login');
                });
            });
    };

    onLogout = () => {
        this.setState({isLoggingOut: true})
        return authAPI.logout();
    };

    userExpireCleanUp = () => {
        this.setState({
            loggedIn: false,
            ciisUserInfo: null,
            loading: false,
        }, () => {
            clearInterval(this.interval);
            localStorage.removeItem('header')
            localStorage.removeItem('pbiKeys');
            window.location.replace('/login');
        });
    }

    render = () => {
        const { ciisUserInfo, currentTab, isHeaderMenuOpen, isShowUserExpiredToaster } = this.state;
        return (
            <React.Fragment>
                <Modal 
                    open={isShowUserExpiredToaster}
                    title="Session Expiration"
                    content={
                        <div className="session-expire-container">
                            <img src={warningIcon} className="remove-icon" alt="" width="44" />
                            <Typography variant="h6" className="remove-message">User session expired. Please login again to continue!</Typography>
                            <div className="action-container">
                                <div className="free-space"></div>
                                <button className="btn primary size-normal" onClick={() => this.userExpireCleanUp()}>OK</button>
                                <div className="free-space"></div>
                            </div>
                        </div>
                    }
                    onClose={(cb) => this.userExpireCleanUp()}
                />
                {ciisUserInfo
                ? (
                    <div className="container-hospital">
                        <Header ciisUserInfo={ciisUserInfo} logout={() => this.onLogout()} setHeaderMenuFlag={(cb) => this.setState({ isHeaderMenuOpen: cb })} isUserSessionExpire={isShowUserExpiredToaster} />
                        {/* Content */}
                        <Switch>
                            {routes.map((prop, key) => {
                                if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                                return <Route 
                                    path={prop.path}
                                    key={key} 
                                    render={(props) => 
                                        <prop.component 
                                            {...props} 
                                            tab={currentTab} 
                                            isHeaderMenu={isHeaderMenuOpen} 
                                            name={ciisUserInfo?.profile.user_id}
                                            roles={ciisUserInfo?.roles}
                                            setActiveTab={(cb) => this.setState({currentTab: cb})}
                                            setStackToaster={(message, options) => {
                                                this.handleOpenStackSnackbar(message, options)
                                            }}
                                            updateRole={() => this.checkLoggedIn()}
                                            isUserSessionExpire={isShowUserExpiredToaster} 
                                            />}  />;
                            })}
                        </Switch>
                        <Footer isShowSublinks={this.props.history && this.props.history.location.pathname.split("/")[2]} setActiveSubTab={(cb) => this.setState({ currentTab: cb })} />
                    </div>
                )
                : (
                    <DotLoading title="Loading user information..." />
                )}
                {this.state.isLoggingOut && <DotLoading title="Logging out..." />}
            </React.Fragment>
        );
    };
}

export default withSnackbar(MainLayout)
