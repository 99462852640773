// Node module
import React from "react";
import { withRouter } from "react-router-dom";
// Styles
import "components/common/Header/Header.scss";
import iconuser from "images/icon-user.svg";
// Components
import { PullDownMenu } from "components/common";
// Helpers
import { headerOptions, profileOptions } from "helpers/constant";
import { Badge } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import EditProfile from "./Profile/EditProfile";
class Header extends React.Component {
    state = {
        isPullDownMenuOpen: false,
        isOpen: false,
        name: null,
        profile: this.props.ciisUserInfo.profile,
    };

    pullDownMenuRef = React.createRef();

    componentDidMount() {
        const profile = this.props.ciisUserInfo.profile || null;
        this.redefinedName(profile);
        window.addEventListener("click", (e) => this.handleOutsidePulldownClick(e));
    };

    componentWillUnmount = () => {
        window.removeEventListener("click", (e) => this.handleOutsidePulldownClick(e));
    };

    handleOutsidePulldownClick = (e) => {
        if (this.pullDownMenuRef && this.pullDownMenuRef.current && !this.pullDownMenuRef.current.contains(e.target)) {
            this.setState({ isPullDownMenuOpen: false });
            this.props.setHeaderMenuFlag(false);
        }
    };

    handleRedirect = (path) => {
        if (path && path !== 'edit-account') {
            this.props.history.push(path);
        } else if (path === 'edit-account') {
            this.handleOpenEditProfile();
        } else {
            this.props.logout('');
        }
        this.setState({ isPullDownMenuOpen: false })
    }

    handleOpenEditProfile = () => {
        this.setState({ isOpen: true });
    }

    handleCloseEditProfile = (profile) => {
        this.setState({ isOpen: false });
        profile && this.redefinedName(profile);
    }

    redefinedName = (profile) => {
        const name = profile ? profile["first_name"] + " " + profile["last_name"].charAt(0) + "." : null;
        this.setState({ name, profile });
    }

    render() {
        const { isPullDownMenuOpen, isOpen, name, profile } = this.state;
        const { location, ciisUserInfo, setHeaderMenuFlag, isUserSessionExpire } = this.props;
        const currentPath = location.pathname;
        return (
            <>
                <header>
                    <div className="logo"></div>
                    <div className="user-loggedin">
                        <div className="switch-container badge">
                            {headerOptions.map(item => (
                                <button
                                    key={item.id}
                                    className={
                                        currentPath.split("/")[1] === item.path.replace("/", "")
                                        || (item.path.replace("/", "") === 'facilities' && currentPath.split("/")[1] === 'enclosure_logs')
                                            ? "btn primary badge size-tiny active"
                                            : "btn primary badge size-tiny"
                                    }
                                    onClick={() => this.handleRedirect(item.path)}>{item.title}
                                </button>
                            ))}
                        </div>
                        <button className="btn primary size-small transparent-background">
                            <Badge
                                className="notification-area"
                                max={99}
                                color="primary"
                                badgeContent={ciisUserInfo['notifications_count']}
                                classes={{
                                    colorPrimary: 'notification-badge'
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.handleRedirect('/enclosure_logs')
                                }}
                            >
                                <Notifications className="notication-icons" />
                            </Badge>
                        </button>
                        <div className="setting-container" ref={this.pullDownMenuRef}>
                            <button
                                className="icon-settings transparent-background"
                                onClick={() =>
                                    this.setState({
                                        isPullDownMenuOpen: !isPullDownMenuOpen,
                                    }, () => setHeaderMenuFlag(!isPullDownMenuOpen))
                                }
                            >
                                <img className="icon-user" src={iconuser} alt="" />
                                <span>{name}</span>
                            </button>
                            <PullDownMenu open={isPullDownMenuOpen} className="option">
                                {profileOptions.map((item) => (
                                    <button
                                        id={item.id}
                                        key={item.id}
                                        className="btn primary size-small option-button"
                                        onClick={() => this.handleRedirect(item.path)}
                                    >
                                        {item.title}
                                    </button>
                                ))}
                            </PullDownMenu>
                        </div>
                        <EditProfile
                            open={isOpen}
                            onClose={this.handleCloseEditProfile}
                            profile={profile}
                            isUserSessionExpire={isUserSessionExpire}
                        />
                    </div>
                </header>
            </>
        );
    };
}

export default withRouter(Header);
