export const limitInterval = 5;
export const uvcAdminRole = 'uvc_admin';
export const facilityAdminRole = 'facility_admin';
export const userRole = 'user';
export const defaultRowsPerPage = 15;
export const markInput = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const toasterMessageType = {
  SUCCESS: 0,
  ERROR: 1,
};

export const headerOptions = [
  {
    id: 'button-uvc-admin',
    title: 'Admin',
    path: '/facilities',
  },
  {
    id: 'button-uvc-report',
    title: 'Reports',
    path: '/report',
  },
];

export const profileOptions = [
  {
    id: 'button-profile',
    title: 'Edit Account',
    path: 'edit-account',
  },
  {
    id: 'button-logout',
    title: 'Logout',
    path: null,
  },
];

export const UVCAdmin = {
  grandHeader: 'My Facilities',
  noFacilityDesc: 'There are currently no facilities',
  addFacility: 'Add a Facility',
  editFacility: 'Edit Facility',
  deleteFacility: 'Delete Confirmation',
  deleteQuestion: 'Are you sure you want to delete',
  deleteDescription:
    "This item will be deleted immediately, You can't undo this action",
  loadingScreenMessage: 'Loading facilities...',
  addFacilityModal: [
    {
      key: 'name',
      title: 'Facility Name',
      isRequired: true,
      isError: false,
      isShow: true,
      errorMessage: '',
    },
    {
      key: 'address_1',
      title: 'Address 1',
      isRequired: true,
      isError: false,
      isShow: true,
      errorMessage: '',
    },
    {
      key: 'address_2',
      title: 'Address 2',
      isRequired: false,
      isError: false,
      isShow: true,
      errorMessage: '',
    },
    {
      key: 'city',
      title: 'City',
      isRequired: true,
      isError: false,
      isShow: true,
      errorMessage: '',
    },
    {
      key: 'state',
      title: 'State/Province/Region',
      isRequired: true,
      isError: false,
      isShow: true,
      errorMessage: '',
    },
    {
      key: 'zip',
      title: 'Zip/Postal Code',
      isRequired: true,
      isError: false,
      isShow: true,
      errorMessage: '',
    },
    {
      key: 'id',
      title: 'Facility Id',
      isRequired: true,
      isError: false,
      isShow: false,
      errorMessage: '',
    },
  ],

  facilityDetails: {
    loadingMessage: 'Loading Facility detail information...',
    enclosures: {
      grantTitle: 'UV Enclosures',
      noEnclosureDesc: 'There are currently no enclosures',
      addAnEnclosure: 'Add Enclosure',
      offlineMessage:
        'UVE is not connecting to the cloud. Please contact UV-C Support at 720-310-1101',
      enclosureModal: [
        {
          key: 'nickname',
          title: '',
        },
        {
          key: 'last_check_in',
          title: 'Last Checked in',
        },
        {
          key: 'location',
          title: 'Location',
        },
        {
          key: 'display_due_date_on_tags',
          title: 'Due Dates on Tags?',
        },
        {
          key: 'udi',
          title: 'UDI',
        },
        {
          key: 'configuration_type',
          title: 'Configuration Type',
        },
        {
          key: 'operating_system',
          title: 'OS',
        },
      ],
      softwareSelectionOptions: [
        {
          key: 'disable_pir',
          desc: 'Disable PIR motion sensor',
        },
        {
          key: 'disable_pocket_pinch',
          desc: 'Disable Door Pocket Pinch optical sensor',
        },
        {
          key: 'disable_current_check',
          desc: 'Disable Lamp Module Current level checks',
        },
        {
          key: 'enable_no_camera',
          desc: 'Allow video camera absence',
        },
        {
          key: 'disable_leading_edge',
          desc: 'Disable Door Leading Edge optical sensor',
        },
        {
          key: 'disable_bluetooth',
          desc: 'Disable Bluetooth Tag Scanning/Updating',
        },
        {
          key: 'enable_state_display',
          desc: 'Display UVE and door state on panel',
        },
      ],
      enclosureDetails: [
        {
          key: 'nickname',
          title: 'UVE Nickname',
          isRequired: true,
          'grid-xs': 6,
          isError: false,
          errorMessage: '',
          isShow: true,
        },
        {
          key: 'udi',
          title: 'UDI',
          isRequired: true,
          'grid-xs': 6,
          isError: false,
          errorMessage: '',
          isShow: true,
        },
        {
          key: 'location',
          title: 'Location Description',
          isRequired: true,
          'grid-xs': 9,
          isError: false,
          errorMessage: '',
          isShow: true,
        },
        {
          key: 'configuration_type',
          title: 'Config Type',
          isRequired: true,
          'grid-xs': 3,
          isError: false,
          errorMessage: '',
          isShow: true,
        },
        {
          key: 'display_due_date_on_tags',
          title: 'Display due date on indication tags',
          isShow: true,
        },
        {
          key: 'enclosure_software_package_id',
          isShow: false,
        },
        {
          key: 'password',
          title: 'Password',
          isRequired: true,
          'grid-xs': 6,
          isError: false,
          errorMessage: '',
          isShow: true,
        },
        {
          key: 'password_confirmation',
          title: 'Confirm Password',
          isRequired: true,
          'grid-xs': 6,
          isError: false,
          errorMessage: '',
          isShow: true,
        },
      ],
    },
    users: {
      grantTitle: 'Users',
      noUsersDesc: 'There are currently no users',
      addAnUsers: ' Add a user',
      addUser: 'ADD USER',
      deleteMessage: 'Remove the following user?',
      userModal: {
        headers: [
          {
            key: 'name',
            title: 'Name',
            isShow: true,
          },
          {
            key: 'email',
            title: 'Email',
            isShow: true,
            width: '300px',
          },
          {
            key: 'role_name',
            title: 'Role',
            isShow: true,
            width: '200px',
          },
          {
            key: 'invitation_pending',
            title: '',
            isShow: true,
            width: '200px',
          },
          {
            key: 'id',
            title: '',
            isShow: false,
          },
          {
            key: 'invitation_link',
            title: '',
            isShow: true,
            width: '200px',
          },
          {
            key: 'actions',
            title: '',
            isShow: true,
            width: '200px',
          },
        ],
        addUserModal: [
          {
            key: 'email',
            title: 'Email',
            isRequired: true,
            'grid-xs': 8,
          },
          {
            key: 'role_name',
            title: 'Role',
            isRequired: true,
            'grid-xs': 4,
          },
        ],
      },
    },
    departments: {
      grantTitle: 'Departments',
      noDepartmentsDesc: 'There are currently no departments',
      addAnDepartment: ' Add a Department',
      departmentModal: [
        {
          key: 'title',
          title: 'Department Name',
          isRequired: true,
          isError: false,
          errorMessage: '',
          isShow: true,
        },
      ],
    },
    equipments: {
      grantTitle: 'Equipment',
      noEquipmentsDesc: 'This department currently has no equipment',
      addAnEquipments: ' Add equipment',
      bulkUpload: 'Bulk upload',
      deleteEquipmentTypeMessage: 'Remove the following equipment type?',
      deleteEquipmentMessage: 'Remove the following equipment?',
      addEquipmentNote:
        'Updating the frequency of an equipment type will affect all associated equipment, effective on the next cycle.',
      overwriteTagIDHelp:
        'Overwriting a duplicate Indicator Tag ID will deactivate the previously existing record and make the new one active. No other Indicator Tag IDs will be affected.',
      equipmentsModal: {
        headers: [
          {
            key: 'tag_id',
            title: 'Indicator Tag ID',
            width: '500px',
            isShow: true,
          },
          {
            key: 'equipment_id',
            title: 'Equipment ID',
            width: '500px',
            isShow: true,
          },
          {
            key: 'next_disinfection_date',
            title: 'Next Cycle',
            isShow: true,
          },
          {
            key: 'equipment_type',
            title: '',
            isShow: false,
          },
          {
            key: 'id',
            title: '',
            isShow: false,
          },
          {
            key: 'protocol_id',
            title: '',
            isShow: false,
          },
          {
            key: 'action',
            title: '',
            isShow: true,
          },
        ],
        addEquipmentModal: [
          {
            key: 'tag_id',
            title: 'Indicator Tag ID',
            isShow: true,
            isRequired: true,
            'grid-xs': 6,
            isError: false,
            errorMessage: '',
          },
          {
            key: 'equipment_id',
            title: 'Equipment ID',
            isShow: true,
            isRequired: false,
            'grid-xs': 6,
            isError: false,
            errorMessage: '',
          },
          {
            key: 'deactivate_duplicate_tags',
            title: '',
            isShow: true,
            isRequired: false,
            'grid-xs': 12,
            isError: false,
            errorMessage: '',
          },
          {
            key: 'equipment_type_id',
            title: '',
            isShow: false,
            isRequired: true,
            'grid-xs': 12,
            isError: false,
            errorMessage: '',
          },
          {
            key: 'frequency',
            title: '',
            isShow: false,
            isRequired: true,
            'grid-xs': 12,
            isError: false,
            errorMessage: '',
          },
        ],
      },
    },
    operators: {
      loadingMessage: 'Loading Operators...',
      grantTitle: 'Operators',
      deleteMessage: 'Deactive the following operator?',
      noOperatorsDesc: 'There are currently no operators',
      duplicateIdHelpText:
        'Overwriting a duplicate Badge ID will deactivate the previously existing record and make the new one active. No other Badge IDs will be affected.',
      addAnOperator: ' Add operators',
      operatorModal: {
        headers: [
          {
            key: 'name',
            title: 'Name',
            width: '700px',
            isShow: true,
          },
          {
            key: 'badge_id',
            title: 'Badge ID',
            isShow: true,
          },
          {
            key: 'department_id',
            title: 'Department ID',
            isShow: false,
          },
          {
            key: 'department_name',
            title: 'Department Name',
            isShow: false,
          },
          {
            key: 'id',
            title: '',
            isShow: true,
          },
        ],
        addModal: [
          {
            key: 'first_name',
            title: 'First Name',
            isRequired: true,
            'grid-xs': 6,
            isError: false,
            errorMessage: '',
            isShow: true,
          },
          {
            key: 'last_name',
            title: 'Last Name',
            isRequired: true,
            'grid-xs': 6,
            isError: false,
            errorMessage: '',
            isShow: true,
          },
          {
            key: 'badge_id',
            title: 'Badge ID',
            isRequired: true,
            'grid-xs': 12,
            isError: false,
            errorMessage: '',
            isShow: true,
          },
          {
            key: 'deactivate_duplicate_badges',
            title: "Overwrite duplicate badge ID's",
            isRequired: true,
            'grid-xs': 12,
            isError: false,
            errorMessage: '',
            isShow: true,
          },
          {
            key: 'department_id',
            title: 'Department',
            isRequired: true,
            'grid-xs': 12,
            isError: false,
            errorMessage: '',
            isShow: true,
          },
          {
            key: 'id',
            title: '',
            isShow: false,
          },
        ],
      },
    },
  },

  enclosureLogs: {
    headers: [
      {
        key: 'performed_at',
        title: 'Timestamp',
        width: '300px',
        isShow: true,
      },
      {
        key: 'error_code',
        title: 'Error Code',
        width: '150px',
        isShow: true,
      },
      {
        key: 'error_description',
        title: 'Error Message',
        isShow: true,
      },
      {
        key: 'id',
        title: '',
        isShow: false,
      },
    ],
  },
};

export const UVCFooter = {
  appName: 'UV-Concepts',
  links: [
    {
      key: 'uv-footer-enclosure',
      title: 'UV Enclosures',
      iconName: 'iconEnclosures',
    },
    {
      key: 'uv-footer-users',
      title: 'Users',
      iconName: 'iconUsers',
    },
    {
      key: 'uv-footer-departments',
      title: 'Departments',
      iconName: 'iconDepartments',
    },
    {
      key: 'uv-footer-operator',
      title: 'Operators',
      iconName: 'iconOperators',
    },
  ],
};

export const UserProfile = {
  title: 'Edit Profile',
  modal: [
    {
      key: 'first_name',
      title: 'First Name',
      isRequired: true,
      'grid-xs': 6,
      isError: false,
      errorMessage: '',
    },
    {
      key: 'last_name',
      title: 'Last Name',
      isRequired: true,
      'grid-xs': 6,
      isError: false,
      errorMessage: '',
    },
    {
      key: 'title',
      title: 'Title',
      isRequired: true,
      'grid-xs': 6,
      isError: false,
      errorMessage: '',
    },
    {
      key: 'employee_id',
      title: 'Employee ID',
      isRequired: false,
      'grid-xs': 6,
      isError: false,
      errorMessage: '',
    },
    {
      key: 'department',
      title: 'Department',
      isRequired: true,
      'grid-xs': 12,
      isError: false,
      errorMessage: '',
    },
    {
      key: 'phone_number',
      title: 'Phone',
      isRequired: true,
      'grid-xs': 6,
      isError: false,
      errorMessage: '',
    },
  ],
};

export const STORAGE = window.localStorage;
export const USER_INFO = 'USER.INFO';
export const IS_LOGIN = 'IS.LOGIN';

export const ERROR_MESSAGE = {
  REQUIRED_FIELD: 'Required Field',
  INVALID_EMAIL: 'Invalid Email',
  DOES_NOT_MATCH_PASSWORD: `doesn't match password`,
  IS_TOO_SHORT: `is too short (minimum is 8 characters)`,
  IS_INVALID_NUMBER: `is an invalid number`,
};

export const TOAST_MESSAGE = {
  ADD_USER_SUCCESS: 'New user added to facility.',
  ADD_USER_ERROR:
    'A server error occurred. We have been notified of the problem.',
  RESEND_INVITATION_SUCCESS: 'User invitation resent.',
  RESEND_INVITATION_ERROR:
    'A server error occurred. We have been notified of the problem.',
  UPDATE_PROFILE: 'Profile updated successfully.',
  INVALID_INVITATION_TOKEN: 'Invalid invitation token',
  INVALID_TOKEN: 'Invalid token',
  TOKEN_EXPIRED: 'Invalid Password Reset token. Your token may be expired or was already used.',
};

export const UVCAcceptInvitation = {
  title: 'Create an Account',
  description:
    'Please provide the following information to complete your account setup.',
  required: 'Required',
  forms: {
    firstName: 'First Name',
    lastName: 'Last Name',
    title: 'Title',
    employeeId: 'Employee ID',
    department: 'Department',
    phone: 'Phone',
    password: 'Password',
    confirmPassword: 'Confirm Password',
  },
  createAccountButton: 'CREATE ACCOUNT',
};

export const ResetPassword = {
  title: 'Reset Password',
  description: 'Please enter your new password',
  required: 'Required',
  forms: {
    newPassword: 'Create a New Password',
    confirmPassword: 'Confirm Password',
  },
  resetPasswordButton: 'CREATE PASSWORD',
};