/******************************************************************************\
 * File: api.js
 *
 * Author: CIIS
 *
 * Description: List of api endpoint functions
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from 'axios';
import CONFIG from './config';
import fileSaver from 'file-saver';
// Helpers
import { objectMapping } from 'helpers/functions';
import { STORAGE } from 'helpers/constant';
//------------------------------------------------------------------------------
// API Instance ----------------------------------------------------------------

const currentVersion = '/v1';

const api = axios.create({
  baseURL: CONFIG.get_api_url(),
  headers: { 'Cache-Control': 'no-store', Pragma: 'no-cache' },
});

const ciis_api = axios.create({
  baseURL: CONFIG.get_ciis_service_url() + currentVersion,
  headers: { 'Cache-Control': 'no-store', Pragma: 'no-cache' },
});

const ciis_api_without_version = axios.create({
  baseURL: CONFIG.get_ciis_service_url(),
  headers: { 'Cache-Control': 'no-store', Pragma: 'no-cache' },
});

const headerKeys = ['access-token', 'client', 'expiry', 'token-type', 'uid'];
const initialHeader = {
  'access-token': null,
  client: null,
  expiry: null,
  'token-type': null,
  uid: null,
};

api.interceptors.request.use(
  function (config) {
    const header = browserStorage.getData('header');
    if (header !== null) {
      config.headers = objectMapping(header, null, headerKeys);
    }

    return config;
  },
  function (error) {
    // Get token from error header to refresh current header
    const err = error.response;
    const header = browserStorage.getData('header');
    if (header !== null) {
      err.headers = objectMapping(header, null, headerKeys);
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (res) {
    const savedHeader = browserStorage.getData('header');
    if (res.headers['access-token']) {
      const header = objectMapping(res.headers, savedHeader, headerKeys);
      browserStorage.saveData('header', header);
    }
    return res;
  },
  function (error) {
    // Get token from error header to refresh current header
    if (error && error.response) {
      const err = error.response;
      const savedHeader = browserStorage.getData('header');
      if (err.headers['access-token']) {
        const header = objectMapping(err.headers, savedHeader, headerKeys);
        browserStorage.saveData('header', header);
      }
    }
    return Promise.reject(error);
  }
);

// set/get headers information from CIIS-Service - temporary

ciis_api.interceptors.request.use(
  function (config) {
    const header = browserStorage.getData('header');
    if (header !== null) {
      config.headers = objectMapping(header, null, headerKeys);
    }

    return config;
  },
  function (error) {
    // Get token from error header to refresh current header
    const err = error.response;
    const header = browserStorage.getData('header');
    if (header !== null) {
      err.headers = objectMapping(header, null, headerKeys);
    }
    return Promise.reject(error);
  }
);

ciis_api.interceptors.response.use(
  function (res) {
    const savedHeader = browserStorage.getData('header');
    if (res.headers['access-token']) {
      const header = objectMapping(res.headers, savedHeader, headerKeys);
      browserStorage.saveData('header', header);
    }
    return res;
  },
  function (error) {
    // Get token from error header to refresh current header
    if (error && error.response) {
      const err = error.response;
      const savedHeader = browserStorage.getData('header');
      if (err.headers['access-token']) {
        const header = objectMapping(err.headers, savedHeader, headerKeys);
        browserStorage.saveData('header', header);
      }
    }
    return Promise.reject(error);
  }
);

ciis_api_without_version.interceptors.request.use(
  function (config) {
    const url = config.url;
    const method = config.method;
    let header = browserStorage.getData('header');

    if (url === 'auth/password' && method === 'put') {
      header = browserStorage.getData('tempHeaders');
    }

    if (header !== null) {
      config.headers = objectMapping(header, null, headerKeys);
    }

    return config;
  },
  function (error) {
    // Get token from error header to refresh current header
    const err = error.response;
    const header = browserStorage.getData('header');
    if (header !== null) {
      err.headers = objectMapping(header, null, headerKeys);
    }
    return Promise.reject(error);
  }
);

ciis_api_without_version.interceptors.response.use(
  function (res) {
    const url = res.config.url;
    const method = res.config.method;
    let savedHeader = browserStorage.getData('header');
    let key = 'header';
    if (url === 'auth/password' && method === 'put') {
      savedHeader = browserStorage.getData('tempHeaders');
      key = 'tempHeaders';
    }

    if (res.headers['access-token']) {
      const header = objectMapping(res.headers, savedHeader, headerKeys);
      browserStorage.saveData(key, header);
    }
    return res;
  },
  function (error) {
    // Get token from error header to refresh current header
    if (error && error.response) {
      const err = error.response;
      const url = err.config.url;
      const method = err.config.method;
      let savedHeader = browserStorage.getData('header');
      let key = 'header';
      if (url === 'auth/password' && method === 'put') {
        savedHeader = browserStorage.getData('tempHeaders');
        key = 'tempHeaders';
      }
      if (err.headers['access-token']) {
        const header = objectMapping(err.headers, savedHeader, headerKeys);
        browserStorage.saveData(key, header);
      }
    }
    return Promise.reject(error);
  }
);

//------------------------------------------------------------------------------
// Authentication/ Authorization APIs ------------------------------------------
export const auth = {
  //prefix: `/login`,

  login: (username, password) =>
    api.post(`/login`, { username, password }).then((resp) => {
      const value = resp.data;
      return value;
    }),

  logout: () => {
    // keep state in report page when logout
    const savedView = browserStorage.getData('savedFacility');
    const header = browserStorage.getData('header');
    // Call CIIS Logout API
    ciis_api_without_version
      .delete('auth/sign_out', header)
      .then((res) => {
        return res.data.success;
      })
      .then((isSuccess) => {
        if (isSuccess) {
          STORAGE.clear();
          browserStorage.saveData('savedFacility', savedView);
        }
      })
      .then(() =>
        setTimeout(
          () => window.location.replace('http://www.uvconcepts.com'),
          1000
        )
      );
  },

  customer: () =>
    api.get(`/customer`).then((resp) => {
      const { value } = resp.data;
      return value;
    }),

  whoami: () =>
    api.get(`/who`).then((resp) => {
      const value = resp.data;
      return value;
    }),
  // Temporary Implement API call for CIIS Services
  ciis_login: (formData) =>
    ciis_api_without_version
      .post(`auth/sign_in`, formData, {
        headers: initialHeader,
      })
      .then((resp) => {
        const value = resp.data.data;
        return value;
      }),

  refreshSession: () =>
    ciis_api_without_version.get(`auth/validate_token`).then((resp) => {
      return resp.data.data;
    }),

  resetPassword: (email) =>
    ciis_api_without_version
      .post('auth/password', { 'email': email, 'redirect_url': '/reset_password' })
      .then((resp) => {
        const value = resp.data;
        return value;
      }),
  invitation: (payload) =>
    ciis_api_without_version
      .put('v1/invitation', payload, {
        headers: initialHeader,
      })
      .then((resp) => {
        const value = resp.data;
        return value;
      }),
  sendResetPassword: (payload) =>
    ciis_api_without_version
      .put('auth/password', payload)
      .then((resp) => {
        const value = resp.data;
        return value;
      }),
};

//------------------------------------------------------------
// Report View APIs ------------------------------------------
export const report = {
  prefix: `/bi`,

  getFeatured: () =>
    api.get(`${report.prefix}/my/featured`).then((resp) => {
      const value = resp.data;
      return value;
    }),

  getGroup: () =>
    api.get(`${report.prefix}/my/group`).then((resp) => {
      const value = resp.data;
      return value;
    }),

  listReports: (groupID) =>
    api.get(`${report.prefix}/my/group/${groupID}`).then((resp) => {
      const value = resp.data;
      return value;
    }),

  viewReport: (groupID, reportID) =>
    api
      .get(`${report.prefix}/my/group/report/${groupID}/${reportID}`)
      .then((resp) => {
        const value = resp.data;
        return value;
      }),
  // // Temporary Implement for Download Report

  downloadReport: (id) =>
    ciis_api
      .get(`/facilities/${id}/disinfection_reports`, {
        responseType: 'blob',
      })
      .then((resp) => {
        var header = resp.headers['content-disposition'];
        let filename = header.match(/filename="(.+)"/)[1];
        fileSaver.saveAs(resp.data, filename);
      }),

  getFalicities: () =>
    ciis_api.get(`/facilities/summary`).then((res) => {
      const value = res.data;
      return value;
    }),
  getFalicitiesByUser: () =>
    ciis_api.get(`/facilities`).then((res) => {
      const value = res.data;
      return value;
    }),

  getFalicityById: (id) =>
    ciis_api.get(`/facilities/${id}`).then((res) => {
      const value = res.data;
      value.users.map((user) => {
        user.roles.map((role) => {
          role['role_name'] = role.name;
          delete role.name;
          return role;
        });
        if (user['invitation_pending']) {
          user['invitation_pending'] = 'Yes';
        } else {
          user['invitation_pending'] = 'No';
        }

        return user;
      });
      value['software_packages'].map((version) => {
        version['key'] = version.id;
        version['title'] = version.version;
        delete version.id;
        delete version.version;
        return version;
      });
      value['software_packages'].unshift({ key: -1, title: 'None' });
      return value;
    }),

  updateFacility: (id, facility) =>
    ciis_api.patch(`/facilities/${id}`, facility).then((res) => {
      const value = res.data;
      return value;
    }),

  addFacility: (facility) =>
    ciis_api.post('/facilities', facility).then((res) => {
      const value = res.data;
      return value;
    }),

  fetchOperatorsByFacilityId: (id, search) => {
    if (search) {
      return ciis_api
        .get(`/facilities/${id}/operators?search=${search}`)
        .then((res) => {
          const value = res.data;
          value.map((opt) => {
            opt['name'] = `${opt['first_name']} ${opt['last_name']}`;
            delete opt['first_name'];
            delete opt['last_name'];
            return opt;
          });
          return value;
        });
    } else {
      return ciis_api.get(`/facilities/${id}/operators`).then((res) => {
        const value = res.data;
        value.map((opt) => {
          opt['name'] = `${opt['first_name']} ${opt['last_name']}`;
          return opt;
        });
        return value;
      });
    }
  },

  getDepartmentById: (id) =>
    ciis_api.get(`/departments/${id}`).then((res) => {
      const value = res.data;
      return value;
    }),

  updateUVE: (id, data) =>
    ciis_api.patch(`/enclosures/${id}`, data).then((res) => {
      const value = res.data;
      return value;
    }),

  addUVE: (data) =>
    ciis_api.post(`/enclosures`, data).then((res) => {
      const value = res.data;
      return value;
    }),

  addUser: (id, userPayload) =>
    ciis_api.post(`/facilities/${id}/users`, userPayload).then((res) => {
      const value = res.data;
      return value;
    }),

  updateUser: (id, userId, roleName) =>
    ciis_api
      .patch(`/facilities/${id}/users/${userId}`, { role_name: roleName })
      .then((res) => {
        const value = res.data;
        return value;
      }),

  deleteUser: (id, userId) =>
    ciis_api
      .patch(`/facilities/${id}/users/${userId}`, { role_name: '' })
      .then((res) => {
        const value = res.data;
        return value;
      }),

  resendInvitation: (facilityId, userId) =>
    ciis_api
      .post(`/resend_invitations`, { user_id: userId, facility_id: facilityId })
      .then((res) => {
        const value = res.data;
        return value;
      }),
  addDepartment: (data) =>
    ciis_api.post(`/departments`, data).then((res) => {
      const value = res.data;
      return value;
    }),
  updateDepartment: (id, data) =>
    ciis_api.patch(`/departments/${id}`, data).then((res) => {
      const value = res.data;
      return value;
    }),
  addNewEquipmentByDepartmentId: (id, data) =>
    ciis_api.post(`/departments/${id}/protocols`, data).then((res) => {
      const value = res.data;
      return value;
    }),
  addNewEquipmentByDepartmentIdWithExistProtocolId: (deptId, protId, data) =>
    ciis_api
      .patch(`/departments/${deptId}/protocols/${protId}`, data)
      .then((res) => {
        const value = res.data;
        return value;
      }),
  removeEquipmentType: (deptId, protId) =>
    ciis_api
      .patch(`/departments/${deptId}/equipment_types/${protId}`, {
        active: false,
      })
      .then((res) => {
        const value = res.data;
        return value;
      }),
  removeEquipment: (deptId, equipId) =>
    ciis_api
      .patch(`/departments/${deptId}/equipment_items/${equipId}`, {
        active: false,
      })
      .then((res) => {
        const value = res.data;
        return value;
      }),
  searchEquipment: (deptId, searchValue) =>
    ciis_api
      .get(`/departments/${deptId}/equipment_items?search=${searchValue}`)
      .then((res) => {
        const value = res.data;
        return value;
      }),
  addOperator: (facilityId, data) =>
    ciis_api.post(`/facilities/${facilityId}/operators`, data).then((res) => {
      const value = res.data;
      return value;
    }),
  editOpertor: (facilityId, operatorId, data) =>
    ciis_api
      .patch(`/facilities/${facilityId}/operators/${operatorId}`, {
        operator: data,
      })
      .then((res) => {
        const value = res.data;
        return value;
      }),
  removeOperator: (facilityId, operatorId) =>
    ciis_api
      .patch(`/facilities/${facilityId}/operators/${operatorId}`, {
        active: false,
      })
      .then((res) => {
        const value = res.data;
        return value;
      }),
  getEnclosureLogs: (enclosureId) =>
    ciis_api
      .get(`/enclosures/${enclosureId}/enclosure_logs?limit=100`)
      .then((res) => {
        const value = res.data;
        return value;
      }),
};

//------------------------------------------------------------
// Customer/ User APIs ------------------------------------------
export const user = {
  // prefix: `/bi`,
  getUsers: () =>
    api.get(`/user`).then((resp) => {
      const value = resp.data;
      return value;
    }),
  getCustomers: () =>
    api.get(`/customer`).then((resp) => {
      const value = resp.data;
      return value;
    }),
  updateProfile: (userId, profile) =>
    ciis_api.patch(`/profiles/${userId}`, profile).then((res) => {
      const value = res.data;
      return value;
    }),
};

//------------------------------------------------------------
// Info APIs ------------------------------------------
export const info = {
  // prefix: `/bi`,

  getUVCInfo: () =>
    api.get(`/info`).then((resp) => {
      const value = resp.data;
      return value;
    }),
};

//------------------------------------------------------------
// localStorage APIs -----------------------------------------
export const browserStorage = {
  saveData: (key, value) => {
    if (typeof value === 'object') {
      STORAGE.setItem(key, JSON.stringify(value));
    } else {
      STORAGE.setItem(key, value);
    }
  },

  getData: (key) => {
    const data = STORAGE.getItem(key);
    if (data) {
      if (data.charAt(0) === '{') {
        return JSON.parse(data);
      } else {
        return data;
      }
    } else {
      return null;
    }
  },

  saveSessionData: (key, value) => {
    if (typeof value === 'object') {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, value);
    }
  },

  getSessionData: (key) => {
    const data = sessionStorage.getItem(key);
    if (data) {
      if (data.charAt(0) === '{') {
        return JSON.parse(data);
      } else {
        return data;
      }
    } else {
      return null;
    }
  },

  removeData: (key) => {
    STORAGE.removeItem(key);
  },
};
