import React from "react";
import _ from "lodash";
import { TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import iconNoEquipments from "images/graphic-equipment.svg";
import iconEdit from "images/icon-edit.svg";
import iconDelete from "images/icon-delete.svg";
import iconSearch from "images/icon-search.svg";

import { UVCAdmin, toasterMessageType } from "helpers/constant";

import { CustomizeAccordion, CustomizeTable, DotLoading, TextField } from "components/common";

export const EQUIPMENT_ADD_OPTIONS = "equipment_add_options";
export const ADD_EQUIPMENT = "add_equipment";
export const EDIT_EQUIPMENT = "edit_equipment";
export const EDIT_EQUIPMENT_TYPE = "edit_equipment_type";
export const BULK_ADD_EQUIPMENT = "bulk_add_equipment";
export const DELETE_EQUIPMENT_TYPE = "delete_equipment_type";
export const DELETE_EQUIPMENT_TYPE_WARNING = "delete_equipment_type_warning";
export const DELETE_EQUIPMENT = "delete_equipment";

export default class Equipments extends React.Component {
    state = {
        headers: UVCAdmin.facilityDetails.equipments.equipmentsModal.headers,
        searchValue: '',
        isSearchProcessing: false,
        isOpenTitle: false,
    };

    typingTimer = null;

    componentWillUnmount = () => {
        clearTimeout(this.typingTimer)
    }

    handleChangeSearch = (value) => {
        if (value === '') {
            this.setState({ isSearchProcessing: false })
        }
        clearTimeout(this.typingTimer)
        this.setState({searchValue: value, isSearchProcessing: true }, () => 
            this.typingTimer = setTimeout(() => {
                return this.state.searchValue 
                    ? this.props.getEquipmentbySearch(this.state.searchValue).then(() => this.setState({ isSearchProcessing: false }))
                    : (this.props.setEmptySeachData(), this.setState({ isSearchProcessing: false }))
            }, 1000)
        )
    }

    mouseEnter = (e) => {
        if (e.target.scrollWidth > e.target.clientWidth) {
            this.setState({ isOpenTitle: true });
        }
    }
    render = () => {
        const { 
            searchData,
            equipmentInfo, 
            handleOpenDeparmentDialog, 
            handleOpenEquipmentDialog, 
            setSelectedEquipmentType, 
            setSelectedEquipment,
            groupedEquipment,
            setToaster, 
        } = this.props;
        const { headers, searchValue, isSearchProcessing, isOpenTitle } = this.state;

        return (
            <React.Fragment>
                <div className="details-container details-container-for-equipment" id="equipments">
                    <div className="outer-title">
                        <Tooltip title={equipmentInfo.title} placement="top" disableHoverListener={!isOpenTitle}>
                            <span onMouseEnter={this.mouseEnter}>{equipmentInfo.title}</span>
                        </Tooltip>
                        <span>
                            <div className="action-bar">
                                <button className="btn primary size-small action-button" onClick={() => handleOpenDeparmentDialog(true)}>
                                    <img src={iconEdit} width="15" alt="Edit operator" />
                                </button>
                            </div>
                        </span>
                    </div>
                    {groupedEquipment && _.keys(groupedEquipment).length !== 0 ? (
                        <React.Fragment>
                            <h5 className="outer-title margin-top">{UVCAdmin.facilityDetails.equipments.grantTitle}</h5>
                            <div className="details-header header-equipment">
                                <div className="search-container">
                                    <img src={iconSearch} alt="" width="20" />
                                    <TextField placeholder="Search..." className="search-bar" value={searchValue} onChange={(val) => this.handleChangeSearch(val)} />
                                </div>
                                <div className="flex-spacer"></div>
                                <div
                                    ref={this.pullDownMenuRef}
                                    style={{position: 'relative'}}>
                                    <button 
                                        className="btn primary size-small add-operators"
                                        onClick={() => handleOpenEquipmentDialog(true, EQUIPMENT_ADD_OPTIONS)}>
                                            {UVCAdmin.facilityDetails.equipments.addAnEquipments}
                                    </button>
                                </div>
                            </div>
                            {!searchData && searchData === null && !isSearchProcessing ? (<div className="equipments-content">
                                {_.keys(groupedEquipment).map((key) => 
                                    equipmentInfo.protocols.filter((item) => item.equipment_type_name === key)[0] &&
                                    (
                                        <CustomizeAccordion
                                            key={key}
                                            title={key}
                                            titleStyle="equipment-style"
                                            additionalInfo={
                                                <div className="summary-container-additional-info">
                                                    <h5 className="cycle-title">Cycle Frequency</h5>
                                                    <div className="badge size-small transparent">
                                                        {`${equipmentInfo.protocols.filter((item) => item.equipment_type_name === key)[0]["frequency"]} Days`}
                                                    </div>
                                                    <button 
                                                        className="btn primary transparent size-small"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            handleOpenEquipmentDialog(true, EDIT_EQUIPMENT_TYPE)
                                                            setSelectedEquipmentType(equipmentInfo.protocols.filter((item) => item.equipment_type_name === key)[0])
                                                        }}>
                                                        <img src={iconEdit} alt="" />
                                                    </button>
                                                    {equipmentInfo && equipmentInfo['equipment_types'].filter((item) => item.name === key)[0]['default'] === false ?
                                                        <button 
                                                            className="btn primary transparent size-small"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                if(groupedEquipment[key].length === 0) {
                                                                    handleOpenEquipmentDialog(true, DELETE_EQUIPMENT_TYPE)
                                                                    setSelectedEquipmentType(equipmentInfo.protocols.filter((item) => item.equipment_type_name === key)[0])
                                                                }
                                                                else {
                                                                    handleOpenEquipmentDialog(true, DELETE_EQUIPMENT_TYPE_WARNING)
                                                                }
                                                                
                                                            }}>
                                                            <img src={iconDelete} alt="" />
                                                        </button>
                                                        :
                                                        <button 
                                                            className="btn primary transparent size-small"
                                                            disabled>
                                                            <img src={iconDelete} alt="" />
                                                        </button>
                                                    }
                                                </div>
                                            }
                                            children={
                                                groupedEquipment[key].length !== 0
                                                ? (<CustomizeTable
                                                    headers={headers}
                                                    children={
                                                        <React.Fragment>
                                                            {groupedEquipment[key].map((row) => (
                                                                <TableRow
                                                                    hover
                                                                    key={row.id}
                                                                    classes={{
                                                                        root: "normal-cell",
                                                                    }}
                                                                >
                                                                    {headers.map(
                                                                        (header) =>
                                                                            header.isShow && (
                                                                                <TableCell className="cell-item" key={header.key} id={header.key}
                                                                                style={{width: header.width ? header.width : 'auto'}}>
                                                                                    {header.key !== 'action' && row[header.key]}
                                                                                    {header.key === 'action' && (
                                                                                        <button 
                                                                                            className="btn primary transparent size-small delete-equipment"
                                                                                            onClick={() => {
                                                                                                handleOpenEquipmentDialog(true, DELETE_EQUIPMENT)
                                                                                                setSelectedEquipment(row)
                                                                                            }}>
                                                                                            <img src={iconDelete} alt="" />
                                                                                        </button>
                                                                                    )}
                                                                                </TableCell>
                                                                            )
                                                                    )}
                                                                </TableRow>
                                                            ))}
                                                        </React.Fragment>
                                                    }
                                                />) : (
                                                    <Typography className="empty-equipment" variant="h5"> No equipment of this type is currently registered in this department.</Typography>
                                                )
                                            }
                                        />
                                    ))}
                            </div>) : (
                                <div className="equipments-search-content">
                                    <React.Fragment>
                                        {isSearchProcessing ? (
                                            <DotLoading title="Loading..." />
                                        ) : (
                                            <React.Fragment>
                                                {_.keys(searchData).length !== 0 && _.keys(searchData).map((key) => 
                                                (
                                                    <CustomizeAccordion
                                                        defaultExpanded={true}
                                                        key={key}
                                                        title={key}
                                                        additionalInfo={
                                                            <div className="summary-container-additional-info">
                                                                <h5 className="cycle-title">Cycle Frequency</h5>
                                                                <div className="badge size-small transparent">
                                                                    {`${equipmentInfo.protocols.filter((item) => item.equipment_type_name === key)[0]["frequency"]} Days`}
                                                                </div>
                                                                <button 
                                                                    className="btn primary transparent size-small"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        handleOpenEquipmentDialog(true, EDIT_EQUIPMENT_TYPE)
                                                                        setSelectedEquipmentType(equipmentInfo.protocols.filter((item) => item.equipment_type_name === key)[0])
                                                                    }}>
                                                                    <img src={iconEdit} alt="" />
                                                                </button>
                                                                {equipmentInfo && equipmentInfo['equipment_types'].filter((item) => item.name === key)[0]['default'] === false ?
                                                                    <button 
                                                                        className="btn primary transparent size-small"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            if(searchData[key].length === 0) {
                                                                                handleOpenEquipmentDialog(true, DELETE_EQUIPMENT_TYPE)
                                                                                setSelectedEquipmentType(equipmentInfo.protocols.filter((item) => item.equipment_type_name === key)[0])
                                                                            }
                                                                            else {
                                                                                setToaster(toasterMessageType.ERROR, 'Equipment Type is being used by active Equipment Items.')
                                                                            }
                                                                            
                                                                        }}>
                                                                        <img src={iconDelete} alt="" />
                                                                    </button>
                                                                    :
                                                                    <button 
                                                                        className="btn primary transparent size-small"
                                                                        disabled>
                                                                        <img src={iconDelete} alt="" />
                                                                    </button>
                                                                }
                                                            </div>
                                                        }
                                                        children={
                                                            searchData[key].length !== 0
                                                            ? (<CustomizeTable
                                                                headers={headers}
                                                                children={
                                                                    <React.Fragment>
                                                                        {searchData[key].map((row) => (
                                                                            <TableRow
                                                                                hover
                                                                                key={row.id}
                                                                                classes={{
                                                                                    root: "normal-cell",
                                                                                }}
                                                                            >
                                                                                {headers.map(
                                                                                    (header) =>
                                                                                        header.isShow && (
                                                                                            <TableCell className="cell-item" key={header.key} id={header.key}>
                                                                                                {header.key !== 'action' && row[header.key]}
                                                                                                {header.key === 'action' && (
                                                                                                    <button 
                                                                                                        className="btn primary transparent size-small delete-equipment"
                                                                                                        onClick={() => {
                                                                                                            handleOpenEquipmentDialog(true, DELETE_EQUIPMENT)
                                                                                                            setSelectedEquipment(row)
                                                                                                        }}>
                                                                                                        <img src={iconDelete} alt="" />
                                                                                                    </button>
                                                                                                )}
                                                                                            </TableCell>
                                                                                        )
                                                                                )}
                                                                            </TableRow>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                            />) : (
                                                                <Typography className="empty-equipment" variant="h5"> No equipment of this type is currently registered in this department.</Typography>
                                                            )
                                                        }
                                                    />
                                                ))}
                                                {_.keys(searchData).length === 0 && (
                                                    <div className="empty-search-container">
                                                        <Typography className="empty-equipment-searching" variant="h5">No Equipment Found</Typography>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                </div>
                            )}
                            
                        </React.Fragment>
                    ) : (
                        <div className="no-details">
                            <div className="img-container">
                                <img src={iconNoEquipments} alt="No-Equipments" width="130" />
                            </div>
                            <div className="content-container">
                                <div>
                                    <h1>{UVCAdmin.facilityDetails.equipments.grantTitle}</h1>
                                    <h5>{UVCAdmin.facilityDetails.equipments.noEquipmentsDesc}</h5>
                                    <button className="btn primary size-small" onClick={() => handleOpenEquipmentDialog(true, EQUIPMENT_ADD_OPTIONS)}>
                                        {UVCAdmin.facilityDetails.equipments.addAnEquipments}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    };
}
