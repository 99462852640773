import React from 'react';
import _ from 'lodash';
// Styles
import './UVReport.scss';
import iconrefresh from 'images/icon-refresh.svg';
import iconFullscreen from 'images/icon-fullscreen.svg';
import iconDownload from 'images/computing-cloud.svg';
import iconChevronDown from 'images/chevron-down.svg';
import iconSuccess from 'images/status_green.svg';
import iconError from 'images/status_red.svg';
//Helpers
import { report as reportAPI, browserStorage } from 'services/api';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import CONFIG from 'services/config';
// Child components
import {
  PullDownMenu,
  DotLoading,
  Spinner,
  ToasterMessage,
} from 'components/common';
import { withRouter } from 'react-router';

export const pbiKeys = browserStorage.getData('pbiKeys');

class UVReport extends React.Component {
  constructor(props) {
    super(props);

    this.pullDownMenuRef = React.createRef();

    this.state = {
      showReport: false,
      featuredReport: null,
      showOtherReport: false,
      otherReport: null,
      displayToaster: false,
      report: null,
      visualPage1: false,
      visualPage2: false,
      visualPage3: false,
      userActive: false,
      featuredChecked: true,
      otherReportID: null,
      othergroupID: null,
      enableOtherReport: false,
      timerID: null,
      fullscreenMode: false,
      currentSelectedReport: null,
      isReportLoaded: false,
      isPullDownMenuOpen: false,
      filteredFacilities: null,
      isReportDownload: false,
      isDownloadError: false,
      toasterMessage: null,
      userFacilities: null,
    };
  }

  componentDidMount = () => {
    this.onGetFeaturedReport(false);
    window.addEventListener('mousemove', this.resetActivityTimer);
    window.addEventListener('click', (e) => this.handleOutsidePulldownClick(e));
    //document.addEventListener("keydown", this.handleKeypress);
  };

  componentWillUnmount = () => {
    window.removeEventListener('mousemove', this.resetActivityTimer);
    window.removeEventListener('click', (e) =>
      this.handleOutsidePulldownClick(e)
    );
    //document.removeEventListener("keydown", this.handleKeypress);
  };

  resetActivityTimer = () => {
    //reset the timer as user is active
    //console.log("User Active !!!");
    this.setState({
      userActive: true,
    });
  };

  onGetFeaturedReport = (refreshFlag) => {
    if (refreshFlag) {
      this.setState({ isReportLoaded: true });
    } else {
      this.setState({ isReportLoaded: false });
    }
    return reportAPI
      .getFalicitiesByUser()
      .then((facilities) => {
        this.setState({ userFacilities: facilities });
        if (
          pbiKeys &&
          !_.isEmpty(pbiKeys) &&
          pbiKeys.hasOwnProperty('featuredReport') &&
          pbiKeys.featuredReport
        ) {
          return Promise.resolve(pbiKeys.featuredReport);
        } else {
          return reportAPI.getFeatured();
        }
      })
      .then((res) => {
        console.log('success calling getFeatured: ', res);
        this.setState(
          {
            featuredReport: res,
            showReport: true,
            showOtherReport: false,
            visualPage1: false,
            visualPage2: false,
            visualPage3: false,
            currentSelectedReport: 'featureReport',
          },
          () => {
            this.setReportTimer(this.state.featuredReport.expiration);
          }
        );
        this.getOtherReports();
      })
      .catch((err) => {
        if (err.reponse && err.response.status === 401) {
          alert(`Session expired, ${err.response.data.errors[0]}`);
        }

        this.setState({
          featuredReport: null,
          showReport: false,
        });
      });
  };

  getOtherReports = () => {
    reportAPI
      .getGroup()
      .then((res) => {
        console.log('success calling getGroup: ', res);

        if (res.total > 0) {
          const groupID = res.items[0].id;

          reportAPI
            .listReports(groupID)
            .then((res) => {
              console.log('success calling listReports: ', res);

              if (res.total > 0) {
                const reportID = res.items[0].id;

                if (this.state.featuredReport.item.id !== reportID) {
                  this.setState({
                    otherReportID: reportID,
                    othergroupID: groupID,
                    enableOtherReport: true,
                  });
                }
              }
            })
            .catch((err) => {
              console.log('Error at listReports: ', err);
            });
        }
      })
      .catch((err) => {
        console.log('Error at getGroup: ', err);
      });
  };

  viewOtherReports = (refreshFlag) => {
    if (refreshFlag) {
      this.setState({ isReportLoaded: true });
    } else {
      this.setState({ isReportLoaded: false });
    }
    return reportAPI
      .getFalicitiesByUser()
      .then((facilities) => {
        this.setState({ userFacilities: facilities });
        if (
          pbiKeys &&
          !_.isEmpty(pbiKeys) &&
          pbiKeys.hasOwnProperty('facilityReport') &&
          pbiKeys.facilityReport
        ) {
          return Promise.resolve(pbiKeys.facilityReport);
        } else {
          return reportAPI.viewReport(
            this.state.othergroupID,
            this.state.otherReportID
          );
        }
      })
      .then((res) => {
        console.log('success calling viewReport: ', res);
        this.setState(
          {
            otherReport: res,
            showReport: false,
            showOtherReport: true,
            visualPage1: false,
            visualPage2: false,
            visualPage3: false,
            currentSelectedReport: 'facilityReport',
          },
          () => {
            this.setReportTimer(this.state.otherReport.expiration);
          }
        );
      })
      .catch((err) => {
        console.log('Error at viewReport: ', err);

        this.setState({
          otherReport: null,
          showOtherReport: false,
        });
      });
  };

  setReportTimer = (tokenExpiration) => {
    var currentTime = Date.now();
    var expiration = Date.parse(tokenExpiration);
    var safetyInterval = 1 * 60 * 1000; //1 minute

    // time until token refresh in milliseconds
    var timeout = expiration - currentTime - safetyInterval;
    if (timeout <= 0) {
      //show toaster message
      this.showToasterMessage();
    } else {
      if (this.state.timerID) {
        clearTimeout(this.state.timerID);
      }
      const id = setTimeout(this.showToasterMessage, timeout);
      this.setState({
        timerID: id,
      });
    }
  };

  showToasterMessage = () => {
    console.log('REPORT TIMER EXPIRED !!!');
    //alert( " Report has expired, please refresh");

    if (this.state.userActive) {
      //reset the user active for next check
      this.setState(
        {
          userActive: false,
        },
        () => this.refreshReport()
      );
    } else {
      this.setState({
        displayToaster: true,
        toasterMessage: 'This report has expired',
      });
    }
  };

  refreshReport = () => {
    this.setState(
      {
        displayToaster: false,
      },
      () => {
        if (this.state.showReport === true) {
          this.onGetFeaturedReport(true);
        } else if (this.state.showOtherReport === true) {
          this.viewOtherReports(true);
        }
        this.setState({ toasterMessage: null });
      }
    );
  };

  setNewFilter = () => {
    //   if(this.state.report){
    //     console.log("Setting the filters");
    //     this.state.report.setFilters([dateFilter])
    //     .then(resp => {
    //         console.log("SUCCESS setting filter: ", resp);
    //     })
    //     .catch(errors => {
    //       // Handle error
    //       console.log("Error setting filter: ", errors);
    //     });
    //     // this.state.report.getFilters()
    //     //   .then(filters => {
    //     //     filters.push(dateFilter);
    //     //       this.state.report.setFilters(filters);
    //     //       console.log("Filters: ", filters);
    //     //   })
    //     //   .catch(err => {
    //     //     console.log("Error gettting filter: ", err);
    //     //   });
    // }
  };

  onReportLoaded = () => {
    console.log('On report Loaded');
  };

  onPageChanged = () => {
    console.log('Page Changed');
    this.setNewFilterUsingVisual();
  };

  setNewFilterUsingVisual = () => {
    //console.log("Set filter using visual ");

    // Retrieve the page collection and get the visuals for the first page.
    this.state.report
      .getPages()
      .then((pages) => {
        var activePage = pages.find(function (page) {
          return page.isActive;
        });

        this.setVisualOnPage(activePage);
      })
      .catch(function (errors) {
        console.log(errors);
      });
  };

  setVisualOnPage = (page) => {
    //Get saved slicer from localstorage if exists
    // const savedDateSlicer = browserStorage.getData(this.props.name).slicerDate;
    //Get checked facilites from localstorage
    const savedView = browserStorage.getData('savedFacility');
    const savedFacilites =
      savedView[this.props.name][`${this.state.currentSelectedReport}`] &&
      savedView[this.props.name][`${this.state.currentSelectedReport}`] !== null
        ? savedView[this.props.name][`${this.state.currentSelectedReport}`]
            .checkedFacilities
        : null;

    //Date format has to match "2020-05-22 00:00:00" in the date filter
    var currentDate = new Date();
    const curMonth = (currentDate.getMonth() + 1).toString(); // JS counts month from 0 to 11
    const curDate = currentDate.getDate().toString();

    var curDateString =
      currentDate.getFullYear() +
      '-' +
      curMonth.padStart(2, '0') +
      '-' +
      curDate.padStart(2, '0') +
      ' 00:00:00';
    //console.log("CurrentDate: ", curDateString);

    currentDate.setMonth(currentDate.getMonth() - 6);
    const sixMonth = (currentDate.getMonth() + 1).toString(); // JS counts month from 0 to 11
    var sixMonthsString =
      currentDate.getFullYear() +
      '-' +
      sixMonth.padStart(2, '0') +
      '-01' +
      ' 00:00:00';
    //console.log("sixMonthsString: ", sixMonthsString);

    const dateFilter = {
      $schema: 'http://powerbi.com/product/schema#advanced',
      target: {
        table: 'DimDate',
        column: 'Date',
      },
      // target: {
      //     table: "Run Cycle Master",
      //     column: "Run-Cycle Date",
      // },
      logicalOperator: 'AND',
      conditions: [
        {
          operator: 'GreaterThanOrEqual',
          value: sixMonthsString,
          //value: "2020-05-22 00:00:00"
        },
        {
          operator: 'LessThanOrEqual',
          value: curDateString,
          //value: "2020-08-27 00:00:00"
        },
      ],
      filterType: models.FilterType.Advanced,
    };

    page
      .getVisuals()
      .then((visuals) => {
        // Retrieve the wanted visual.
        visuals.forEach((visual) => {
          if (visual.title === 'Run-Cycle Report Date Range') {
            if (!this.state.visualPage1) {
              // Set the filter for the visual.
              // Pay attention that setFilters receives an array.
              visual
                .setSlicerState({
                  filters: [dateFilter],
                })
                .then(() =>
                  this.setUpFacilityCheckboxes(visuals, savedFacilites)
                )
                // visual.setFilters([dateFilter])
                .catch(function (errors) {
                  console.log(errors);
                });

              this.setState({
                visualPage1: true,
              });
            }
          }
          if (visual.title === 'Select the Run-Cycle date range:') {
            if (!this.state.visualPage3) {
              console.log('Set filter using visual', visual);
              // Set the filter for the visual.
              // Pay attention that setFilters receives an array.
              visual
                .setSlicerState({
                  filters: [dateFilter],
                })
                .then(() =>
                  this.setUpFacilityCheckboxes(visuals, savedFacilites)
                )
                // visual.setFilters([dateFilter])
                .catch(function (errors) {
                  console.log(errors);
                });

              this.setState({
                visualPage3: true,
              });
            }
          }
          if (visual.title === 'Enclosure Log Date Range') {
            if (!this.state.visualPage2) {
              //console.log("Setting visual Enclosure Log Date Range");
              console.log('Set filter using visual ', visual);
              // Set the filter for the visual.
              // Pay attention that setFilters receives an array.
              visual
                .setSlicerState({
                  filters: [dateFilter],
                })
                .then(() =>
                  this.setUpFacilityCheckboxes(visuals, savedFacilites)
                )
                // visual.setFilters([dateFilter])
                .catch(function (errors) {
                  console.log(errors);
                });
              this.setState({
                visualPage2: true,
              });
            }
          }
        });
      })
      .catch(function (errors) {
        console.log(errors);
      });
  };

  onReportRendered = () => {
    console.log('report rendered');

    if (!this.state.report) {
      return;
    }
    return this.state.report
      .getPages()
      .then((pages) => {
        const activePage = pages.find((page) => page.isActive);
        return activePage.getVisuals();
      })
      .then((visuals) => {
        let savedInfo = browserStorage.getData('savedFacility');
        visuals = visuals.filter((view) => view.title !== undefined);
        visuals.forEach((view) => {
          switch (true) {
            // For saving slicer state
            // case "Run-Cycle Report Date Range":
            // case "Select the Run-Cycle date range:":
            //     return view.getSlicerState()
            //         .then(filters => {
            //             savedInfo['slicerDate'] = filters.filters[0]
            //             browserStorage.saveData(this.props.name, savedInfo);
            //         })
            // For saving Facility checking options
            case view.title === '*Use ctrl key to select multiple*':
              const requests = [
                view.exportData(
                  models.ExportDataType.Summarized,
                  CONFIG.get_max_facilities()
                ),
                view.getSlicerState(),
              ];
              return Promise.all(requests)
                .then((res) => {
                  if (res) {
                    const filter = res[1] ? res[1].filters[0] : null;
                    // Transform visual facility checkboxes into list
                    let visualizeFacilities = res[0]
                      ? this.transformPBIExportData(res[0])
                      : null;

                    savedInfo[this.props.name][
                      this.state.currentSelectedReport
                    ] = {
                      checkedFacilities: filter ? filter : null,
                    };
                    browserStorage.saveData('savedFacility', savedInfo);
                    return visualizeFacilities;
                  }
                })
                .then((visualizeFacilities) => {
                  this.setState({
                    filteredFacilities: visualizeFacilities
                      ? this.filterPullDownFacilities(
                          visualizeFacilities,
                          this.state.userFacilities
                        )
                      : null,
                  });
                })
                .catch((err) => console.log(err));
            default:
              return;
          }
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isReportLoaded: true,
        });
      })
      .finally(() => {
        this.setState({
          isReportLoaded: true,
        });
      });
  };

  onShowOtherReport = () => {
    this.viewOtherReports(false);

    // if(!this.state.otherReport){
    //     this.viewOtherReports();
    // }
    // else{
    //     this.setState({
    //         showReport: false,
    //         showOtherReport: true,
    //         });
    // }

    // this.setState({
    //     visualPage1: false,
    //     visualPage2: false,
    //     visualPage3: false
    //   });

    this.setState({ featuredChecked: false });
  };

  onFeaturedReport = () => {
    this.onGetFeaturedReport(false);
    // if(this.state.featuredReport){
    //     this.setState({
    //         showReport: true,
    //         showOtherReport: false,
    //         visualPage1: false,
    //         visualPage2: false,
    //         visualPage3: false
    //       });

    // }

    this.setState({ featuredChecked: true });
  };

  onFullscreen = (mode) => {
    this.state.report.fullscreen();
    // if(mode){
    //this.setState({fullscreenMode: true});
    // }
    // else {
    //     this.setState({fullscreenMode: false});
    // }
  };

  handleOutsidePulldownClick = (e) => {
    if (
      this.pullDownMenuRef &&
      this.pullDownMenuRef.current &&
      !this.pullDownMenuRef.current.contains(e.target)
    ) {
      this.setState({ isPullDownMenuOpen: false });
    }
  };

  filterPullDownFacilities = (visualFacilities, userFacilities) => {
    let filtered = [];
    if (
      browserStorage.getData('savedFacility')[this.props.name][
        `${this.state.currentSelectedReport}`
      ] !== null
    ) {
      const savedView =
        browserStorage.getData('savedFacility')[this.props.name][
          `${this.state.currentSelectedReport}`
        ];
      if (
        savedView.checkedFacilities !== null &&
        savedView.checkedFacilities.values &&
        savedView.checkedFacilities.values.length > 0
      ) {
        filtered = userFacilities.filter((el) =>
          savedView.checkedFacilities.values.includes(el.name)
        );
      } else {
        filtered = visualFacilities.map((vf) => {
          vf = vf.trim();
          let res = userFacilities.find((uf) => uf.name.trim() === vf);
          return res;
        });
      }
      filtered = filtered.filter((el) => el !== undefined);
      return filtered;
    }
  };

  downloadReport = (facilityId) => {
    this.setState({
      isReportDownload: true,
      isPullDownMenuOpen: false,
    });

    return reportAPI
      .downloadReport(facilityId)
      .then((res) => {
        this.setState({
          isReportDownload: false,
          toasterMessage: 'Report downloaded successfully!',
          isDownloadError: false,
        });
      })
      .catch((err) => {
        this.setState({
          isReportDownload: false,
          toasterMessage: 'Error when downloading report!',
          isDownloadError: true,
        });
      });
  };

  isChartType = (type) => {
    return (
      type === 'donutChart' ||
      type === 'barChart' ||
      type === 'columnChart' ||
      type === 'pieChart'
    );
  };

  transformPBIExportData = (exportData) => {
    let dataArr = exportData.data.split('\n');
    const title = dataArr.shift();
    dataArr = dataArr.filter((el) => el !== '' && el !== title);
    dataArr = dataArr.map((el) => {
      return el.trim();
    });
    return dataArr;
  };

  setUpFacilityCheckboxes = (visuals, savedFacilites) => {
    let checkboxesListView = visuals.filter(
      (visual) => visual.title === '*Use ctrl key to select multiple*'
    )[0];
    return checkboxesListView
      .exportData(models.ExportDataType.Summarized, CONFIG.get_max_facilities())
      .then((data) => {
        const facilities = this.transformPBIExportData(data);
        let checkedFacilities = null;
        switch (true) {
          case savedFacilites === null:
            checkedFacilities = null;
            break;
          case savedFacilites.values.length > 0:
            let res = savedFacilites.values.filter(
              (el) => facilities.includes(el.trim()) || facilities.includes(el)
            );
            if (res.length === 0) {
              checkedFacilities = null;
              break;
            } else {
              savedFacilites.values = res;
              checkedFacilities = savedFacilites;
              break;
            }
          default:
            break;
        }

        return checkboxesListView.setSlicerState({
          filters: [checkedFacilities],
        });
      })
      .catch((err) => console.log(err));
  };

  // handleKeypress = (e) => {
  //     //it triggers by pressing the esc key
  //     console.log("Escape key pressed", e.keyCode );

  //   if (e.keyCode  === 27) {
  //     this.onFullscreen(false);
  //   }
  // };

  render = () => {
    const {
      featuredReport,
      showReport,
      displayToaster,
      showOtherReport,
      otherReport,
      filteredFacilities,
      toasterMessage,
      isReportDownload,
      isDownloadError,
    } = this.state;
    const { isHeaderMenu } = this.props;
    const bi_type = 'report';

    return (
      <React.Fragment>
        {/* Refresh Report Toaster */}
        <ToasterMessage
          open={displayToaster}
          title={toasterMessage}
          actionFn={() => this.refreshReport()}
          actionTitle='Refresh Report'
          icon={iconrefresh}
        />
        {/* Download Report Toaster */}
        <ToasterMessage
          open={!isReportDownload && toasterMessage !== null && !displayToaster}
          title={toasterMessage}
          icon={isDownloadError ? iconError : iconSuccess}
          handleClose={(cb) => this.setState({ toasterMessage: cb })}
        />
        <div className='container-subnav'>
          {!this.state.enableOtherReport && (
            <div className='radio'>
              <input
                type='radio'
                id='1'
                name='radios'
                checked={this.state.featuredChecked}
                onClick={() => this.onFeaturedReport()}
                readOnly
              />
              <label htmlFor='1'>
                <span className='label-option'>Featured Report</span>
              </label>
            </div>
          )}
          {this.state.enableOtherReport && (
            <div className='radio'>
              <input
                type='radio'
                id='1'
                name='radios'
                checked={this.state.featuredChecked}
                onClick={() => this.onFeaturedReport()}
                readOnly
              />
              <label htmlFor='1'>
                <span className='label-option'>Featured Report</span>
              </label>

              <input
                type='radio'
                id='2'
                name='radios'
                checked={!this.state.featuredChecked}
                onClick={() => this.onShowOtherReport()}
                readOnly
              />
              <label htmlFor='2'>
                <span className='label-option'>Facility Report</span>
              </label>
            </div>
          )}

          <div className='title right grid-2'>
            <div className='download-container' ref={this.pullDownMenuRef}>
              <div className='custom-spinner-container'>
                <Spinner
                  open={this.state.isReportDownload}
                  fullscreen={false}
                />
              </div>
              <div className='inner'>
                <button
                  className='btn primary size-normal margin-10 padding-15'
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isPullDownMenuOpen: !this.state.isPullDownMenuOpen,
                    });
                  }}
                  disabled={
                    !this.state.isReportLoaded || this.state.isReportDownload
                  }
                >
                  <span className='icon left'>
                    <img src={iconDownload} alt='' width='14' height='14' />
                  </span>
                  Process Report
                  <span className='icon right'>
                    <img
                      className={
                        this.state.isPullDownMenuOpen ? 'expand' : 'collapse'
                      }
                      src={iconChevronDown}
                      width='14'
                      height='14'
                      alt=''
                    />
                  </span>
                </button>
                <PullDownMenu open={this.state.isPullDownMenuOpen}>
                  {filteredFacilities &&
                    filteredFacilities.map((item) => (
                      <button
                        key={item.id}
                        className='btn size-small transparent-background'
                        onClick={() => this.downloadReport(item.id)}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.name}
                      </button>
                    ))}
                </PullDownMenu>
              </div>
            </div>

            <button
              className='btn primary size-normal padding-15'
              onClick={() => this.onFullscreen(true)}
            >
              <span className='icon left'>
                <img src={iconFullscreen} alt='' />
              </span>
              Fullscreen
            </button>
          </div>
        </div>
        <div
          className='wrap-container-dashboard'
          style={{ position: 'relative' }}
        >
          <div className='container-dashboard'>
            {/* <div className={classNames("container-dashboard", fullscreenMode ? "fullscreen" : null)} > */}
            {!this.state.isReportLoaded && (
              <DotLoading title='Loading and initializing report...' />
            )}
            {showReport && (
              <PowerBIEmbed
                embedConfig={{
                  type: bi_type, // Supported types: report, dashboard, ...
                  id: featuredReport.item.id,
                  embedUrl: featuredReport.embed_url,
                  accessToken: featuredReport.token,
                  tokenType: models.TokenType.Embed,
                  //  slicers: slicers
                  //filters: undefined
                }}
                cssClassName={'report-style-class'}
                eventHandlers={
                  new Map([
                    // ['loaded', () => {this.onReportLoaded()}],
                    [
                      'rendered',
                      () => {
                        this.onReportRendered();
                      },
                    ],
                    [
                      'pageChanged',
                      () => {
                        this.onPageChanged();
                      },
                    ],
                  ])
                }
                getEmbeddedComponent={(embeddedReport) => {
                  this.setState({
                    report: embeddedReport,
                  });
                }}
              />
            )}
            {showOtherReport && (
              <PowerBIEmbed
                embedConfig={{
                  type: bi_type, // Supported types: report, dashboard, ...
                  id: otherReport.item.id,
                  embedUrl: otherReport.embed_url,
                  accessToken: otherReport.token,
                  tokenType: models.TokenType.Embed,
                  //  slicers: slicers
                  //filters: undefined
                }}
                cssClassName={'report-style-class'}
                eventHandlers={
                  new Map([
                    // ['loaded', () => {this.onReportLoaded()}],
                    [
                      'rendered',
                      () => {
                        this.onReportRendered();
                      },
                    ],
                    [
                      'pageChanged',
                      () => {
                        this.onPageChanged();
                      },
                    ],
                  ])
                }
                settings={{
                  navContentPanelEnabled: true,
                  layoutType: models.LayoutType.Custom,
                  customLayout: {
                    displayOption: models.DisplayOption.FitToPage,
                  },
                }}
                getEmbeddedComponent={(embeddedReport) => {
                  this.setState({
                    report: embeddedReport,
                  });
                }}
              />
            )}
          </div>
          {(this.state.isPullDownMenuOpen || isHeaderMenu) && (
            <div className='overlay'></div>
          )}
        </div>
        {/* {fullscreenMode &&
                        <div className="toaster">
                            <span>Press Esc to exit fullscreen</span>
                        </div>
                        } */}
      </React.Fragment>
    );
  };
}

export default withRouter(UVReport);
