import React from "react";
import classNames from "classnames";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Tooltip } from "@material-ui/core";

import "./Select.scss";
import { KeyboardArrowDown } from '@material-ui/icons'

export default class CustomizeSelect extends React.Component {
    state = {
        options: this.props.options
    }

    mouseEnter = (e, key) => {
        const options = this.state.options.map((value, indx) => {
            if (value.key === key && e.target.scrollWidth > e.target.clientWidth) {
                value.isOpenTitle = true;
            } else {
                value.isOpenTitle = false;
            }
            return value;
        });
        this.setState({ options });
    }

    render = () => {
        const { id, title, value, handleChange, required, className, disabled, error, errorName, onOpen } = this.props;

        return (
            <FormControl className={`select-container ${className}`} disabled={disabled} error={error ? error : false}>
                <InputLabel shrink className={required ? classNames("select-title", "required") : "select-title"}>
                    {title}
                </InputLabel>
                <Select 
                    labelId={`${id - title}`} 
                    id={`${id - title}`} 
                    value={value}
                    displayEmpty 
                    onChange={(e) => handleChange(e.target.value)}
                    onOpen={(e) => onOpen && onOpen(e)}  
                    variant="outlined" 
                    className="select-bg" 
                    IconComponent={KeyboardArrowDown}
                    >
                    {this.state.options.map((el) => (
                        <MenuItem value={el.key} key={el.key}>
                            <Tooltip title={el.title} placement="top" disableHoverListener={!el.isOpenTitle}>
                                <div onMouseEnter={(e) => this.mouseEnter(e, el.key)} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {el.title}
                                </div>
                            </Tooltip>
                        </MenuItem>
                    ))}
                </Select>
                {errorName && <FormHelperText className="form-helper-text">{errorName}</FormHelperText>}
            </FormControl>
        );
    };
}
