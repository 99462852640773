import React from "react";
import CONFIG from "services/config";
import { Typography } from "@material-ui/core";

import "components/uv-admin/details/Detail.scss";
import iconSuccess from "images/icon-success.svg";
import iconError from "images/status_red.svg";

import { Switch, Spinner } from "components/common";
import { ToasterMessageTemplate } from "components/common/Toaster/ToasterMessage";
import { UVCAdmin } from "helpers/constant"

import { report as reportAPI } from "services/api";

export default class BulkAddModal extends React.Component {
    initialState = {
        uploadedFile: null,
        isOverwriteDuplicateId: true,
        isOperatorProcessing: false,
    }

    state = {...this.initialState};

    uploadFieldRef = React.createRef();

    handleUpload = (e) => this.setState({ uploadedFile: e.target.files[0] });

    onInputClick = (event) => event.target.value = '';

    handleClose = (val, cb) => this.setState(this.initialState, () => cb(val));

    addBulkOperator = (e) => {
        e.preventDefault();
        this.setState({isOperatorProcessing: true})
        let { uploadedFile, isOverwriteDuplicateId } = this.state;

        let data = new FormData();
        data.append('operator[operators_upload]', uploadedFile);
        data.append('operator[deactivate_duplicate_badges]', isOverwriteDuplicateId)
        return reportAPI.addOperator(this.props.facilityId, data)
        .then(res => {
            if(res || res === 0) {
                this.setState({
                    isOperatorProcessing: false,
                }, () => {
                    // this.props.setToaster(toasterMessageType.SUCCESS, `${res} Operators added to facility.`);
                    this.props.setStackToaster(
                        (
                            <ToasterMessageTemplate 
                                icon={iconSuccess}
                                title={`${res} Operators added to facility.`}
                            />
                        )
                    )
                    this.handleClose(false, this.props.submit)
                })
            }
        })
        .catch(err => this.handleError(err))
    }

    handleError = (err) => {
        switch(true) {
            case err && err.response && err.response.status && err.response.status === 422:
                this.setState({
                    isOperatorProcessing: false,
                }, () => {
                    this.props.setStackToaster(
                        (
                            <ToasterMessageTemplate 
                                icon={iconError}
                                title={err.response.data.errors.operators_upload}
                            />
                        )
                    )
                })
                break;
            case err && err.response && err.response.status && err.response.status === 500:
                this.setState({
                    isOperatorProcessing: false,
                }, () => {
                    // this.props.setToaster(toasterMessageType.ERROR, err.response.data.errors.operators_upload);
                    this.props.setStackToaster(
                        (
                            <ToasterMessageTemplate 
                                icon={iconError}
                                title="Something may be wrong with your CSV file, please review the contents and format and try again."
                            />
                        )
                    )
                })
                break;
            case !err.status:
                this.setState({
                    isOperatorProcessing: false,
                }, () => {
                    // this.props.setToaster(toasterMessageType.ERROR, 'Error when deactivating operator');
                    this.props.setStackToaster(
                        (
                            <ToasterMessageTemplate 
                                icon={iconError}
                                title={'Could not connect to server'}
                            />
                        )
                    )
                })
                break;
            default: return
        }
    }

    render = () => {
        const { uploadedFile, isOverwriteDuplicateId, isOperatorProcessing } = this.state;
        const { goBack, closeForm } = this.props;

        return (
            <React.Fragment>
                <div className="require-title-container" style={{margin: '-35px 0 15px 0'}}>
                    <h6 className="require-title required">Required</h6>
                </div>
                <div className="bulk-uploader-container" style={{borderTop: 'unset'}}>
                    <Typography variant="h5">
                        Please download our CSV template and add your operators to it.
                    </Typography>
                    <a 
                        className="btn primary size-tiny download-template"
                        href={`${CONFIG.get_ciis_service_url().split("/")[0]}//${CONFIG.get_ciis_service_url().split("/")[2]}/operators_csv_template.csv`}>
                        Download Template
                    </a>
                    <Typography variant="h5" className="required" style={{marginTop: '20px'}}>
                        Select your CSV file to upload
                    </Typography>
                    <div className="upload-file">
                        <label htmlFor="operator-bulk-upload-file">
                            <span 
                                className="btn primary size-normal"
                            >Browse</span>
                            <input
                                ref={this.uploadFieldRef} 
                                accept=".csv" 
                                id="operator-bulk-upload-file" 
                                type="file"
                                onChange={this.handleUpload}
                                onClick={this.onInputClick}
                                />
                        </label>
                        {uploadedFile && <Typography variant="h5" className="path-name">
                            {uploadedFile.name}
                            <span>
                                <img src={iconSuccess} alt="" width="15" />
                            </span>
                        </Typography>}
                    </div>
                    <div className="help-container margin-60">
                        <Switch
                            title={UVCAdmin.facilityDetails.operators.operatorModal.addModal.filter(obj => obj.key === 'deactivate_duplicate_badges')[0].title}
                            checked={isOverwriteDuplicateId}
                            setValue={(val) => this.setState({isOverwriteDuplicateId: val})}
                        />
                        <Typography variant="h5">
                            <span>Please note: </span>
                            {UVCAdmin.facilityDetails.operators.duplicateIdHelpText}
                        </Typography>
                    </div>
                    {!isOperatorProcessing ? (
                    <div className="action-container multiple-actions">
                        <button className="btn tertiary size-normal" onClick={() => this.handleClose(null, goBack)}>
                            Back
                        </button>
                        <button className="btn tertiary size-normal" onClick={() => this.handleClose(false, closeForm)}>
                            Cancel
                        </button>
                        <button className="btn primary size-normal" onClick={(e) => this.addBulkOperator(e)} disabled={!uploadedFile}>
                           Upload CSV
                        </button>
                    </div>
                    ) : (
                        <div className="action-container multiple-actions">
                            <div className="free-space"></div>
                            <Spinner open={isOperatorProcessing} fullscreen={false} />
                            <div className="free-space"></div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
