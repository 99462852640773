import React from "react";

import _ from 'lodash';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { report as reportAPI } from "services/api";
import "./Facilities.scss";
import iconNoFacility from "images/graphic-facilities.svg";
import iconFacility from "images/icon-facility.svg";
import iconEdit from "images/icon-edit.svg";
import iconSuccess from "images/status_green.svg";
import iconError from "images/status_red.svg";

import { UVCAdmin, uvcAdminRole, toasterMessageType } from "helpers/constant";

import { DotLoading, ToasterMessage } from "components/common";
import { ModifyFacilityModal } from "components";

export const DELETE = "delete";
export const ADD = "add";
export const MODIFY = "edit";

class UVAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddFacilityModalOpen: false,
            selectedFacility: null,
            facilityAction: ADD,
            facilities: [],
            isLoading: false,
            totalEquipments: 0,
            toasterMessage: null,
            toasterType: toasterMessageType.SUCCESS
        }
    }

    componentDidMount() {
        this.getFacilitiesByUser();
    };

    mouseEnter = (e, id, type) => {
        const facilities = this.state.facilities.map((value, indx) => {
            if (value.id === id && e.target.scrollWidth > e.target.clientWidth) {
                type === 'title' ? value.isOpenTitle = true : value.isOpenAddr = true;
            } else {
                value.isOpenTitle = false;
                value.isOpenAddr = false;
            }
            return value;
        });
        this.setState({ facilities });
    }

    closeAndResetForm = (cb) => {
        this.setState({
            isAddFacilityModalOpen: cb,
            selectedFacility: null,
        });
    };

    getFacilitiesByUser = () => {
        this.setState({ isLoading: true });
        return reportAPI
            .getFalicities()
            .then((facilities) => {
                if(facilities.length > 1) {
                    facilities.forEach(facility => {
                        let totalEquipments = 0;
                        if (facility.departments) {
                            facility.departments.forEach(dept => {
                                return totalEquipments += dept['equipment_items'];
                            });
                        }
                        facility['total_equipments'] = totalEquipments
                    })
                    this.setState({ facilities, isLoading: false });
                }
                else if (facilities.length > 0 && facilities.length <= 1) {
                    this.props.history.push({
                        pathname: `/facilities/${facilities[0].id}`,
                        state: {singleFacility: true}
                    })
                    return;
                } else {
                    this.setState({ isLoading: false });
                    return;
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    getFacilityId = (id) => {
        this.props.history.push({
            pathname: `/facilities/${id}`,
            state: {singleFacility: false}
        })
    }

    addrFacility = (facility) => `${facility.address_1} ${facility.address_2 !== null && facility.address_2 !== "" ? facility.address_2 + ", " : ""}${facility.city}, ${facility.state} ${facility.zip}`;

    render = () => {
        const {
            isAddFacilityModalOpen,
            selectedFacility,
            facilityAction,
            facilities,
            isLoading,
            toasterType,
            toasterMessage,
        } = this.state;

        const { roles, isUserSessionExpire, setStackToaster } = this.props;

        return (
            <React.Fragment>
                <ToasterMessage open={toasterMessage !== null} title={toasterMessage} icon={toasterType === toasterMessageType.ERROR ? iconError : iconSuccess} handleClose={(cb) => this.setState({ toasterMessage: cb })} />
                <div className="container-subnav flex-container">
                    <h4 className="uvc-admin-grand-title">{UVCAdmin.grandHeader}</h4>
                    <div style={{ flex: 1 }}></div>
                    {facilities && facilities.length > 0 && (
                        <button
                            className="btn primary size-small"
                            onClick={() =>
                                this.setState({
                                    isAddFacilityModalOpen: true,
                                    facilityAction: ADD,
                                })
                            }
                            style={{'visibility' : 
                                _.find(roles, {'name': uvcAdminRole})
                                ? 'visible'
                                : 'hidden'}}  
                        >
                            {UVCAdmin.addFacility}
                        </button>
                    )}
                </div>
                <div className="container-dashboard">
                    {!isLoading ? (
                        facilities.length > 0 ? (
                            <Grid container className="overflow-container" alignContent="flex-start">
                                {facilities.map((facility) => (
                                    <Grid item xs={12} md={4} lg={3} xl={2} key={facility.id} style={{ padding: '10px' }}>
                                        <div key={facility.id} className="facility-info" onClick={() => this.getFacilityId(facility.id)}>
                                            {(_.find(roles, {'name': uvcAdminRole})) && (
                                                <div className="action-bar">
                                                    <button 
                                                        className="btn primary size-small action-button" 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.setState({
                                                                selectedFacility: facility,
                                                                isAddFacilityModalOpen: true,
                                                                facilityAction: MODIFY
                                                            })
                                                        }}   
                                                    >
                                                        <img src={iconEdit} width="15" alt="Edit facility" />
                                                    </button>
                                                </div>
                                            )}
                                            <div className="inner">
                                                <img src={iconFacility} width="49" className="img" alt={`icon-facility-${facility.id}`} />
                                                <Tooltip title={facility.name} placement="top" disableHoverListener={!facility.isOpenTitle}>
                                                    <Typography onMouseEnter={(e) => this.mouseEnter(e, facility.id, 'title')} variant="h4" className="facility-title">{facility.name}</Typography>
                                                </Tooltip>
                                                <Tooltip ref={this.addrElementRef} title={this.addrFacility(facility)} placement="top" disableHoverListener={!facility.isOpenAddr}>
                                                    <Typography onMouseEnter={(e) => this.mouseEnter(e, facility.id, 'addr')} variant="h5" className="facility-address">{this.addrFacility(facility)}</Typography>
                                                </Tooltip>
                                                <div className="enclosures-info">
                                                    <div className="item">
                                                        <h5>UV Enclosures</h5>
                                                        <div></div>
                                                        <span className="badge size-small">{facility.enclosures}</span>
                                                    </div>
                                                    <div className="item">
                                                        <h5>Departments</h5>
                                                        <div></div>
                                                        <span className="badge size-small">{facility.departments ? facility.departments.length : "0"}</span>
                                                    </div>
                                                    <div className="item">
                                                        <h5>Equipment</h5>
                                                        <div></div>
                                                        <span className="badge size-small">{facility['total_equipments']}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <div className="no-facility">
                                <div className="img-container">
                                    <img src={iconNoFacility} alt="No-Facility" width="130" />
                                </div>
                                <div className="content-container">
                                    <div>
                                        <h1>{UVCAdmin.grandHeader}</h1>
                                        <h5>{UVCAdmin.noFacilityDesc}</h5>
                                        <button
                                            className="btn primary size-small"
                                            onClick={() =>
                                                this.setState({
                                                    isAddFacilityModalOpen: true,
                                                    facilityAction: ADD,
                                                })
                                            }
                                        >
                                            {UVCAdmin.addFacility}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : (
                        <DotLoading title={UVCAdmin.loadingScreenMessage} />
                    )}
                </div>

                {/* Add/Edit facility modal */}
                <ModifyFacilityModal 
                    open={isAddFacilityModalOpen} 
                    action={facilityAction}  
                    facility={selectedFacility} 
                    onClose={(cb) => this.closeAndResetForm(cb)}
                    onSubmit={(cb) => {
                        this.setState({
                            isAddFacilityModalOpen: cb,
                            selectedFacility: null,
                            facilities: [],
                            facilityAction: ADD,
                        })
                        this.getFacilitiesByUser();
                    }}
                    setStackToaster={(mes, opts) => setStackToaster(mes, opts)}
                    isUserSessionExpire={isUserSessionExpire} />
            </React.Fragment>
        );
    };
}

export default withRouter(UVAdmin);
